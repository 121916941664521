import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import MonthPickerPopover from "app/components/commonUI/MonthPickerPopover";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import { defaultDateFormat } from "app/constants";
import Path from "app/route/Path";

import CustomerVisitAnalyze from "./AnalyzeComponents/CustomerVisitAnalyze";
import CustomerRepeatRateAnalyze from "./AnalyzeComponents/CustomerRepeatRateAnalyze";
import TurnoverAnalyze from "./AnalyzeComponents/TurnoverAnalyze";
import AverageSpendPerCustomerAnalyze from "./AnalyzeComponents/AverageSpendPerCustomerAnalyze";
import CostRateAnalyze from "./AnalyzeComponents/CostRateAnalyze";
import OperatingProfitRatioAnalyze from "./AnalyzeComponents/OperatingProfitRatioAnalyze";
import ProductivityAnalyze from "./AnalyzeComponents/ProductivityAnalyze";
import InputAnalyze from "./AnalyzeComponents/InputAnalyze";

function SelectMonth(props) {
  const { month, setMonth } = props;

  const [showSelectMonthEl, setShowSelectMonthEl] = useState(null);
  return (
    <>
      <button onClick={(e) => setShowSelectMonthEl(e.currentTarget)}>
        {month ? month.format("YYYY年MM月") : ""}
      </button>
      {showSelectMonthEl ? (
        <MonthPickerPopover
          anchorEl={showSelectMonthEl}
          handleClose={() => setShowSelectMonthEl(null)}
          month={month}
          setMonth={setMonth}
        />
      ) : null}
    </>
  );
}

function AnalyzeManagementTopPageContent(props) {
  return (
    <div className="w-full h-full overflow-auto">
      <div className="flex flex-nowrap justify-center mb-6">
        <div className="w-1/4 px-2 min-w-[240px] max-w-[300px] h-auto shrink-0">
          <CustomerVisitAnalyze />
        </div>
        <div className="w-1/4 px-2 min-w-[240px] max-w-[300px] h-auto shrink-0">
          <CustomerRepeatRateAnalyze />
        </div>
        <div className="w-1/4 px-2 min-w-[240px] max-w-[300px] h-auto shrink-0">
          <TurnoverAnalyze />
        </div>
        <div className="w-1/4 px-2 min-w-[240px] max-w-[300px] h-auto shrink-0">
          <AverageSpendPerCustomerAnalyze />
        </div>
      </div>
      <div className="flex flex-nowrap justify-center">
        <div className="w-1/4 px-2 min-w-[240px] max-w-[300px] h-auto shrink-0">
          <InputAnalyze />
        </div>
        <div className="w-1/4 px-2 min-w-[240px] max-w-[300px] h-auto shrink-0">
          <CostRateAnalyze />
        </div>
        <div className="w-1/4 px-2 min-w-[240px] max-w-[300px] h-auto shrink-0">
          <OperatingProfitRatioAnalyze />
        </div>
        <div className="w-1/4 px-2 min-w-[240px] max-w-[300px] h-auto shrink-0">
          <ProductivityAnalyze />
        </div>
      </div>
    </div>
  );
}

function AnalyzeManagementTopPage() {
  const [showMonth, setShowMonth] = useState(() =>
    moment(moment().set("date", 1).format(defaultDateFormat), defaultDateFormat)
  );

  const navigate = useNavigate();
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "",
      }}
    >
      <MainContentLayout pageTitle="経営分析">
        <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-12">
          <div className="w-full h-8 flex items-center justify-between absolute inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              <SelectMonth month={showMonth} setMonth={setShowMonth} />
            </h2>
            <div className="">
              <Link
                to={Path.inputConsultationAnalyzeManagement}
                className="px-8 text-white text-base font-semibold py-1 rounded bg-blue-btn-primary"
              >
                経営相談をする
              </Link>
            </div>
          </div>

          <div className="w-full max-h-full">
            <div className="w-full h-full overflow-auto">
              <AnalyzeManagementTopPageContent showMonth={showMonth} />
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default AnalyzeManagementTopPage;

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import InputField from "app/components/commonUI/InputField";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const validationErrMessages = {
  productNumberRequired: "品番は必須です。",
  makerRequired: "メーカーは必須です。",
  seriesRequired: "シリーズは必須です。",
  nameRequired: "品名は必須です。",
  inventoryRequired: "在庫数は必須です。",
  productScopeRequired: "専売品は必須です。",
  priceRequired: "価格は必須です。",
};
const productScope = {
  GENERAL: "general",
  MONOPOLY: "monopoly",
};
function ProductType(props) {
  const { value, handleSelect, errors, touched } = props;
  const handleChange = (event) => {
    handleSelect(event.target.value);
  };
  return (
    <div className="!min-w-[112px] w-[200px] !mr-0">
      <RadioGroup
        value={`${value}`}
        onChange={handleChange}
        disabled={value !== null ? true : false}
      >
        <FormControlLabel
          value={productScope.MONOPOLY}
          control={<Radio />}
          label="登録する"
          className="!mr-0"
        />
        <FormControlLabel
          value={productScope.GENERAL}
          control={<Radio />}
          label="登録しない"
          className="!mr-0"
        />
      </RadioGroup>
      {touched && errors ? (
        <p className="w-full !text-red-600 !text-[12px]">
          <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
            <ErrorIcon
              fontSize="inherit"
              className="text-xs text-red-600 font-thin"
            />
          </span>
          <span>{errors}</span>
        </p>
      ) : null}
    </div>
  );
}

function CreateInventoryForm(props) {
  const {
    menuInfo = {},
    isReadMode,
    handleCreate,
    confirmMenuData,
    handleBackClick,
    fetchMenuDetail,
    menuId,
  } = props;

  const validationSchema = Yup.object().shape({
    productNumber: Yup.string().required(
      validationErrMessages.productNumberRequired
    ),
    maker: Yup.string().required(validationErrMessages.makerRequired),
    series: Yup.string().required(validationErrMessages.seriesRequired),
    name: Yup.string().required(validationErrMessages.nameRequired),
    inventory: Yup.string().required(validationErrMessages.inventoryRequired),
    productScope: Yup.string().required(
      validationErrMessages.productScopeRequired
    ),
    // price: Yup.string().required(validationErrMessages.priceRequired),
  });

  const onSubmit = async (values) => {
    confirmMenuData(values);
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      productNumber: "",
      maker: "",
      series: "",
      name: "",
      inventory: "",
      productScope: "",
      priceTaxExc: "",
    },
    onSubmit,
    validationSchema,
  });
  return (
    <form className="w-full h-full">
      <div className="w-full h-full flex flex-col gap-6 border border-gray-400 rounded-2xl p-4 overflow-auto">
        <InputField
          label="品番"
          name="productNumber"
          values={values.productNumber}
          type="text"
          onChange={(newValue) => setFieldValue("productNumber", newValue)}
          handleBlur={handleBlur}
          touched={touched.productNumber}
          errors={errors.productNumber}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <InputField
          label="メーカー"
          name="maker"
          values={values.maker}
          type="text"
          onChange={(newValue) => setFieldValue("maker", newValue)}
          handleBlur={handleBlur}
          touched={touched.maker}
          errors={errors.maker}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />

        <InputField
          label="シリーズ"
          name="series"
          values={values.series}
          type="text"
          onChange={(newValue) => setFieldValue("series", newValue)}
          handleBlur={handleBlur}
          touched={touched.series}
          errors={errors.series}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <InputField
          label="品名"
          name="name"
          values={values.name}
          type="text"
          onChange={(newValue) => setFieldValue("name", newValue)}
          handleBlur={handleBlur}
          touched={touched.name}
          errors={errors.name}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />

        <InputField
          label="在庫数"
          name="inventory"
          value={values.inventory}
          type="text"
          onChange={(newValue) => {
            setFieldValue(
              "inventory",
              Number(newValue.replace(/[^0-9.]/g, ""))
            );
          }}
          handleBlur={handleBlur}
          touched={touched.inventory}
          errors={errors.inventory}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />

        <div className="flex">
          <label className="block shrink-0 font-semibold leading-9 !w-[80px] md:!w-[100px] lg:!w-[130px]">
            専売品
          </label>
          <ProductType
            value={values.productScope}
            handleSelect={(newValue) => {
              if (newValue === productScope.GENERAL) {
                setFieldValue("productScope", newValue);
                setFieldValue("priceTaxExc", "");
              } else {
                setFieldValue("productScope", newValue);
              }
            }}
            errors={errors.productScope}
            touched={touched.productScope}
          />
          {values.productScope === productScope.MONOPOLY ? (
            <>
              <InputField
                name="priceTaxExc"
                value={values.priceTaxExc}
                type="text"
                onChange={(newValue) => {
                  setFieldValue(
                    "priceTaxExc",
                    Number(newValue.replace(/[^0-9.]/g, ""))
                  );
                }}
                handleBlur={handleBlur}
                touched={
                  values.productScope === productScope.MONOPOLY
                    ? touched.priceTaxExc
                    : false
                }
                errors={
                  values.productScope === productScope.MONOPOLY
                    ? errors.priceTaxExc
                    : false
                }
                disabled={isReadMode}
                labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
              />
              <div className="w-2/12 mt-1 ml-1 font-semibold">円(税抜)</div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          onClick={handleBackClick}
          type="button"
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          終る
        </button>
        <button
          onClick={() => {
            if (handleCreate) {
              handleCreate();
            } else {
              handleSubmit();
            }
          }}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          登録
        </button>
      </div>
    </form>
  );
}

export default CreateInventoryForm;

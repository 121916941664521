import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, useRoutes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import Path, { routePathPermission } from "./Path";
import Home from "../pages/Home";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";

// 11**
import CreateCustomerInfoPage from "../pages/11/CreateCustomerInfoPage";
import ConfirmCustomerInfoPage from "../pages/11/ConfirmCustomerInfoPage";
import CustomterCounselingSheetPage from "../pages/11/CustomterCounselingSheetPage";
import CreateReservationAndVisitPage from "../pages/11/CreateReservationAndVisitPage";
import ConfirmReservationPage from "../pages/11/ConfirmReservationPage";

// 12 ** During Service
import CustomersUndergoingTreatment from "../pages/12/CustomersUndergoingTreatment";
import DuringServicePage from "../pages/12/DuringServicePage";
import IntroductionScalpLabForUndergoingTreatment from "../pages/12/IntroductionScalpLabForUndergoingTreatment";
import SuggestScalpLabToCustomerDuringService from "../pages/12/SuggestScalpLabToCustomer";
import ConfirmIntroductionForm from "../pages/12/ConfirmIntroductionForm";
import UpdateVisitMenuPage from "../pages/12/UpdateVisitMenuPage";
import AddCounselingPage from "../pages/12/AddCounselingPage";

// 13** Payment
import ToDayVisitList from "../pages/13/ToDayVisitList";
import PaymentNonCustomerPage from "../pages/13/PaymentNonCustomerPage";
import CustomerPaymentByCash from "../pages/13/PaymentForReservation/PaymentByCash";
import CustomerPaymentByOtherMethod from "../pages/13/PaymentForReservation/PaymentByOtherMethod";

// 14** Customer Management
import CustomerManagementTopPage from "../pages/14/CustomerManagementTopPage";
import DetailCustomerPage from "../pages/14/DetailCustomerPage";
import UpdateCustomerBaseInfoPage from "../pages/14/UpdateCustomerBaseInfoPage";
import Customer1StImpressionAndFactCheckPage from "../pages/14/Customer1StImpressionAndFactCheckPage";
import CustomerBasicInfoPage from "../pages/14/CustomerBasicInfoPage";
import CustomerCouselingSheetPage from "../pages/14/CustomerCouselingSheetPage";
import CustomerVisitHistoryPage from "../pages/14/CustomerVisitHistoryPage";
import RegisterNewCustomerPage from "../pages/14/RegisterNewCustomerPage";

// 15** Reservation Management
import ReservationManagementTopPage from "../pages/15/ReservationManagementTopPage";
import ReservationManagementRegisterPage from "../pages/15/ReservationManagementRegisterPage";
import ReservationDetailPage from "../pages/15/ReservationDetailPage";
import ReservationEditPage from "../pages/15/ReservationEditPage";
import ContactingCustomer from "../pages/15/ContactingCustomer";

// 16** Salon Staff Management
import StaffManagementTopPage from "../pages/16/StaffManagementTopPage";
import StaffManagementListPage from "../pages/16/StaffManagementListPage";
import StaffManagementCalendarPage from "../pages/16/StaffManagementCalendarPage";
import StaffManagementSelectStaffRegisterShiftPlanPage from "../pages/16/StaffManagementRegisterShiftPlanPage/SelectStaffRegisterShiftPlanPage";
import StaffManagementRegisterShiftPlanPage from "../pages/16/StaffManagementRegisterShiftPlanPage";
import StaffManagementListShiftPlanPage from "../pages/16/StaffManagementListShiftPlanPage";
import StaffManagementRegisterPage from "../pages/16/StaffManagementRegisterPage";
import StaffManagementDetailPage from "../pages/16/StaffManagementDetailPage";
import StaffManagementUpdatePage from "../pages/16/StaffManagementUpdatePage";
import StaffManagementIndividualSalesPage from "../pages/16/StaffManagementIndividualSalesPage";
import StaffManagementUpdateStaffAttendancePage from "../pages/16/StaffManagementUpdateStaffAttendancePage";
import StaffManagementIndividualAttendancesPage from "../pages/16/StaffManagementIndividualAttendancesPage";
import StaffManagementAttendanceWithoutShiftPlanPage from "../pages/16/StaffManagementAttendanceWithoutShiftPlanPage";

// 17** Sale Management
import SaleManagementTopPage from "../pages/17/SaleManagementTopPage";
import DetailSaleOfSalonPage from "../pages/17/DetailSaleOfSalonPage";
import ListStaffMemberPage from "../pages/17/ListStaffMemberPage";
import InputSaleStaffPage from "../pages/17/InputSaleStaffPage";
import SaleOfStaffMemberDetailPage from "../pages/17/SaleOfStaffMemberDetailPage";

// 18** Inventory Management
import InventoryManagementTopPage from "../pages/18/InventoryManagementTopPage";
import RegisterInventoryFormPage from "../pages/18/RegisterInventoryFormPage";
import InventoryManagementSearchPage from "../pages/18/SearchInventoryFormPage";
import InventoryManagementCheckStockPage from "../pages/18/InventoryManagementCheckStockPage";

// 1A** DMs Management
import DMsManagementTopPage from "../pages/1A/DMsManagementTopPage";
import DmsManagementHistoryPage from "../pages/1A/DmsManagementHistorypage";

// 1B**
import AnalyzeManagementTopPage from "../pages/1B/AnalyzeManagementTopPage";
import CustomerVisitAnalyzePage from "../pages/1B/CustomerVisitAnalyzePage";
import CustomerRepeatRateAnalyzePage from "../pages/1B/CustomerRepeatRateAnalyzePage";
import TurnoverAnalyzePage from "../pages/1B/TurnoverAnalyzePage";
import AverageSpendPerCustomerAnalyzePage from "../pages/1B/AverageSpendPerCustomerAnalyzePage";
import CostRateAnalyzePage from "../pages/1B/CostRateAnalyzePage";
import OperatingProfitRatioAnalyzePage from "../pages/1B/OperatingProfitRatioAnalyzePage";
import ProductivityAnalyzePage from "../pages/1B/ProductivityAnalyzePage";
import InputConsultationAnalyzePage from "../pages/1B/InputConsultationAnalyzePage";

// 1C** Scalp Lap Management
import ScalpLabManagementTopPage from "../pages/1C/ScalpLabManagementTopPage";
import SuggestScalpLabToCustomer from "../pages/1C/SuggestScalpLabToCustomer";
import ApplicationForm from "../pages/1C/ApplicationForm";
import ConfirmApplicationForm from "../pages/1C/ConfirmApplicationForm";
import OrderScalpLab from "../pages/1C/OrderScalpLab";
import BatchDeliverScalpLab from "../pages/1C/BatchDeliverScalpLab";
import BatchDeliverScalpLabDetailPage from "../pages/1C/BatchDeliverScalpLab/BatchDeliverScalpLabDetailPage";
import HistoryOfOrderingPage from "../pages/1C/HistoryOfOrderingPage";
import HistoryOfOrderDetailPage from "../pages/1C/HistoryOfOrderingPage/HistoryOfOrderDetailPage";
import HistoryOfOrderDetailByKitIdPage from "../pages/1C/HistoryOfOrderingPage/HistoryOfOrderDetailByKitIdPage";
import OrderKits from "../pages/1C/OrderKits";
import ContactingOperator from "../pages/1C/ContactingOperator";
import LabProductOrderProductPage from "../pages/1C/LabProductOrderProductPage";
import LabProductOrderProductDetailPage from "../pages/1C/LabProductOrderProductPage/LabProductOrderProductDetailPage";

// 1D** System Management
import SystemManagementTopPage from "../pages/1D/SystemManagementTopPage";
import RegisterSalonFormPage from "../pages/1D/RegisterSalonFormPage";
import RegisterMenuFormPage from "../pages/1D/RegisterMenuFormPage";
import SystemManagementAllMenuPage from "../pages/1D/SystemManagementAllMenuPage";
import EditMenuFormPage from "../pages/1D/SystemManagementAllMenuPage/EditMenuFormPage";
import SettingAccessRightPage from "../pages/1D/SettingAccessRightPage";
import ConnectToDevice from "../pages/1D/ConnectToDevice";
import ConnectSuccess from "../pages/1D/ConnectToDevice/ConnectSuccess";

// 1E** Cashier Management
import CashierManagementTopPage from "../pages/1E/CashierManagementTopPage";
import CashierManagementListPage from "../pages/1E/CashierManagementListPage";
import CashierManagementSalesTotalToday from "../pages/1E/CashierManagementSalesTotalToday";
import HistoryDmsDetailPage from "../pages/1A/DmsManagementHistorypage/HistoryDmsDetailPage";
import { ToastContainer } from "react-toastify";
import CashierManagementVisitDetailPage from "../pages/1E/CashierManagementListPage/CashierManagementDetailVisitPage";
import CashierManagementNonVisitDetailPage from "../pages/1E/CashierManagementListPage/CashierManagementDetailNonVisitPage";
import NoPermission from "../pages/NoPermission";
import { accountTypes } from "../constants";

function CheckAccessRights(props) {
  const { path, children } = props;

  const permissions = useSelector((state) => state.auth.user?.permissions);
  const accountType = useSelector((state) => state.auth.user?.accountType);

  const isAccessRight = () => {
    if (accountType === accountTypes.MANAGER) return true;
    if (!Array.isArray(permissions)) return false;

    const necessaryPermissions = routePathPermission?.[path];
    if (
      Array.isArray(necessaryPermissions) &&
      necessaryPermissions.length > 0
    ) {
      const isNotPermission = necessaryPermissions.some(
        (necessaryPermission) =>
          !permissions.find(
            (item) =>
              item.functionCode === necessaryPermission && item.canAccess
          )
      );
      if (isNotPermission) return false;
    }

    return true;
  };

  if (isAccessRight()) return children;
  return <NoPermission />;
}

const privateRouteList = [
  {
    path: Path.home,
    element: <Home />,
  },

  // 11**
  { path: Path.createCustomerInfo, element: <CreateCustomerInfoPage /> },
  {
    path: Path.confirmCustomerInfo(),
    element: <ConfirmCustomerInfoPage />,
  },
  {
    path: Path.customterCounselingSheet(),
    element: <CustomterCounselingSheetPage />,
  },
  {
    path: Path.createNewReservationAndVisit(),
    element: <CreateReservationAndVisitPage />,
  },
  {
    path: Path.confirmReservationInfo(),
    element: <ConfirmReservationPage />,
  },

  // 12** During Service
  {
    path: Path.customersUndergoingTreatment,
    element: <CustomersUndergoingTreatment />,
  },
  { path: Path.duringService(), element: <DuringServicePage /> },
  {
    path: Path.customersUndergoingTreatmentIntroduction(),
    element: <SuggestScalpLabToCustomerDuringService />,
  },
  {
    path: Path.customersUndergoingTreatmentIntroductionScalpLab(),
    element: <IntroductionScalpLabForUndergoingTreatment />,
  },
  {
    path: Path.customersUndergoingTreatmentIntroductionScalpLabForm(),
    element: <ConfirmIntroductionForm />,
  },
  {
    path: Path.customersUndergoingTreatment,
    element: <CustomersUndergoingTreatment />,
  },
  {
    path: Path.duringServiceAddCounselingPage(),
    element: <AddCounselingPage />,
  },
  {
    path: Path.updateVisitMenu(),
    element: <UpdateVisitMenuPage />,
  },

  // 13** Payment
  { path: Path.toDayVisitList, element: <ToDayVisitList /> },
  {
    path: Path.customerPaymentByCash(),
    element: <CustomerPaymentByCash />,
  },
  {
    path: Path.customerPaymentByOtherMethod(),
    element: <CustomerPaymentByOtherMethod />,
  },
  { path: Path.paymentNonCustomer, element: <PaymentNonCustomerPage /> },
  // 14** Customer Management
  {
    path: Path.customerManagement,
    element: <CustomerManagementTopPage />,
  },
  {
    path: Path.customerManagementRegisterNew,
    element: <RegisterNewCustomerPage />,
  },
  {
    path: Path.customerManagementBasicInfo(),
    element: <CustomerBasicInfoPage />,
  },
  {
    path: Path.customerManagementUpdateBasicInfo(),
    element: <UpdateCustomerBaseInfoPage />,
  },
  {
    path: Path.customerManagementDetail(),
    element: <DetailCustomerPage />,
  },
  {
    path: Path.customerManagementFactCheck(),
    element: <Customer1StImpressionAndFactCheckPage />,
  },
  {
    path: Path.customerManagementCounselingSheet(),
    element: <CustomerCouselingSheetPage />,
  },
  {
    path: Path.customerManagementVisitHistory(),
    element: <CustomerVisitHistoryPage />,
  },

  // 15** Reservation Management
  {
    path: Path.reservationManagement,
    element: <ReservationManagementTopPage />,
  },
  {
    path: Path.reservationManagementRegister,
    element: <ReservationManagementRegisterPage />,
  },
  {
    path: Path.reservationManagementDetail(),
    element: <ReservationDetailPage />,
  },
  {
    path: Path.reservationManagementEdit(),
    element: <ReservationEditPage />,
  },
  {
    path: Path.contactToCustomer(),
    element: <ContactingCustomer />,
  },

  // 16** Salon Stafff Management
  {
    path: Path.staffManagement,
    element: <StaffManagementTopPage />,
  },
  {
    path: Path.staffManagementList,
    element: <StaffManagementListPage />,
  },
  {
    path: Path.staffManagementCalendar,
    element: <StaffManagementCalendarPage />,
  },
  {
    path: Path.staffManagementSelectStaffRegisterShiftPlan,
    element: <StaffManagementSelectStaffRegisterShiftPlanPage />,
  },
  {
    path: Path.staffManagementRegisterShiftPlan(),
    element: <StaffManagementRegisterShiftPlanPage />,
  },
  {
    path: Path.staffManagementListShiftPlan(),
    element: <StaffManagementListShiftPlanPage />,
  },
  {
    path: Path.staffManagementRegister,
    element: <StaffManagementRegisterPage />,
  },
  {
    path: Path.staffManagementDetail(),
    element: <StaffManagementDetailPage />,
  },
  {
    path: Path.staffManagementIndividualSales(),
    element: <StaffManagementIndividualSalesPage />,
  },
  {
    path: Path.staffManagementIndividualAttendances(),
    element: <StaffManagementIndividualAttendancesPage />,
  },
  {
    path: Path.staffManagementUpdate(),
    element: <StaffManagementUpdatePage />,
  },
  {
    path: Path.staffManagementUpdateStaffAttendance(),
    element: <StaffManagementUpdateStaffAttendancePage />,
  },
  {
    path: Path.staffManagementAttendanceWithoutShiftPlan(),
    element: <StaffManagementAttendanceWithoutShiftPlanPage />,
  },
  // 17** Sale Management
  { path: Path.saleManagement, element: <SaleManagementTopPage /> },
  {
    path: Path.detailSaleOfSalonPage,
    element: <DetailSaleOfSalonPage />,
  },
  {
    path: Path.listStaffMember,
    element: <ListStaffMemberPage />,
  },
  {
    path: Path.inputSaleStaff(),
    element: <InputSaleStaffPage />,
  },
  {
    path: Path.saleOfStaffMemberDetail(),
    element: <SaleOfStaffMemberDetailPage />,
  },

  // 18** Inventory Management
  {
    path: Path.inventoryManagement,
    element: <InventoryManagementTopPage />,
  },
  {
    path: Path.inventoryManagementRegister,
    element: <RegisterInventoryFormPage />,
  },
  {
    path: Path.inventoryManagementSearch,
    element: <InventoryManagementSearchPage />,
  },
  {
    path: Path.inventoryManagementCheckStock,
    element: <InventoryManagementCheckStockPage />,
  },

  // 1A** DMs Management
  {
    path: Path.dmsManagement,
    element: <DMsManagementTopPage />,
  },
  {
    path: Path.dmsManagementHistory,
    element: <DmsManagementHistoryPage />,
  },
  {
    path: Path.dmsManagementHistoryDetail(),
    element: <HistoryDmsDetailPage />,
  },

  // 1B** Analyze Management
  {
    path: Path.analyzeManagement,
    element: <AnalyzeManagementTopPage />,
  },
  {
    path: Path.customerVisitAnalyzeManagement,
    element: <CustomerVisitAnalyzePage />,
  },
  {
    path: Path.customerRepeatRateAnalyzeManagement,
    element: <CustomerRepeatRateAnalyzePage />,
  },
  {
    path: Path.turnoverAnalyzeManagement,
    element: <TurnoverAnalyzePage />,
  },
  {
    path: Path.averageSpendPerCustomerAnalyzeManagement,
    element: <AverageSpendPerCustomerAnalyzePage />,
  },
  {
    path: Path.costRateAnalyzeManagement,
    element: <CostRateAnalyzePage />,
  },
  {
    path: Path.operatingProfitRatioAnalyzeManagement,
    element: <OperatingProfitRatioAnalyzePage />,
  },
  {
    path: Path.productivityAnalyzeManagement,
    element: <ProductivityAnalyzePage />,
  },
  {
    path: Path.inputConsultationAnalyzeManagement,
    element: <InputConsultationAnalyzePage />,
  },
  // 1C** Scalp Lap Management
  {
    path: Path.scalpLabManagement,
    element: <ScalpLabManagementTopPage />,
  },
  {
    path: Path.scalpLabManagementIntroduction,
    element: <SuggestScalpLabToCustomer />,
  },
  {
    path: Path.scalpLabManagementApplicationForm,
    element: <ApplicationForm />,
  },
  {
    path: Path.scalpLabManagementConfirmApplicationForm,
    element: <ConfirmApplicationForm />,
  },
  {
    path: Path.orderScalpLab,
    element: <OrderScalpLab />,
  },
  {
    path: Path.batchDeliverScalpLab,
    element: <BatchDeliverScalpLab />,
  },
  {
    path: Path.batchDeliverScalpLabDetail(),
    element: <BatchDeliverScalpLabDetailPage />,
  },
  {
    path: Path.historyOfOrdering,
    element: <HistoryOfOrderingPage />,
  },
  {
    path: Path.historyOfOrderingDetail(),
    element: <HistoryOfOrderDetailPage />,
  },
  {
    path: Path.historyOfOrderingDetailByKitId(),
    element: <HistoryOfOrderDetailByKitIdPage />,
  },
  {
    path: Path.orderKits,
    element: <OrderKits />,
  },
  {
    path: Path.contactOprator,
    element: <ContactingOperator />,
  },
  { path: Path.labProductOrder(), element: <LabProductOrderProductPage /> },

  {
    path: Path.labProductOrderProductDetail(),
    element: <LabProductOrderProductDetailPage />,
  },

  // 1D** System Management
  {
    path: Path.systemManagement,
    element: <SystemManagementTopPage />,
  },
  {
    path: Path.systemManagementRegisterSalon,
    element: <RegisterSalonFormPage />,
  },
  {
    path: Path.systemManagementEdit(),
    element: <RegisterSalonFormPage />,
  },
  {
    path: Path.systemManagementRegisterMenu,
    element: <RegisterMenuFormPage />,
  },
  {
    path: Path.systemManagementAllMenu,
    element: <SystemManagementAllMenuPage />,
  },
  {
    path: Path.systemManagementMenuEdit(),
    element: <EditMenuFormPage />,
  },
  {
    path: Path.systemManagementSettingAccessRight,
    element: <SettingAccessRightPage />,
  },
  {
    path: Path.systemManagementConnectDevice,
    element: <ConnectToDevice />,
  },
  {
    path: Path.systemManagementConnectDeviceSuccess,
    element: <ConnectSuccess />,
  },

  // 1E** Cashier Management
  {
    path: Path.cashierManagement,
    element: <CashierManagementTopPage />,
  },
  {
    path: Path.cashierManagementAll,
    element: <CashierManagementListPage />,
  },
  {
    path: Path.cashierManagementTotalSalesToday,
    element: <CashierManagementSalesTotalToday />,
  },
  {
    path: Path.cashierManagementCashierVisitDetail(),
    element: <CashierManagementVisitDetailPage />,
  },
  {
    path: Path.cashierManagementCashierNonVisitDetail(),
    element: <CashierManagementNonVisitDetailPage />,
  },
];

const routeList = [
  {
    path: "/",
    element: <PrivateRoute />,
    children: privateRouteList.map((item) => ({
      path: item.path,
      element: (
        <CheckAccessRights path={item.path}>{item.element}</CheckAccessRights>
      ),
    })),
  },

  {
    path: "/",
    element: <AuthRoute />,
    children: [{ path: Path.login, element: <Login /> }],
  },
  {
    path: "/Forbidden",
    element: <NoPermission />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

function Router() {
  const element = useRoutes(routeList);
  return element;
}

export default function AppRoute() {
  return (
    <>
      <BrowserRouter>
        <Router />
      </BrowserRouter>

      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

import client from "../client";
import moment from "moment";
import { defaultDateFormat, defaultTimeFormat } from "app/constants";

const customerBaseInfoAdapter = (rawData) => ({
  id: rawData._id,
  lastNameKata: rawData.lastNameKata,
  firstNameKata: rawData.firstNameKata,
  lastNameKanji: rawData.lastNameKanji,
  firstNameKanji: rawData.firstNameKanji,
  dob: moment(rawData.dob).tz("Asia/Tokyo").format(defaultDateFormat),
  phone1: rawData.phone1,
  phone2: rawData.phone2,
  email: rawData.email,
  postalCode: rawData.postalCode,
  address: rawData.address,
  // chen 
  salonCustomers: rawData.salonCustomers,
});

export const getAllHistoryOfOrdering = () => {
  return client.get("/scalp-data/find-scalp-data").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: [] };
  });
};

export const getAllScalpDataBatchingGroup = () => {
  return client.get("/scalp-data-group/get-all-scalp-data-group").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: [] };
  });
};

export const getScalpDataByBatchingGroup = (group_id) => {
  return client
    .get("/scalp-data-group/get-with-scalp-data-populated", {
      params: { id: group_id },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const batchDeliverToLab = (group_id) => {
  return client.put(
    `/scalp-data-group/batch-deliver-to-lab?id=${group_id}`,
    {}
  );
};

export const getHistoryOfOrderingDetail = (customerNo) => {
  return client
    .get("/scalp-data/find-scalp-data", {
      params: { customerNo: customerNo },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const createNewScalpData = (newScalpData) => {
  return client.post("/scalp-data/create", newScalpData).then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: {} };
  });
};

export const receiveNewOrderKit = (id, data) => {
  return client
    .put(`/inspection-kit-salon-order/receive-new-order-kit?id=${id}`, {
      orderAmount: +data.orderAmount,
      orderStatus: "completed"
    })
    .then((res) => {
      return {
        data: res?.data,
      };
    });
};

export const createNewOrderKit = (newOrderKitData) => {
  return client
    .post("/inspection-kit-salon-order/create", newOrderKitData)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const createContact = (newContactData) => {
  return client
    .post("/scalp-lap-contact/create", newContactData)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const getAllSalonCustomer = () => {
  return client.get("/salon/salon-customer/all").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: [] };
  });
};

export const getSalonCustomer = (customerNo) => {
  return client
    .get(`/customer/customer/get-by-customer-no`, {
      params: { customerNo: customerNo },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    });
};

export const searchCustomerByCustomerNo = (customerNo) => {
  return client
    .get(`/customer/customer/search-by-customer-no`, {
      params: { customerNo: customerNo },
    })
    .then((res) => {
      if (res.data.success) {
        const rawList = res.data.result;
        return {
          data: rawList.map((rawData) => customerBaseInfoAdapter(rawData)),
        };
      }
      return {
        data: [],
      };
    });
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import { checkNotTimeValid, convertTimeToNumber } from "app/utils";
import { defaultDateFormat } from "app/constants";

import TableReservationRegister from "../components/TableReservationRegister";
import SmallInputDateField from "../components/SmallInputDateField";
import SmallInputTimeField from "../components/SmallInputTimeField";
import { getStoreManagementStatus } from "../../../services/api";

function Step3Form(props) {
  const { step3Data, setStep3Data } = props;

  const now = useMemo(() => moment(), []);

  const handleChangeDate = (newDate) => {
    if (newDate.format(defaultDateFormat) === step3Data?.selectedDate) return;
    setStep3Data((oldData) => ({
      ...oldData,
      selectedDate: newDate.format(defaultDateFormat),
    }));
  };

  const handleSelectTime = (time) => {
    setStep3Data((oldData) => ({
      ...oldData,
      selectedTime: time,
    }));
  };

  return (
    <div className="w-full h-auto flex flex-col p-2 border border-gray-400 rounded-md">
      <div className="w-full h-10 flex justify-between mb-2">
        <div className="w-44 h-full flex items-center">
          <SmallInputDateField
            value={
              step3Data?.selectedDate
                ? moment(step3Data.selectedDate, defaultDateFormat)
                : null
            }
            setValue={handleChangeDate}
            shouldDisableDate={(date) =>
              now.subtract(1, "days").valueOf() > date.valueOf()
            }
            inputFontSize="14px"
          />
        </div>
      </div>

      <div className="w-full h-10 flex justify-between">
        <div className="w-44 h-full flex items-center">
          <SmallInputTimeField
            time={step3Data.selectedTime}
            setTime={handleSelectTime}
          />
        </div>
      </div>
    </div>
  );
}

function Step3SelectTimeAndStaff(props) {
  const { handleBackToStep2, step2Data, step3DataProps, handleNextToStep4 } =
    props;

  const [step3Data, setStep3Data] = useState(() => ({
    selectedDate: moment().format(defaultDateFormat),
    selectedTime: "",
    selectedStaff: null,
  }));

  const [showTabpersonInCharge, setShowTabpersonInCharge] = useState(false);

  const [loadStaffAvailableState, setLoadStaffAvailableState] = useState({
    isLoading: true,
    data: [],
    error: null,
  });

  const loadAllStaffAvailable = useCallback(() => {
    setLoadStaffAvailableState({
      isLoading: true,
      data: [],
      error: null,
    });

    getStoreManagementStatus(step3Data.selectedDate)
      .then((res) => {
        setLoadStaffAvailableState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadStaffAvailableState({
          isLoading: false,
          data: [],
          error: error,
        });
      });
  }, [step3Data.selectedDate]);

  const durationOfReservation = useMemo(() => {
    if (!Array.isArray(step2Data)) return 0;
    return step2Data.reduce((sum, item) => sum + item.durationNumber / 60, 0);
  }, [step2Data]);

  useEffect(() => {
    console.log("step3DataProps");
    setStep3Data({
      selectedDate:
        step3DataProps?.visitDate || moment().format(defaultDateFormat),
      selectedTime: step3DataProps?.startTime || "",
      selectedStaff: step3DataProps?.staff || null,
    });
  }, [step3DataProps]);

  useEffect(() => {
    loadAllStaffAvailable();
  }, [loadAllStaffAvailable]);

  const isValidReservation = useMemo(() => {
    const allStaffReservation = loadStaffAvailableState.data || [];
    const selectedStaff = step3Data.selectedStaff;
    const selectedTime = step3Data.selectedTime;

    if (!Array.isArray(allStaffReservation) || !selectedTime) return false;

    const staffReservation = allStaffReservation.find(
      (item) => item.id === selectedStaff?.id
    );
    if (!staffReservation || !staffReservation.availableShiftPlan) return false;

    const availableShiftPlan = staffReservation.availableShiftPlan;
    const startAvailableNumber = convertTimeToNumber(
      availableShiftPlan.startTime
    );
    const endAvailableNumber = convertTimeToNumber(
      availableShiftPlan.finishTime
    );
    const selectedTimeNumber = convertTimeToNumber(selectedTime);

    if (
      selectedTimeNumber < startAvailableNumber ||
      selectedTimeNumber + durationOfReservation > endAvailableNumber
    )
      return false;

    const listTimeNotAvailable = staffReservation.reservationList.map(
      (item) => [
        convertTimeToNumber(item.startTime),
        convertTimeToNumber(item.finishTime),
      ]
    );

    return !checkNotTimeValid(listTimeNotAvailable, [
      selectedTimeNumber,
      selectedTimeNumber + durationOfReservation,
    ]);
  }, [loadStaffAvailableState.data, step3Data, durationOfReservation]);

  const handleNextClick = () => {
    const selectedTimeArr = step3Data.selectedTime.split(":");
    const selectedTime = `${`0${Number(selectedTimeArr[0])}`.slice(
      -2
    )}:${`0${Number(selectedTimeArr[1])}`.slice(-2)}`;
    if (isValidReservation) {
      handleNextToStep4(
        step3Data.selectedDate,
        selectedTime,
        step3Data.selectedStaff
      );
    }
  };

  return (
    <>
      <div className="w-full h-full">
        <div className="w-full h-full flex flex-col lg:flex-row lg:justify-between overflow-hidden">
          <div className="w-full h-auto lg:w-48 lg:h-full lg:pt-8 flex flex-row lg:flex-col justify-between pb-4 lg:pb-0">
            <div className="w-48">
              <Step3Form
                step3Data={step3Data}
                setStep3Data={setStep3Data}
                loadStaffAvailableState={loadStaffAvailableState}
              />
            </div>

            <div>
              <div className="w-full h-auto flex flex-col items-end justify-end text-white text-base font-semibold">
                <button
                  className={`button-size mb-3 ${
                    isValidReservation
                      ? "bg-blue-btn-primary"
                      : "bg-gray-btn-secondary cursor-default"
                  }`}
                  onClick={handleNextClick}
                  disabled={!isValidReservation}
                >
                  予約内容確認へ
                </button>

                <button
                  className="button-size bg-gray-btn-secondary"
                  onClick={handleBackToStep2}
                >
                  戻る
                </button>
              </div>
            </div>
          </div>

          {/* TableReservationRegister */}
          <div className="w-full h-full flex-1 lg:h-full lg:w-[calc(100%-192px)] lg:pl-4 relative overflow-auto pt-8">
            <div className="w-full h-full">
              <div className="w-full h-8 absolute inset-x-0 top-[1px] flex px-6 z-10">
                <button
                  type="button"
                  onClick={() => setShowTabpersonInCharge(false)}
                  className={`w-auto h-8 px-5 border border-b-0 border-gray-400 rounded-t-xl text-sm font-bold ${
                    !showTabpersonInCharge ? "bg-[#F0F1EF] bg-opacity-50" : "opacity-50"
                  }`}
                >
                  店舗管理状況
                </button>
                <button
                  type="button"
                  onClick={() => setShowTabpersonInCharge(true)}
                  className={`w-auto h-8 px-5 border border-b-0 border-gray-400 rounded-t-xl text-sm font-bold ${
                    showTabpersonInCharge ? "bg-[#F0F1EF] bg-opacity-50" : "opacity-50"
                  }`}
                >
                  担当別
                </button>
              </div>
              <div className="w-full h-full border border-gray-400 rounded-md relative">
                <TableReservationRegister
                  allStaffReservation={loadStaffAvailableState.data || []}
                  selectedStaff={step3Data.selectedStaff}
                  selectedTime={step3Data.selectedTime}
                  selectedDate={step3Data.selectedDate}
                  durationOfReservation={durationOfReservation}
                  handleSelectStaff={(staff) => {
                    setStep3Data((oldData) => ({
                      ...oldData,
                      selectedStaff: staff,
                    }));
                  }}
                  handleSelectTime={(time) => {
                    setStep3Data((oldData) => ({
                      ...oldData,
                      selectedTime: time,
                    }));
                  }}
                  isValidReservation={isValidReservation}
                />

                {showTabpersonInCharge ? (
                  <div className="w-full h-full absolute inset-0 rounded-md bg-[#F0F1EF] bg-opacity-50 z-[100]"></div>
                ) : null}
              </div>
            </div>
          </div>

        </div>
      </div>
      <LoadingBackdrop isOpen={loadStaffAvailableState.isLoading} />
    </>
  );
}

export default Step3SelectTimeAndStaff;

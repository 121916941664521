import React, { useState } from "react";
import MainMenuItem from "app/components/MainMenuItem";
import PaymentProductDialog from "./PaymentProductDialog";

function InputPaymentProdcuts(props) {
  const { selectedProduct, setSelectedProduct } = props;
  const [showPaymentProductDialog, setShowPaymentProductDialog] =
    useState(false);

  const handleClosePaymentProductDialog = () => {
    setShowPaymentProductDialog(false);
  };

  const handleOpenPaymentProductDialog = () => {
    setShowPaymentProductDialog(true);
  };

  return (
    <>
      <div className="w-full h-auto flex items-center flex-wrap">
        <div className="w-auto sm:w-5/12">
          <div className="flex flex-col items-end">
            <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center w-[160px] mb-3">
              その他の販売
            </div>
          </div>
        </div>
        <div className="w-auto sm:w-7/12 pl-4">
          <div className="flex gap-4 mb-3 ">
            <MainMenuItem
              itemTitle={<p className="text-xs font-medium">詳細へ</p>}
              onClick={handleOpenPaymentProductDialog}
              borderSize={4}
              size={50}
              isActive={showPaymentProductDialog}
            />
          </div>
        </div>
      </div>

      <PaymentProductDialog
        open={showPaymentProductDialog}
        handleClose={handleClosePaymentProductDialog}
        selectedProductProps={selectedProduct}
        setSelectedProductProps={setSelectedProduct}
      />
    </>
  );
}

export default InputPaymentProdcuts;

import client from "../client";
import { defaultDateFormat, defaultTimeFormat } from "app/constants";
import moment from "moment";
import "moment-timezone";

const visitAdapter = (rawData) => ({
  id: rawData._id,

  visitDate: moment(rawData.visitDate)
    .tz("Asia/Tokyo")
    .format(defaultDateFormat),
  expectedStartTime: moment(rawData.expectedStartTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  expectedFinishTime: moment(rawData.expectedFinishTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  startTime:
    typeof rawData.startTime === "string" && rawData.startTime
      ? moment(rawData.startTime).tz("Asia/Tokyo").format(defaultTimeFormat)
      : "",
  finishTime:
    typeof rawData.finishTime === "string" && rawData.finishTime
      ? moment(rawData.finishTime).tz("Asia/Tokyo").format(defaultTimeFormat)
      : "",

  customer: rawData.customer
    ? {
        ...rawData.customer,
        id: rawData.customer._id,
      }
    : {},

  staff: rawData.staff
    ? {
        ...rawData.staff,
        id: rawData.staff._id,
      }
    : {},

  sales: Array.isArray(rawData.sales)
    ? rawData.sales.map((sale) => ({
        id: sale._id,
        name: sale.name,
        price: sale.price,
        amount: sale.amount,
        product: { id: sale.productId },
      }))
    : [],

  visitMenus: Array.isArray(rawData.visitmenus)
    ? rawData.visitmenus.map((visitmenu) => ({
        id: visitmenu._id,
        menuType: visitmenu.menuType,
        name: visitmenu.name,
        durationString: visitmenu.durationString,
        durationNumber: visitmenu.durationNumber,
        priceTaxExc: visitmenu.priceTaxExc,
        priceTaxInc: visitmenu.priceTaxInc,
        salonMenuId: visitmenu.salonMenuId,
      }))
    : [],

  payment: rawData.payment,
  discounts: rawData.discounts,
  counsellings: Array.isArray(rawData.counsellings)
    ? rawData.counsellings.map((counselling) => ({
        id: counselling._id,
        counsellingDate: moment(counselling.counsellingDate)
          .tz("Asia/Tokyo")
          .format(defaultTimeFormat),
        memo: counselling.memo,
      }))
    : [],
});

const productAdapter = (rawData) => ({
  id: rawData._id,
  name: rawData.name,
  price: rawData.priceTaxExc,
  priceTaxExc: rawData.priceTaxExc,
  priceTaxInc: rawData.priceTaxInc,
  description: rawData.description,
  imageUrl: rawData.imageUrl,
  category: rawData.category,
});

export const getAllVisitsToday = () => {
  const today = moment().format(defaultDateFormat);

  return client
    .get("/salon/visit/all", {
      params: { visitDate: today },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result.map((rawData) => visitAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const getVisitDetailForPayment = (id) => {
  return client
    .get("salon/visit/get", {
      params: { id: id },
    })
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        return {
          data: visitAdapter(rawData),
        };
      }
      return {
        data: null,
      };
    });
};

export const updateVisitDetail = (id, data) => {
  return client.put(`/salon/visit/update?id=${id}`, data).then((res) => {
    if (res.data.success) {
      const rawData = res.data.result;
      return {
        data: visitAdapter(rawData),
      };
    }
    return {
      data: null,
    };
  });
};

export const getAllSaleProducts = () => {
  return client.get("/product/monopoly").then((res) => {
    if (res.data.success) {
      const rawList = res.data.result;
      return {
        data: rawList.map((rawData) => productAdapter(rawData)),
      };
    }
    return {
      data: [],
    };
  });
};

export const paymentForVisit = (visitId, paymentData) => {
  const data = {
    paymentType: paymentData.paymentType,
    paymentMethod: paymentData.paymentMethod,
    paymentAmount: paymentData.paymentAmount,
    paymentDate: moment().toISOString(),
  };

  return client
    .post(`/salon/visit/payment-visit?id=${visitId}`, data)
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        return {
          data: visitAdapter(rawData),
        };
      }
      return {
        data: null,
      };
    });
};

export const paymentForNonVisit = (paymentData) => {
  const data = {
    paymentType: paymentData.paymentType,
    paymentMethod: paymentData.paymentMethod,
    paymentAmount: paymentData.paymentAmount,
    paymentDate: moment().toISOString(),
  };

  return client.post("/non-visit-payment/create", data).then((res) => {
    if (res.data.success) {
      const rawData = res.data.result;
      return {
        data: { ...rawData, id: rawData.id },
      };
    }
    return {
      data: null,
    };
  });
};

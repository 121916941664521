import React from "react";
import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import { labProducts, labProductSalonOrder } from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import SegmentedControl from "app/components/common/SegmentedControl";
import Product from "app/components/Product";
import BackBtn from "app/components/common/BackButton";
import Button from "@mui/material/Button";
import moment from "moment";

function LabProductOrderProductPage() {
  const { segmentName } = useParams();
  const navigate = useNavigate();

  const [segmentIndex, setSegmentIndex] = useState(
    segmentName === "normal" ? 1 : 0
  );

  const [salonOrderItems, setSalonOrderItems] = useState([]);
  const [salonOrderItemLoaded, setSalonOrderItemLoaded] = useState(false);
  const [normalItems, setNormalItems] = useState([]);
  const [normalItemLoaded, setNormalItemLoaded] = useState(false);

  const segments = [
    {
      label: "発注情報",
      value: "orderInfo",
      ref: useRef(),
    },
    {
      label: "商品",
      value: "normal",
      ref: useRef(),
    },
  ];

  const registerNewOrderProduct  = async (values) => {
    const input = {
      orderAmount: values.orderAmount
    }
    labProductSalonOrder.receiveNewOrderProduct(values._id, input)
    .then((res) => {
      getAllLabProductSalonOrder();
    })
    .catch((e) => {
      alert(e);
    })
    .finally(() => {
    });
  };

  const findFromSegmentsOrDefault = (segments, value, defaultIndex = 0) => {
    const index = segments.findIndex((segment) => segment.value === value);
    return index === -1 ? defaultIndex : index;
  };
  const defaultIndex = findFromSegmentsOrDefault(segments, segmentName, 0);

  useEffect(() => {
    // Reload the form page
    setSegmentIndex(defaultIndex);
  }, [segmentIndex, segmentName, defaultIndex]);

  const segmentChanged = (value, index) => {
    setSegmentIndex(index);
    navigate(Path.labProductOrder(value));
  };


  async function getAllLabProductOrderNormalProducts() {
    const response = await labProducts.normal.all();
    const items = response.data;
    setNormalItems(items);
    setNormalItemLoaded(true);
  }

  async function getAllLabProductSalonOrder() {
    const response = await labProductSalonOrder.all();
    const items = response.data;
    setSalonOrderItems(items);
    setSalonOrderItemLoaded(true);
  }

  useEffect(() => {
    getAllLabProductSalonOrder();
    getAllLabProductOrderNormalProducts();
  }, []);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "おすすめ商品",
      }}
    >
      <SegmentedControl
        name="group-1"
        callback={(val, idx) => segmentChanged(val, idx)}
        controlRef={useRef()}
        segments={segments}
        defaultIndex={segmentIndex}
      />
      {segmentIndex === 0 ? (
        <div className="w-full h-full flex gap-6">
          <div className="w-full h-1/12">
            <div className="w-full h-full relative min-w-[900px]">
              {/* header */}
              <div className="sticky top-0 left-0 w-full h-14 flex justify-center bg-gray-100 border-b z-10">
                <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate">
                  商品名
                </div>
                <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate">
                  発注日時
                </div>
                <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate">
                  発注数
                </div>
                <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate">
                  発注状況
                </div>
                <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate">
                  発送日時
                </div>
                <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate"></div>
              </div>

              {/* body */}
              {salonOrderItems?.map((row, index) => (
                // bg-blue-100
                <div
                    className={`w-full h-14 flex justify-center 
                    ${row.orderStatus === 'waiting' ? 'bg-red-100': 
                    row.orderStatus == "confirmed" ? 'bg-yellow-100':
                    row.orderStatus == "delivering" ? 'bg-green-100':
                    row.orderStatus == "completed" ? 'bg-blue-100': "bg-[#F0F1EF] bg-opacity-50"
                  } 
                    border-b`}
                  >
                  <div className="w-1/6 flex text-sm items-center justify-center">
                    {row.scalpLabProduct?.name? row.scalpLabProduct.name: ""}
                  </div>
                  <div className="w-1/6 flex text-sm items-center justify-center">
                    {row.orderDate? moment(row.orderDate).format("YYYY-MM-DD HH:mm"): ""}
                  </div>
                  <div className="w-1/6 flex text-sm items-center justify-center">
                    {row.orderAmount}
                  </div>
                  <div className="w-1/6 flex text-sm items-center justify-center">
                    {
                      row.orderStatus == "waiting"? "頭皮ラボ承認待ち": 
                      row.orderStatus == "confirmed"? "頭皮ラボ承認済み":
                      row.orderStatus == "delivering"? "頭皮ラボ発送済み":
                      row.orderStatus == "completed"? "商品到着済み": "未知"
                    }
                  </div>
                  <div className="w-1/6 flex text-sm items-center justify-center">
                    {row.deliveryDate? moment(row.deliveryDate).format("YYYY-MM-DD HH:mm"): ""}
                  </div>
                  <div className="w-1/6 flex text-sm items-center justify-center">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled= {row.orderStatus != "delivering"}
                      onClick={() => {
                        registerNewOrderProduct(row)
                      }}
                    >
                      商品到着
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Product.Grid items={normalItems} loading={!normalItemLoaded}>
          <Product.GridLoading>ローディング中</Product.GridLoading>
          <Product.GridEmpty>
            該当市販品おすすめ商品がないです。
          </Product.GridEmpty>
          <Product.GridItems>
            {normalItems.map((item) => (
              <Product.GridItem key={item.id}>{item}</Product.GridItem>
            ))}
          </Product.GridItems>
        </Product.Grid>
      )}
      <BackBtn url={Path.home}/>
    </DefaultLayout>
  );
}

export default LabProductOrderProductPage;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadInitError from "app/components/commonUI/LoadInitError";
import LoadingProgress from "app/components/commonUI/LoadingProgress";

import Path from "app/route/Path";
import { getAllStaffShiftPlans } from "app/services/api";
import {
  defaultDateFormat,
  jpDateFormat,
  showMonthStrFormat,
} from "app/constants";
import RegisterShiftPlanCalendar from "../components/RegisterShiftPlanCalendar";
import { CSVLink } from "react-csv";

function StaffManagementListShiftPlanPageContent(props) {
  const { showMonth, staffShiftPlans } = props;

  return (
    <div className="h-full flex items-center justify-center min-w-[560px]">
      <RegisterShiftPlanCalendar
        showMonth={showMonth}
        staffShiftPlans={staffShiftPlans}
        readonly={true}
        daySize={80}
      />
    </div>
  );
}

function StaffManagementListShiftPlanPage() {
  const [fetchInitState, setFetchInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const { staffId } = useParams();
  let [searchParams] = useSearchParams();

  const showMonth = useMemo(() => {
    const showMonthStr = searchParams.get("month");
    if (!showMonthStr) return moment();
    return moment(showMonthStr, showMonthStrFormat);
  }, [searchParams]);

  const loadAllShiftPlan = useCallback(() => {
    if (!showMonth) return;
    setFetchInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    const startDateOfMonth =
      showMonth.format(defaultDateFormat.slice(0, 7)) + "-01";
    const startDateOfNextMonth =
      showMonth.clone().add(1, "M").format(defaultDateFormat.slice(0, 7)) +
      "-01";

    getAllStaffShiftPlans(staffId, startDateOfMonth, startDateOfNextMonth)
      .then((res) => {
        const data = res.data;

        setFetchInitState({
          isLoading: false,
          data: data.map((item) => ({
            ...item,
            workDate: moment(item.workDate),
          })),
          error: null,
        });
      })
      .catch((error) => {
        setFetchInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [showMonth, staffId]);

  useEffect(() => {
    loadAllShiftPlan();
  }, [loadAllShiftPlan]);

  const csvData = useMemo(() => {
    let data = [["日付", "開始", "開始"]];
    if (Array.isArray(fetchInitState.data)) {
      fetchInitState.data.forEach((shiftplan) => {
        const newRow = [
          shiftplan.workDate.format(jpDateFormat),
          shiftplan.startTime,
          shiftplan.finishTime,
        ];
        data.push(newRow);
      });
    }
    return data;
  }, [fetchInitState.data]);

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p>勤怠</p>}>
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">出勤スタッフ</h2>
            <CSVLink
              filename={`staff-shiftplan-${showMonth.format(
                jpDateFormat
              )}-${showMonth.clone().add(1, "M").format(jpDateFormat)}.csv`}
              data={csvData}
              className="button-size bg-blue-btn-primary"
            >
              <FontAwesomeIcon icon={faDownload} />
              <span className="ml-2">エクスポート</span>
            </CSVLink>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full overflow-auto">
              {fetchInitState.isLoading ? <LoadingProgress /> : null}

              {!fetchInitState.isLoading && fetchInitState.error ? (
                <LoadInitError error={fetchInitState.error} />
              ) : null}
              {!fetchInitState.isLoading &&
              !fetchInitState.error &&
              Array.isArray(fetchInitState.data) ? (
                <StaffManagementListShiftPlanPageContent
                  staffShiftPlans={fetchInitState.data}
                  showMonth={showMonth}
                  staffId={staffId}
                />
              ) : null}
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.staffManagementRegisterShiftPlan(staffId)}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementListShiftPlanPage;

import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { useNavigate, useParams } from "react-router-dom";
import Path from "../../../route/Path";
import { getIntroductionOfScalpLab } from "../../../services/api";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import NoData from "../../../components/commonUI/NoData";

function SuggestScalpLabToCustomerDuringService() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [fetchIntroductionOfScalpLab, setFetchIntroductionOfScalpLab] =
    useState({
      isLoading: false,
      data: null,
      error: null,
    });

  const loadIntroductionOfScalpLab = () => {
    setFetchIntroductionOfScalpLab({
      isLoading: true,
      data: null,
      error: null,
    });

    getIntroductionOfScalpLab()
      .then((res) => {
        setFetchIntroductionOfScalpLab({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchIntroductionOfScalpLab({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadIntroductionOfScalpLab();
  }, []);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              頭皮ラボについて
            </h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchIntroductionOfScalpLab.isLoading ? (
                  <LoadingProgress />
                ) : null}

                {!fetchIntroductionOfScalpLab.isLoading &&
                fetchIntroductionOfScalpLab.error ? (
                  <LoadInitError error={fetchIntroductionOfScalpLab.error} />
                ) : null}
                {!fetchIntroductionOfScalpLab.isLoading &&
                !fetchIntroductionOfScalpLab.error ? (
                  <div className="w-full max-h-full mx-auto overflow-y-hidden overflow-y-scroll overflow-x-hidden">
                    <p className="pr-1">
                      {fetchIntroductionOfScalpLab.data?.content}{" "}
                      {fetchIntroductionOfScalpLab.data?.content}{" "}
                      {fetchIntroductionOfScalpLab.data?.content}{" "}
                      {fetchIntroductionOfScalpLab.data?.content}
                      {fetchIntroductionOfScalpLab.data?.content}
                      {fetchIntroductionOfScalpLab.data?.content}
                      {fetchIntroductionOfScalpLab.data?.content}
                    </p>
                  </div>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
          <div className="w-full h-8 flex gap-4 lg:gap-6 items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() => navigate(Path.duringService(id))}
              type="button"
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
            <button
              onClick={() =>
                navigate(
                  Path.customersUndergoingTreatmentIntroductionScalpLab(id)
                )
              }
              type="button"
              className="button-size bg-blue-btn-primary"
            >
              申込書へ
            </button>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SuggestScalpLabToCustomerDuringService;

import React, { useMemo, useState } from "react";
import MainContentLayout from "app/layouts/MainContentLayout";
import { Link, useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import { Tooltip } from "@mui/material";
import { payment_type_and_method } from "app/constants";
import MainMenuItem from "app/components/MainMenuItem";
import InputPaymentProdcuts from "../components/InputPaymentProdcuts";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import EnterCashDialog from "../PaymentForReservation/PaymentByCash/EnterCashDialog";
import { formatPrice } from "app/utils";
import { paymentForNonVisit } from "app/services/api";
import { toast } from "react-toastify";
import PrintReceiptDialog from "../../../components/PrintReceiptDialog.js";

const paymentStatusOptions = {
  idle: "idle",
  loading: "loading",
  success: "success",
  error: "error",
};

function InputPaymentMethod(props) {
  const { paymentType, paymentMethod, handleSelectPaymentMethod } = props;

  return (
    <div className="w-ffull h-auto mb-8">
      <div className="w-full h-auto flex items-center flex-wrap">
        <div className="w-full sm:w-5/12">
          <div className="flex flex-col items-start sm:items-end">
            <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
              {payment_type_and_method.credit_card.payment_type_label}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-7/12 pl-4">
          <div className="flex gap-4 mb-3 ">
            {payment_type_and_method.credit_card.payment_methods.map(
              (payment_method) => (
                <MainMenuItem
                  itemTitle={
                    <p className="text-xs font-medium">
                      {payment_method.payment_method_label}
                    </p>
                  }
                  onClick={() => {
                    handleSelectPaymentMethod({
                      paymentType:
                        payment_type_and_method.credit_card.payment_type,
                      paymentMethod: payment_method.payment_method,
                    });
                  }}
                  borderSize={4}
                  size={50}
                  isActive={
                    paymentType ===
                      payment_type_and_method.credit_card.payment_type &&
                    paymentMethod === payment_method.payment_method
                  }
                />
              )
            )}
          </div>
        </div>

        <div className="w-full sm:w-5/12">
          <div className="flex flex-col items-start sm:items-end">
            <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
              {payment_type_and_method.qr_code.payment_type_label}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-7/12 pl-4">
          <div className="flex gap-4 mb-3 ">
            {payment_type_and_method.qr_code.payment_methods.map(
              (payment_method) => (
                <MainMenuItem
                  itemTitle={
                    <p className="text-xs font-medium">
                      {payment_method.payment_method_label}
                    </p>
                  }
                  onClick={() => {
                    handleSelectPaymentMethod({
                      paymentType: payment_type_and_method.qr_code.payment_type,
                      paymentMethod: payment_method.payment_method,
                    });
                  }}
                  borderSize={4}
                  size={50}
                  isActive={
                    paymentType ===
                      payment_type_and_method.qr_code.payment_type &&
                    paymentMethod === payment_method.payment_method
                  }
                />
              )
            )}
          </div>
        </div>

        <div className="w-full sm:w-5/12">
          <div className="flex flex-col items-start sm:items-end">
            <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
              {payment_type_and_method.eletronic_payment.payment_type_label}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-7/12 pl-4">
          <div className="flex gap-4 mb-3 ">
            {payment_type_and_method.eletronic_payment.payment_methods.map(
              (payment_method) => (
                <MainMenuItem
                  itemTitle={
                    <p className="text-xs font-medium">
                      {payment_method.payment_method_label}
                    </p>
                  }
                  onClick={() => {
                    handleSelectPaymentMethod({
                      paymentType:
                        payment_type_and_method.eletronic_payment.payment_type,
                      paymentMethod: payment_method.payment_method,
                    });
                  }}
                  borderSize={4}
                  size={50}
                  isActive={
                    paymentType ===
                      payment_type_and_method.eletronic_payment.payment_type &&
                    paymentMethod === payment_method.payment_method
                  }
                />
              )
            )}
          </div>
        </div>
      </div>
      <div className="w-full h-auto flex items-center flex-wrap sm:flex-nowrap">
        <div className="w-auto sm:w-5/12">
          <div className="flex flex-col items-end">
            <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
              {payment_type_and_method.cash.payment_type_label}
            </div>
          </div>
        </div>
        <div className="w-auto sm:w-7/12 pl-4">
          <div className="flex gap-4 mb-3 ">
            {payment_type_and_method.cash.payment_methods.map(
              (payment_method) => (
                <MainMenuItem
                  itemTitle={
                    <p className="text-xs font-medium">
                      {payment_method.payment_method_label}
                    </p>
                  }
                  onClick={() => {
                    handleSelectPaymentMethod({
                      paymentType: payment_type_and_method.cash.payment_type,
                      paymentMethod: payment_method.payment_method,
                    });
                  }}
                  borderSize={4}
                  size={50}
                  isActive={
                    paymentType === payment_type_and_method.cash.payment_type &&
                    paymentMethod === payment_method.payment_method
                  }
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PaymentInput(props) {
  const {
    selectedProduct,
    setSelectedProduct,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setConfimredPaymentData,
  } = props;

  const isValidPaymentMethod = useMemo(
    () =>
      selectedPaymentMethod.paymentType && selectedPaymentMethod.paymentMethod,
    [selectedPaymentMethod]
  );

  const isValidSelectedProduct = useMemo(
    () =>
      Array.isArray(selectedProduct) &&
      selectedProduct.filter((item) => item.amount > 0).length > 0,
    [selectedProduct]
  );

  const nextToConfirm = () => {
    if (isValidPaymentMethod && isValidSelectedProduct)
      setConfimredPaymentData({
        selectedProduct: selectedProduct,
        selectedPaymentMethod: selectedPaymentMethod,
      });
  };

  return (
    <div className="w-full h-full pt-16 pb-8 lg:pt-0 relative">
      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto min-h-full flex flex-col justify-center">
            <div className="w-full h-full">
              <InputPaymentMethod
                paymentType={selectedPaymentMethod.paymentType}
                paymentMethod={selectedPaymentMethod.paymentMethod}
                handleSelectPaymentMethod={setSelectedPaymentMethod}
              />

              <InputPaymentProdcuts
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <Link to={Path.home} className="button-size bg-gray-btn-secondary mr-4">
          戻る
        </Link>

        <div>
          <Tooltip
            title={() => {
              if (!isValidPaymentMethod)
                return "お支払い方法を選択してください";
              if (!isValidSelectedProduct) return "製品を選択してください";
              return "";
            }}
          >
            <button
              className={`button-size ${
                !isValidPaymentMethod || !isValidSelectedProduct
                  ? "bg-gray-btn-secondary"
                  : "bg-blue-btn-primary"
              } `}
              onClick={nextToConfirm}
            >
              金額確認
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

function PaymentComfirm(props) {
  const { confimredPaymentData, setConfimredPaymentData } = props;

  const [showEnterCash, setShowEnterCash] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(paymentStatusOptions.idle);
  const [showPrint, setShowPrint] = useState(false);

  const navigate = useNavigate();

  const totalVisitWithoutDiscount = useMemo(() => {
    const selectedProduct = confimredPaymentData.selectedProduct;

    let amount = 0;

    if (Array.isArray(selectedProduct))
      selectedProduct.forEach((saleItem) => {
        amount += (saleItem?.priceTaxExc || 0) * (saleItem?.amount || 0);
      });

    return amount;
  }, [confimredPaymentData]);

  const totalTax = useMemo(() => {

    return totalVisitWithoutDiscount * 0.1;
  }, [totalVisitWithoutDiscount]);

  const getPaymentMethodTitle = ({ paymentType, paymentMethod }) => {
    const paymentMethodArr =
      payment_type_and_method?.[paymentType]?.payment_methods;
    const paymentMethodObj = paymentMethodArr.find(
      (item) => item.payment_method === paymentMethod
    );
    return paymentMethodObj?.payment_method_label || "";
  };

  const handleCallPayment = () => {
    if (
      paymentStatus === paymentStatusOptions.success ||
      paymentStatus === paymentStatusOptions.loading
    )
      return;

    const paymentData = {
      paymentDate: "",
      paymentType: confimredPaymentData.selectedPaymentMethod.paymentType,
      paymentMethod: confimredPaymentData.selectedPaymentMethod.paymentMethod,
      paymentAmount: totalVisitWithoutDiscount + totalTax,
    };
    setPaymentStatus(paymentStatusOptions.loading);

    paymentForNonVisit(paymentData)
      .then((res) => {
        toast.success("支払い成功");
        setShowEnterCash(false);
        setPaymentStatus(paymentStatusOptions.success);
      })
      .catch(() => {
        toast.error("支払い失敗");
        setPaymentStatus(paymentStatusOptions.error);
      });
  };

  return (
    <>
      <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
        <div className="w-full h-full py-3">
          <div className="w-full h-full overflow-auto">
            <div className="w-full h-auto min-h-full flex flex-col justify-center">
              <div className="flex w-full flex-wrap sm:flex-nowrap">
                <div className="w-full sm:w-1/2 h-auto flex flex-col sm:pr-8">
                  {Array.isArray(confimredPaymentData?.selectedProduct)
                    ? confimredPaymentData?.selectedProduct.map((saleItem) => (
                        <div className="w-full h-auto flex items-center justify-between mb-4">
                          {/* service name */}
                          <div className="flex-1 truncate font-semibold">
                            {saleItem?.name || ""}
                          </div>

                          {/* service price */}
                          <div className="">{`${saleItem.amount}x${formatPrice(
                            saleItem.priceTaxExc
                          )}`}</div>
                        </div>
                      ))
                    : null}
                </div>

                <div className="w-full h-1 bg-[#e5e7eb] my-6 block sm:hidden" />

                <div className="w-full sm:w-1/2 h-auto flex flex-col">
                  <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-0">
                    <div className="w-1/3 font-medium">小計1(税抜)</div>
                    <div>{formatPrice(totalVisitWithoutDiscount)}</div>
                  </div>

                  <div className="w-full h-auto flex items-center justify-between mb-3">
                    <div className="">割引</div>

                    <div className="flex-1 text-end text-green-500"></div>
                  </div>

                  <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-0">
                    <div className="">ご使用クーポン</div>
                    <div className="flex-1 text-end"></div>
                  </div>

                  <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-0">
                    <div className="w-1/3 font-medium">小計2</div>
                    <div>{formatPrice(totalVisitWithoutDiscount)}</div>
                  </div>

                  <div className="w-full h-auto flex items-center justify-between mb-3 relative pr-0">
                    <div className="w-1/3 font-medium">税額(10%)</div>
                    <div>{formatPrice(totalTax)}</div>
                  </div>

                  <div className="border-2 mb-4" />

                  <div className="w-full h-auto flex items-center justify-between mb-8 relative text-lg font-bold pr-0">
                    <div className="w-1/3 font-medium">合計</div>
                    <div className="flex-1 text-end">
                      {formatPrice(totalVisitWithoutDiscount + totalTax)}
                    </div>
                  </div>

                  <div className="flex items-center justify-between mb-3">
                    <div className="w-1/3 font-medium">お支払方法</div>

                    <div className="flex items-center justify-end">
                      <MainMenuItem
                        itemTitle={
                          <p className="text-xs font-medium">
                            {getPaymentMethodTitle(
                              confimredPaymentData.selectedPaymentMethod
                            )}
                          </p>
                        }
                        borderSize={4}
                        size={50}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
          {paymentStatus === paymentStatusOptions.success ? null : (
            <button
              className="button-size bg-gray-btn-secondary mr-4"
              onClick={() => setConfimredPaymentData(null)}
            >
              戻る
            </button>
          )}

          <button
            className="button-size bg-blue-btn-primary"
            onClick={() => {
              if (paymentStatus === paymentStatusOptions.success) {
                setShowPrint(true);
              } else {
                if (
                  confimredPaymentData.selectedPaymentMethod.paymentType ===
                  payment_type_and_method.cash.payment_type_label
                )
                  setShowEnterCash(true);
                else handleCallPayment();
              }
            }}
          >
            {paymentStatus === paymentStatusOptions.success
              ? "レシート出力"
              : "会計する"}
          </button>
        </div>
      </div>

      <LoadingBackdrop
        isOpen={paymentStatus === paymentStatusOptions.loading}
      />

      <EnterCashDialog
        open={
          showEnterCash &&
          confimredPaymentData.selectedPaymentMethod.paymentType ===
            payment_type_and_method.cash.payment_type_label
        }
        handleClose={() => setShowEnterCash(false)}
        total={totalVisitWithoutDiscount}
        handlePayment={handleCallPayment}
      />

      <PrintReceiptDialog
        open={showPrint}
        handleClose={() => {
          setShowPrint(false);
          navigate(Path.toDayVisitList);
        }}
        receiptInfo={{
          sales: confimredPaymentData.selectedProduct,
          payment: confimredPaymentData.selectedPaymentMethod,
        }}
      />
    </>
  );
}

function PaymentNonCustomerPage() {
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    paymentType: "",
    paymentMethod: "",
  });

  const [confimredPaymentData, setConfimredPaymentData] = useState(null);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>お帰り</p>
          </>
        }
      >
        {confimredPaymentData ? (
          <PaymentComfirm
            confimredPaymentData={confimredPaymentData}
            setConfimredPaymentData={setConfimredPaymentData}
          />
        ) : (
          <PaymentInput
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            setConfimredPaymentData={setConfimredPaymentData}
          />
        )}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default PaymentNonCustomerPage;

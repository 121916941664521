import moment from "moment";
import { defaultDateFormat } from "../../../constants";
import client from "../client";

const counselingSheetAdapter = (rawData) => ({
  id: rawData._id,
  counsellingDate: rawData.counsellingDate,
  memo: rawData.memo,
});

const getCounselingHistory = (customerId) => {
  return client
    .get("/counselling/get-all-counseling", {
      params: { customerId: customerId },
    })
    .then((res) => {
      if (res.data.success) {
        const rawDataList = res.data.result;
        if (Array.isArray(rawDataList))
          rawDataList.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        return {
          data: rawDataList.map((rawData) => counselingSheetAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const getAllCoundelingSheetByVisit = (id) => {
  return client
    .get("salon/visit/get", {
      params: { id: id },
    })
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        const rawDataList = rawData.counsellings;
        if (Array.isArray(rawDataList))
          rawDataList.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        return {
          data: rawDataList.map((rawData) => counselingSheetAdapter(rawData)),
        };
      }
      return {
        data: [],
      };
    });
};

export const addCounselingSheetToVisit = (visitId, newCounselings) => {
  const data = {
    visitId: visitId,
    counsellings: newCounselings.map((item) => ({
      memo: item.memo,
      counsellingDate: moment
        .tz(`${item.counsellingDate}`, defaultDateFormat, true, "Asia/Tokyo")
        .toISOString(),
    })),
  };

  return client.post("/counselling/create-many-for-visit", data).then((res) => {
    if (res.data.success) {
      const rawData = res.data.result;
      return { data: rawData };
    }
    return {
      data: null,
    };
  });
};

import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import InputField from "app/components/commonUI/InputField";
import InputDateField from "app/components/commonUI/InputDateField";
import { defaultDateFormat } from "../../../constants";

const validationErrMessages = {
  firstNameKanjiRequired: "名は必須です。",
  firstNameKanjiMaxLength: "名は最大 30 文字にする必要があります。",
  lastNameKanjiRequired: "姓は必須です。",
  lastNameKanjiMaxLength: "姓は最大 30 文字にする必要があります。",
  firstNameKataRequired: "メイは必須です。",
  firstNameKataMaxLength: "メイは最大 30 文字にする必要があります。",
  lastNameKataRequired: "セイは必須です。",
  lastNameKataMaxLength: "セイは最大 30 文字にする必要があります。",
  dobRequired: "生年月日は必須です。",
  phoneRequired: "電話番号は必須です。",
  phoneValid: "電話番号は 10 桁または 11 桁で構成する必要があります",
  emailRequired: "メールアドレスが必要です。",
  emailValid: "有効なメールアドレスを入力してください。",
  passwordRequired: "passwordが必要です。",
  passwordValid: "有効なpasswordを入力してください。",
  addressRequired: "住所は必須です。",
  postalCodeRequired: "郵便番号は必須です。",
  postalCodeValid: "郵便番号は7桁でなければなりません",
};

function CreateCustomerForm(props) {
  const {
    customerInfo = {},
    isReadMode,
    handleCreate,
    confirmCustomerData,
    hanldeBackClick,
  } = props;

  const validationSchema = Yup.object().shape({
    firstNameKanji: Yup.string()
      .required(validationErrMessages.firstNameKanjiRequired)
      .max(30, validationErrMessages.firstNameKanjiMaxLength),
    lastNameKanji: Yup.string()
      .required(validationErrMessages.lastNameKanjiRequired)
      .max(30, validationErrMessages.lastNameKanjiMaxLength),
    firstNameKata: Yup.string()
      .required(validationErrMessages.firstNameKataRequired)
      .max(30, validationErrMessages.firstNameKataMaxLength),
    lastNameKata: Yup.string()
      .required(validationErrMessages.lastNameKataRequired)
      .max(30, validationErrMessages.lastNameKataMaxLength),
    dob: Yup.object().required(validationErrMessages.dobRequired),
    phone1: Yup.string()
      .required(validationErrMessages.phoneRequired)
      .matches(/^(\d{10}|\d{11})$/, validationErrMessages.phoneValid),
    phone2: Yup.string().matches(
      /^(\d{10}|\d{11})$/,
      validationErrMessages.phoneValid
    ),
    email: Yup.string()
      .email(validationErrMessages.emailValid),
      //.required(validationErrMessages.emailRequired),
    postalCode: Yup.string()
      //.required(validationErrMessages.postalCodeRequired)
      .matches(/^(\d{7})$/, validationErrMessages.postalCodeValid),
    //address: Yup.string().required(validationErrMessages.addressRequired),
  });

  const onSubmit = async (values) => {
    console.log("confirmCustomerData: ", values);
    const dobString = values.dob.format(defaultDateFormat);

    confirmCustomerData({ ...values, dob: dobString });
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      firstNameKanji: customerInfo.firstNameKanji || "",
      lastNameKanji: customerInfo.lastNameKanji || "",
      firstNameKata: customerInfo.firstNameKata || "",
      lastNameKata: customerInfo.lastNameKata || "",
      dob: customerInfo.dob
        ? moment.tz(customerInfo.dob, defaultDateFormat, true, "Asia/Tokyo")
        : null,
      phone1: customerInfo.phone1 || "",
      phone2: customerInfo.phone2 || "",
      email: customerInfo.email || "",
      //password: customerInfo.password || "",
      postalCode: customerInfo.postalCode || "",
      address: customerInfo.address || "",
    },
    onSubmit,
    validationSchema,
  });

  return (
    <form className="w-full h-full relative lg:pb-12 lg:pt-0 pt-16 pb-8">
      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto min-h-full flex flex-col justify-center">
            <div className="w-full h-auto rounded-[17px] max-w-[960px] mx-auto bg-[#F0F1EF] bg-opacity-50 px-2 py-6">
              <div className="w-full h-auto px-3 pb-4">
                <div className="w-full h-8 flex items-center text-lg font-semibold rounded-t-lg text-white">
                  お名前
                </div>
                <div className="w-full h-auto">
                  <div className="w-full h-auto flex">
                    <div className="w-1/2 h-auto pr-3 mb-5">
                      <InputField
                        label=""
                        name="lastNameKata"
                        value={values.lastNameKata}
                        type="text"
                        onChange={(newValue) =>
                          setFieldValue("lastNameKata", newValue)
                        }
                        //inputClasses="bg-transparent"
                        placeholder="セイ"
                        handleBlur={handleBlur}
                        touched={touched.lastNameKata}
                        errors={errors.lastNameKata}
                        disabled={isReadMode}
                      />
                    </div>

                    <div className="w-1/2 h-auto pl-3">
                      <InputField
                        label=""
                        name="firstNameKata"
                        value={values.firstNameKata}
                        type="text"
                        onChange={(newValue) =>
                          setFieldValue("firstNameKata", newValue)
                        }
                        placeholder="メイ"
                        handleBlur={handleBlur}
                        touched={touched.firstNameKata}
                        errors={errors.firstNameKata}
                        disabled={isReadMode}
                      />
                    </div>
                  </div>

                  <div className="w-full h-auto flex">
                    <div className="w-1/2 h-auto pr-3">
                      <InputField
                        label=""
                        name="lastNameKanji"
                        value={values.lastNameKanji}
                        type="text"
                        onChange={(newValue) =>
                          setFieldValue("lastNameKanji", newValue)
                        }
                        placeholder="姓"
                        handleBlur={handleBlur}
                        touched={touched.lastNameKanji}
                        errors={errors.lastNameKanji}
                        disabled={isReadMode}
                      />
                    </div>

                    <div className="w-1/2 h-auto pl-3">
                      <InputField
                        label=""
                        name="firstNameKanji"
                        value={values.firstNameKanji}
                        type="text"
                        onChange={(newValue) =>
                          setFieldValue("firstNameKanji", newValue)
                        }
                        placeholder="名"
                        handleBlur={handleBlur}
                        touched={touched.firstNameKanji}
                        errors={errors.firstNameKanji}
                        disabled={isReadMode}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-auto px-3 pb-4">
                <div className="w-full h-8 flex items-center text-lg font-semibold text-white">
                  生年月日
                </div>
                <div className="w-full h-auto">
                  <div className="w-full h-auto flex gap-8 ">
                    <InputDateField
                      id="dob"
                      value={values.dob}
                      setValue={(newValue) => setFieldValue("dob", newValue)}
                      disabled={isReadMode}
                      className="bg-[#FFFFFF] w-full"
                      onBlur={handleBlur}
                      touched={touched.dob}
                      errors={errors.dob}
                      wfull={true}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full h-auto px-3 pb-4">
                <div className="w-full h-8 flex items-center text-lg font-semibold text-white">
                  電話番号
                </div>
                <div className="w-full h-auto">
                  <div className="w-full h-auto flex-col space-y-4">
                    <div className="w-full h-auto">
                      <InputField
                        label=""
                        name="phone1"
                        value={values.phone1}
                        type="text"
                        onChange={(newValue) =>
                          setFieldValue("phone1", newValue)
                        }
                        handleBlur={handleBlur}
                        touched={touched.phone1}
                        errors={errors.phone1}
                        disabled={isReadMode}
                        placeholder="電話番号１　ハイフンなし"
                      />
                    </div>
                    <div className="w-full h-auto">
                      <InputField
                        label=""
                        name="phone2"
                        value={values.phone2}
                        type="text"
                        onChange={(newValue) =>
                          setFieldValue("phone2", newValue)
                        }
                        handleBlur={handleBlur}
                        touched={touched.phone2}
                        errors={errors.phone2}
                        disabled={isReadMode}
                        placeholder="電話番号２　ハイフンなし"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-auto px-3 pb-4">
                <div className="w-full h-8 flex items-center text-lg font-semibold text-white">
                  メールアドレス
                </div>
                <div className="w-full h-auto">
                  <div className="w-full h-auto flex items-center">
                    <InputField
                      label=""
                      name="email"
                      value={values.email}
                      type="email"
                      onChange={(newValue) => setFieldValue("email", newValue)}
                      handleBlur={handleBlur}
                      touched={touched.email}
                      errors={errors.email}
                      disabled={isReadMode}
                      placeholder="example@gmail.com"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="w-ffull h-auto border-b border-gray-400">
                <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                  パスワード
                </div>
                <div className="w-full h-auto px-3 lg:px-6 py-4">
                  <div className="w-full h-auto flex items-center">
                    <InputField
                      label=" "
                      name="password"
                      value={values.password}
                      type="password"
                      onChange={(newValue) =>
                        setFieldValue("password", newValue)
                      }
                      handleBlur={handleBlur}
                      touched={touched.password}
                      errors={errors.password}
                      disabled={isReadMode}
                    />
                  </div>
                </div>
              </div> */}

              <div className="w-full h-auto px-3 pb-4">
                <div className="w-full h-8 flex items-center text-lg font-semibold text-white">
                  住所
                </div>
                <div className="w-full h-auto">
                  <div className="w-full h-auto flex items-start">
                    <div className="w-1/3 overflow-hidden pr-3">
                      <InputField
                        label=""
                        name="postalCode"
                        value={values.postalCode}
                        type="text"
                        onChange={(newValue) =>
                          setFieldValue("postalCode", newValue)
                        }
                        placeholder="NNNNNNN"
                        handleBlur={handleBlur}
                        touched={touched.postalCode}
                        errors={errors.postalCode}
                        disabled={isReadMode}
                      />
                    </div>

                    <div className="w-2/3 overflow-hidden pl-3">
                      <InputField
                        label=""
                        name="address"
                        value={values.address}
                        type="text"
                        onChange={(newValue) =>
                          setFieldValue("address", newValue)
                        }
                        handleBlur={handleBlur}
                        touched={touched.address}
                        errors={errors.address}
                        labelClasses="w-[16px]"
                        disabled={isReadMode}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-auto px-3 py-12  flex justify-center">
                <button
                  onClick={() => {
                    if (handleCreate) {
                      handleCreate();
                    } else {
                      handleSubmit();
                    }
                  }}
                  type="button"
                  className="w-1/2 h-12 button-size bg-[#B6A699] rounded-[10px] text-base"
                >
                  {!handleCreate ? "確認画面へ" : customerInfo?.id ? "変化" : "登録"}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          onClick={hanldeBackClick}
          type="button"
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          戻る
        </button>
        {/* <button
          onClick={() => {
            if (handleCreate) {
              handleCreate();
            } else {
              handleSubmit();
            }
          }}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          {!handleCreate ? "確認画面へ" : customerInfo?.id ? "変化" : "登録"}
        </button> */}
      </div>
    </form>
  );
}

export default CreateCustomerForm;

import React, { useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import CommonDialog from "app/components/CommonDialog";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const stepOptions = {
  CONFIRM: "CONFIRM",
  LOADING: "LOADING",
  COMPLETE: "COMPLETE",
};

const checkCustomerIssueId = {
  ISSUE_ID: "ISSUE_ID",
  NOT_ISSUE_ID: "NOT_ISSUE_ID",
};

function ConfirmIssueIdContent(props) {
  const { selected, setSelected } = props;

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <div className="">
      <FormControl>
        <RadioGroup value={selected} onChange={handleChange}>
          <FormControlLabel
            value={checkCustomerIssueId.ISSUE_ID}
            control={<Radio />}
            label="発行する"
          />
          <FormControlLabel
            value={checkCustomerIssueId.NOT_ISSUE_ID}
            control={<Radio />}
            label="発行しない"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

function ShowMembershipCardQrCode(props) {
  const { value } = props;

  return (
    <div className="w-full h-full">
      <div className="w-full h-auto py-6 flex items-center justify-center">
        <div className="w-60 h-60">
          {value ? <QRCodeCanvas value={value} size={240} /> : null}
        </div>
      </div>
    </div>
  );
}

function IssueMemberIdDialog(props) {
  const { open, handleClose, handleNextToPayment, customerId } = props;

  const [step, setStep] = useState(stepOptions.CONFIRM);
  const [selected, setSelected] = useState(null);

  const resetDialog = () => {
    setStep(stepOptions.CONFIRM);
    setSelected(null);
  };

  useEffect(() => {
    if (!open) resetDialog();
  }, [open]);

  const handleConfimed = () => {
    if (selected === checkCustomerIssueId.ISSUE_ID) {
      setStep(stepOptions.COMPLETE);
    } else if (selected === checkCustomerIssueId.NOT_ISSUE_ID) {
      handleNextToPayment();
    }
  };

  return (
    <>
      <CommonDialog
        open={open}
        handleClose={handleClose}
        handleBack={() => {}}
        handleConfim={() => {}}
        title={
          step !== stepOptions.COMPLETE
            ? "Q. 頭皮ラボ会員のIDを発行しますか？"
            : "下記QRコードを読み取ってください"
        }
        actions={
          step !== stepOptions.COMPLETE ? (
            <div className="w-full flex items-center justify-end text-white text-base font-semibold">
              {/* <button
                onClick={handleBack}
                type="button"
                className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
              >
                戻る
              </button> */}
              <button
                onClick={handleConfimed}
                type="button"
                className={`button-size ${
                  selected
                    ? "bg-blue-btn-primary cursor-pointer"
                    : "bg-gray-btn-secondary cursor-not-allowed"
                }`}
              >
                進む
              </button>
            </div>
          ) : (
            <div className="w-full flex items-center justify-end text-white text-base font-semibold h-12">
              <button
                onClick={handleNextToPayment}
                type="button"
                className="button-size bg-blue-btn-primary mr-4 lg:mr-6"
              >
                進む
              </button>
            </div>
          )
        }
      >
        {step !== stepOptions.COMPLETE ? (
          <ConfirmIssueIdContent
            selected={selected}
            setSelected={setSelected}
          />
        ) : (
          <ShowMembershipCardQrCode value={customerId} />
        )}
      </CommonDialog>
      <LoadingBackdrop isOpen={step === stepOptions.LOADING} />
    </>
  );
}

export default IssueMemberIdDialog;

import React, { useMemo } from "react";

import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
];

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="#59CE8F"
      />
      {/* <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 3}
        outerRadius={outerRadius + 5}
        fill={fill}
      /> */}
    </g>
  );
};

function CustomPieChart(props) {
  const { rate } = props;

  const data = useMemo(() => {
    if (!rate)
      return [
        { name: "", value: 0 },
        { name: "", value: 100 },
      ];

    return [
      { name: "", value: rate * 100 },
      { name: "", value: (1 - rate) * 100 },
    ];
  }, [rate]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={240} height={240}>
        <Pie
          activeIndex={0}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default CustomPieChart;

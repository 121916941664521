import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import ConfirmDeleteDialog from "../../../components/CommonDialog/ConfirmDeleteDialog";
import { formatPrice } from "../../../utils";
import { deleteMenu } from "../../../services/api/1D";
import { toast } from "react-toastify";

export default function TableAllMenu(props) {
  const navigate = useNavigate();
  const { allMenu, load, setLoad } = props;
  const [menuList, setMenuList] = useState(allMenu);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [menuItem, setMenuItem] = useState("");
  const handleDeleteMenuItem = (deleteData) => {
    deleteMenu(deleteData._id)
      .then((res) => {
        const newMenuList = menuList.filter(
          (menu) => menu._id !== deleteData._id
        );
        setMenuList(newMenuList);
        toast.success("削除成功");
        setLoad(!load);
      })
      .catch((error) => {
        toast.error("削除が成功しない");
      });
  };

  const findMenuCategoryName = (menuType) => {
    switch (menuType) {
      case "cut":
        return "カット";
      case "color":
        return "カラー";
      case "perm":
        return "パーマ";
      case "straight":
        return "ストレート";
      case "spa":
        return "スパ";
      case "treatment":
        return "トリートメント";
      case "other":
        return "その他";
      default:
        return menuType;
    }
  };

  const handleShowConfirmDelete = (menuItem) => {
    setShowConfirmDelete(true);
    setMenuItem(menuItem);
  };
  return (
    <>
      <div className="w-full h-full relative overflow-auto min-w-[900px]">
        {/* header */}
        <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
          <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
            メニュー名
          </div>
          <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
            メニューカテゴリ
          </div>
          <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
            所要時間
          </div>
          <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
            料金（税抜）
          </div>
          <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
            掲載
          </div>
          <div className="w-1/6 flex items-center justify-center shrink-0 truncate"></div>
        </div>

        {/* body */}
        {menuList.map((row, index) => (
          <div key={index} className="w-full h-14 flex bg-[#F0F1EF] bg-opacity-50 border-b">
            <div className="w-1/6 flex items-center justify-center">
              {row.name}
            </div>
            <div className="w-1/6 flex items-center justify-center">
              {findMenuCategoryName(row.menuType)}
            </div>
            <div className="w-1/6 flex items-center justify-center">
              {row.durationNumber} 分
            </div>
            <div className="w-1/6 flex items-center justify-center">
              {formatPrice(row.priceTaxExc)}
            </div>
            <div className="w-1/6 flex items-center justify-center">
              {row.publicityStatus === 0 ? "非公開" : "公開"}
            </div>
            <div className="w-1/6 flex items-end justify-center">
              <Button
                onClick={() => {
                  navigate(Path.systemManagementMenuEdit(row._id));
                }}
                className=" !text-black "
              >
                <FontAwesomeIcon icon={faEdit} className="!w-5 !h-5" />
              </Button>
              <Button
                onClick={() => handleShowConfirmDelete(row)}
                className="!text-red-500"
              >
                <FontAwesomeIcon icon={faTrashCan} className="!w-5 !h-5" />
              </Button>
            </div>
          </div>
        ))}
      </div>

      <ConfirmDeleteDialog
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        confirmTitle="以下のメニューを削除しますか？"
        confirmMessage={`メニュー名 ${menuItem.name}`}
        confirmTitleAgain="本当に削除してもよろしいでしょうか？"
        deleteCompleteTitle="メニューを削除しました"
        deleteCompleteBtnLabel="システム設定へ戻る"
        deleteCompleteAction={() => setShowConfirmDelete(false)}
        deleteAction={() => handleDeleteMenuItem(menuItem)}
        confirmCancleLabel="キャンセル"
        confirmCancleAgainLabel="キャンセル"
        confirmLabel="削除"
        confirmAgainLabel="削除"
      />
    </>
  );
}

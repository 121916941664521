import React, { useState } from "react";

import moment from "moment";
import { defaultDateFormat } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function CustomDatePicker(props) {
  const { value, onChange } = props;

  const [showMonth, setShowMonth] = useState(() => {
    if (value) return value;
    return moment();
  });

  const isExtraDays = (week, date) => {
    if (week === 0 && date > 10) {
      return true;
    } else if (week === 5 && date < 10) {
      return true;
    } else if (week === 4 && date < 10) {
      return true;
    } else {
      return false;
    }
  };

  const toLastMonth = () => {
    setShowMonth((month) =>
      month ? month.clone().add(-1, "month") : moment()
    );
  };
  const toNextMonth = () => {
    setShowMonth((month) => (month ? month.clone().add(1, "month") : moment()));
  };

  const getDate = () => {
    if (!showMonth) return null;
    var calendar = [];

    const startDate = moment([showMonth.year(), showMonth.month()])
      .clone()
      .startOf("month")
      .startOf("week");

    const endDate = moment([showMonth.year(), showMonth.month()])
      .clone()
      .endOf("month");

    const day = startDate.clone().subtract(1, "day");

    while (day.isBefore(endDate, "day")) {
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, "day").clone())
      );
    }

    if (calendar.length > 0) {
      return calendar.map((week, index) => (
        <tr className="">
          {week.map((day, dayIndex) => (
            <td className="w-12 h-12 xl:w-16 xl:h-16 border-2 border-white">
              <button
                type="button"
                className={`block w-full h-full bg-slate-100 hover:bg-slate-160 rounded-full relative ${
                  value &&
                  value.format(defaultDateFormat) ===
                    day.format(defaultDateFormat)
                    ? "!bg-blue-500 "
                    : ""
                }`}
                style={{
                  display: !isExtraDays(index, day.format("DD"))
                    ? "block"
                    : "none",
                }}
                onClick={() => {
                  onChange(day);
                }}
              >
                <span
                  className={`w-full h-full flex items-center justify-center text-base font-semibold text-gray-600 ${
                    value &&
                    value.format(defaultDateFormat) ===
                      day.format(defaultDateFormat)
                      ? "!text-white"
                      : dayIndex === 0
                      ? "text-red-500"
                      : dayIndex === 6
                      ? "text-blue-400"
                      : ""
                  }`}
                >
                  {day.format("DD")}
                </span>
              </button>
            </td>
          ))}
        </tr>
      ));
    }
  };

  return (
    <div className="">
      <div className="w-full h-auto flex items-center px-3 mb-2">
        <button
          type="button"
          onClick={toNextMonth}
          className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-200"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <h2 className="text-xl font-bold mx-3">
          {showMonth ? showMonth.format("YYYY年MM月") : ""}
        </h2>

        <button
          type="button"
          onClick={toLastMonth}
          className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-200"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <table>
        <tr className="">
          {["日", "月", "火", "水", "木", "金", "土"].map(
            (dayTitle, dayIndex) => (
              <td className="w-12 xl:w-16 h-8">
                <p className="w-full h-full flex items-center justify-center font-semibold">
                  <span
                    className={`text-gray-600 ${
                      dayIndex === 0
                        ? "!text-red-500"
                        : dayIndex === 6
                        ? "!text-blue-400"
                        : ""
                    }`}
                  >
                    {dayTitle}
                  </span>
                </p>
              </td>
            )
          )}
        </tr>
        {getDate()}
      </table>
    </div>
  );
}

import client from "../client";
import {
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultTimeFormat,
} from "app/constants";
import moment from "moment";

const staffAdapter = (rawData) => ({
  id: rawData._id,
  lastNameKata: rawData.lastNameKata,
  firstNameKata: rawData.firstNameKata,
  lastNameKanji: rawData.lastNameKanji,
  firstNameKanji: rawData.firstNameKanji,
  dob: moment(rawData.dob).tz("Asia/Tokyo").format(defaultDateFormat),
  phone: rawData.phone,
  email: rawData.email,
  postalCode: rawData.postalCode,
  address: rawData.address,
  imageUrl: rawData.imageUrl,
});

const shiftPlanAdapter = (rawData) => ({
  id: rawData._id,
  workDate: moment(rawData.workDate).tz("Asia/Tokyo").format(defaultDateFormat),
  startTime: moment(rawData.startTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  finishTime: moment(rawData.finishTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  attendance: rawData.attendance ? attendanceAdapter(rawData.attendance) : null,
  salonStaff: rawData.salonStaff,
});

const attendanceAdapter = (rawData) => ({
  id: rawData._id,
  workDate: moment(rawData.workDate).tz("Asia/Tokyo").format(defaultDateFormat),
  plannedStartTime: moment(rawData.plannedStartTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  plannedFinishTime: moment(rawData.plannedFinishTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  startTime: rawData.startTime
    ? moment(rawData.startTime).tz("Asia/Tokyo").format(defaultTimeFormat)
    : "",
  finishTime: rawData.finishTime
    ? moment(rawData.finishTime).tz("Asia/Tokyo").format(defaultTimeFormat)
    : "",
  shiftPlanId: rawData.refShiftPlanId,
  rawData: {
    workDate: rawData.workDate,
    plannedStartTime: rawData.plannedStartTime,
    plannedFinishTime: rawData.plannedFinishTime,
    startTime: rawData.startTime,
    finishTime: rawData.finishTime,
  },
});

const staffWithShiftPlanAdapter = (rawData) => ({
  id: rawData._id,
  lastNameKata: rawData.lastNameKata,
  firstNameKata: rawData.firstNameKata,
  lastNameKanji: rawData.lastNameKanji,
  firstNameKanji: rawData.firstNameKanji,
  dob: moment(rawData.dob).tz("Asia/Tokyo").format(defaultDateFormat),
  phone: rawData.phone,
  email: rawData.email,
  postalCode: rawData.postalCode,
  address: rawData.address,
  imageUrl: rawData.imageUrl,
  shiftplans: Array.isArray(rawData.shiftplan)
    ? rawData.shiftplan.map((item) => shiftPlanAdapter(item))
    : [],
  attendances: Array.isArray(rawData.attendance)
    ? rawData.attendance.map((item) => attendanceAdapter(item))
    : [],
});

const chartDataAdapter = (rawData) => ({
  dateTime: rawData.paymentDate,
  amount: rawData.paymentAmount,
});

const convertShiftPlanToJPTime = (rawData) => {
  const workDate = moment
    .tz(rawData.workDate, defaultDateFormat, true, "Asia/Tokyo")
    .toISOString();

  const startTime = moment
    .tz(
      `${rawData.workDate} ${rawData.startTime}`,
      defaultDateTimeFormat,
      true,
      "Asia/Tokyo"
    )
    .toISOString();
  const finishTime = moment
    .tz(
      `${rawData.workDate} ${rawData.finishTime}`,
      defaultDateTimeFormat,
      true,
      "Asia/Tokyo"
    )
    .toISOString();

  return {
    workDate,
    startTime,
    finishTime,
  };
};

const fakeResponseDataApi = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: responseData,
      });
    }, 1000);
  });
};

const fake_data_salon_staff_info = {
  id: "6942d1a5-1ea7-4b86-8105-0a45f1a25273",
  lastNameKata: "セイA",
  firstNameKata: "メイA",
  lastNameKanji: "姓",
  firstNameKanji: "名",
  dob: "1990-09-18",
  phone: "0123456789",
  email: "test@gmail.com",
  postalCode: "111-1111",
  address: "住所",
  imageUrl:
    "https://e7.pngegg.com/pngimages/550/997/png-clipart-user-icon-foreigners-avatar-child-face.png",
};

const fake_data_salon_staff_info_with_shift_plan = {
  id: "6942d1a5-1ea7-4b86-8105-0a45f1a25273",
  lastNameKata: "セイA",
  firstNameKata: "メイA",
  lastNameKanji: "姓",
  firstNameKanji: "名",
  dob: "1990-09-18",
  phone: "0123456789",
  email: "test@gmail.com",
  postalCode: "111-1111",
  address: "住所",
  imageUrl:
    "https://e7.pngegg.com/pngimages/550/997/png-clipart-user-icon-foreigners-avatar-child-face.png",
  shiftPlan: [{}],
};

const fake_data_attendance = {
  id: "650b232241cffb9fb116a3d9",
  workDate: "2023-09-18",
  plannedStartTime: "09:00",
  plannedFinishTime: "18:00",
  startTime: "09:00",
  finishTime: "",
};

export const getAllStaffWithShiftPlanByDate = (date) => {
  return client
    .get(`/salon/salon-staff/all-staff-with-shift-plan`, {
      params: { date: date },
    })
    .then((res) => {
      if (res.data.success) {
        const rawList = res.data.result;
        console.log("rawList: ", rawList);
        return {
          data: rawList.map((rawData) => staffWithShiftPlanAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const getAllSalonStaffs = () => {
  console.log("getAllSalonStaffs: ");

  return client.get(`/salon/salon-staff/all`).then((res) => {
    if (res.data.success) {
      const rawList = res.data.result;
      return {
        data: rawList.map((rawData) => staffAdapter(rawData)),
      };
    }
    return { data: [] };
  });
};

export const getDetailSalonStaff = (staffId) => {
  console.log("getDetailSalonStaff: ", staffId);

  return client
    .get(`/salon/salon-staff/get`, { params: { id: staffId } })
    .then((res) => {
      if (res.data.success) {
        return {
          data: staffAdapter(res.data.result),
        };
      }
      return { data: null };
    });
};

export const getAllStaffShiftPlans = (staffId, start, end) => {
  console.log("getAllStaffShiftPlans: ");
  console.log("staffId: ", staffId);
  console.log("start: ", start);
  console.log("end: ", end);

  return client
    .get(`/shift-plan/all-shift-plan-by-staff-and-period`, {
      params: { staffId: staffId, start: start, end: end },
    })
    .then((res) => {
      if (res.data.success) {
        const rawList = res.data.result;
        return {
          data: rawList.map((rawData) => shiftPlanAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const deleteStaffShiftPlan = (shiftPlanId) => {
  return client.delete(`/shift-plan/delete?id=${shiftPlanId}`).then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: null };
  });
};

export const updateStaffShiftPlan = (
  shiftPlanId,
  { workDate, startTime, finishTime }
) => {
  const shiftPlanJpDateTime = convertShiftPlanToJPTime({
    workDate,
    startTime,
    finishTime,
  });
  return client
    .put(`/shift-plan/update?id=${shiftPlanId}`, shiftPlanJpDateTime)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: null };
    });
};

export const registerStaffShiftPlans = (staffId, newShiftPlans) => {
  console.log("registerStaffShiftPlans :", staffId);
  console.log("newShiftPlans :", newShiftPlans);

  return client
    .post(
      `/shift-plan/create?staffId=${staffId}`,
      newShiftPlans.map((item) => convertShiftPlanToJPTime(item))
    )
    .then((res) => {
      if (res.data.success) {
        const rawList = res.data.result;
        return {
          data: rawList.map((rawData) => shiftPlanAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const registerSalonStaff = (data) => {
  console.log("registerSalonStaff :", data);

  return client.post(`/salon/salon-staff/create`, data).then((res) => {
    if (res.data.success) {
      return {
        data: staffAdapter(res.data.result),
      };
    }
    return { data: null };
  });
};

export const updateSalonStaff = (staffId, data) => {
  console.log("updateSalonStaff :");
  console.log("staffId :", staffId);
  console.log("data :", data);

  return client
    .put(`/salon/salon-staff/update?id=${staffId}`, data)
    .then((res) => {
      if (res.data.success) {
        return {
          data: staffAdapter(res.data.result),
        };
      }
      return { data: null };
    });
};

export const deleteSalonStaff = (staffId) => {
  console.log("deleteSalonStaff: ", staffId);

  return client
    .delete(`/salon/salon-staff/delete`, { params: { id: staffId } })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: null };
    });
};

export const getAllStaffAttendances = (staffId, start, end) => {
  console.log("getAllStaffAttendances: ", staffId);
  console.log("start: ", start);
  console.log("end: ", end);

  return client
    .get(`/attendance/all-attendance-by-staff-and-period`, {
      params: { staffId: staffId, start: start, end: end },
    })
    .then((res) => {
      if (res.data.success) {
        const rawList = res.data.result;
        return {
          data: rawList.map((rawData) => attendanceAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const getDetailSalonStaffAttendance = (attendanceId) => {
  console.log("getDetailSalonStaffAttendance: ", attendanceId);

  return fakeResponseDataApi(fake_data_attendance);
};

export const getDetailSalonStaffWithAttendance = (staffId, attendanceId) => {
  return Promise.all([
    getDetailSalonStaff(staffId),
    getDetailSalonStaffAttendance(attendanceId),
  ]).then((res) => {
    const staffInfo = res[0].data;
    const attendanceInfo = res[1].data;
    return {
      staffInfo,
      attendanceInfo,
    };
  });
};

export const updateStaffAttendance = (attendanceId, updateData) => {
  console.log("updateData", updateData);
  return client
    .put(`/attendance/update?id=${attendanceId}`, updateData)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: null };
    });
};

export const getStaffAttendance = (attendanceId) => {
  return client.get(`/attendance/get?id=${attendanceId}`).then((res) => {
    if (res.data.success) {
      return {
        data: attendanceAdapter(res.data.result),
      };
    }
    return { data: null };
  });
};

export const getStaffShiftPlanToday = (staffId) => {
  console.log("getAllStaffShiftPlans: ");
  const start = moment().tz("Asia/Tokyo").format(defaultDateFormat);
  const end = moment(start, defaultDateFormat)
    .add(1, "d")
    .format(defaultDateFormat);

  return client
    .get(`/shift-plan/all-shift-plan-by-staff-and-period`, {
      params: { staffId: staffId, start: start, end: end },
    })
    .then((res) => {
      if (
        res.data.success &&
        Array.isArray(res.data.result) &&
        res.data.result.length > 0
      ) {
        const rawList = res.data.result;
        return {
          data: shiftPlanAdapter(rawList[0]),
        };
      }
      return { data: null };
    });
};

export const staffShiftPlanAndCheckIn = (
  staffId,
  { workDate, startTime, finishTime }
) => {
  const newShiftPlan = {
    workDate: workDate.format(defaultDateFormat),
    startTime,
    finishTime,
  };

  const convertedData = convertShiftPlanToJPTime(newShiftPlan);
  console.log("convertedData", convertedData);

  return client
    .post(
      `/shift-plan/create-and-check-in?staffId=${staffId}`,
      convertShiftPlanToJPTime(newShiftPlan)
    )
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        return {
          data: shiftPlanAdapter(rawData),
        };
      }
      return { data: [] };
    });
};

export const getAllStaffWithShiftPlanByMonth = (month) => {
  return client
    .get(`/salon/salon-staff/all-staff-with-shift-plan-by-month`, {
      params: { month: month },
    })
    .then((res) => {
      if (res.data.success) {
        const rawList = res.data.result;
        console.log("rawList: ", rawList);
        return {
          data: rawList.map((rawData) => staffWithShiftPlanAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const checkLoginNameIsValid = (loginName) => {
  return client
    .get(`/salon/salon-staff/check-login-name`, {
      params: { loginName: loginName },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: false };
    });
};

import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreateCompleteDialog from "app/components/CommonDialog/CreateCompleteDialog";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import Path from "app/route/Path";
import {
  convertTimeToNumber,
  formatPrice,
  getNameFromFirstLast,
} from "../../../utils";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import {
  createNewReservation,
  createNewReservationForNonCustomer,
} from "app/services/api";
import { Avatar, Tooltip } from "@mui/material";

const statusOptions = {
  idle: "idle",
  loading: "loading",
  success: "success",
  error: "error",
};

const stepOptions = {
  step1: "CUSTOMER_INFO",
  step2: "MENU_LIST",
  step3: "TIME_AND_STEP",
  step4: "COMPLETE",
};

function ReservationMenuItem(props) {
  const { menuItem } = props;
  return (
    <>
      <div className="w-full h-10 bg-slate-200 rounded-md mb-2 px-2 relative">
        <div className="w-full h-full flex">
          <div className="w-1/2 flex items-center justify-start">
            <div className="w-auto h-8 px-5 flex items-center justify-center rounded-full bg-[#736966] text-sm font-semibold text-white">
              {menuItem.name}
            </div>
          </div>
          <div className="w-1/4 flex items-center justify-end">
            <p> {menuItem.durationString}</p>
          </div>
          <div className="w-1/4 flex items-center justify-end">
            <p>{formatPrice(menuItem.priceTaxExc) + "(税抜)"}</p>
          </div>
        </div>
      </div>
    </>
  );
}

function Step4ConfirmAndCreate(props) {
  const navigate = useNavigate();

  const { stepData, handleBackToStep3 } = props;
  const [createStatus, setCreateStatus] = useState(statusOptions.idle);
  const [nonCustomerInfo, setNonCustomerInfo] = useState({
    name: stepData[stepOptions.step1]?.firstNameKata || "",
    phone: "",
  });

  const isDisableCreateBtn = useMemo(() => {
    if (
      !stepData[stepOptions.step1].id &&
      (!nonCustomerInfo.name || !nonCustomerInfo.name.trim())
    )
      return true;
    return false;
  }, [stepData, nonCustomerInfo]);

  const handleCreate = () => {
    setCreateStatus(statusOptions.loading);
    const customerId = stepData[stepOptions.step1].id;
    if (!customerId && (!nonCustomerInfo.name || !nonCustomerInfo.name.trim()))
      return;

    const durationNumber =
      stepData[stepOptions.step2].reduce((a, b) => a + b.durationNumber, 0) /
      60;
    const startTime = stepData[stepOptions.step3].startTime;
    const startTimeNumber = convertTimeToNumber(startTime);
    const finishTimeNumber = startTimeNumber + durationNumber;
    const finishTime =
      `0${Math.floor(finishTimeNumber)}`.slice(-2) +
      ":" +
      `0${Math.floor((finishTimeNumber % 1) * 60)}`.slice(-2);

    const data = {
      visitDate: stepData[stepOptions.step3].visitDate,
      startTime: startTime,
      finishTime: finishTime,
      staffId: stepData[stepOptions.step3].staff.id,
      salonMenuIds: stepData[stepOptions.step2].map((item) => item.id),
    };

    if (customerId) {
      createNewReservation(customerId, data)
        .then((res) => {
          setCreateStatus(statusOptions.success);
          toast.success("Create success!");
        })
        .catch((error) => {
          setCreateStatus(statusOptions.error);
          toast.error("Create fail!");
        });
    } else {
      createNewReservationForNonCustomer(nonCustomerInfo, data)
        .then((res) => {
          setCreateStatus(statusOptions.success);
          toast.success("Create success!");
        })
        .catch((error) => {
          setCreateStatus(statusOptions.error);
          toast.error("Create fail!");
        });
    }
  };

  const totalMenuAmount = useMemo(() => {
    const reservationMenus = stepData[stepOptions.step2];
    if (!Array.isArray(reservationMenus)) return formatPrice(0);
    return formatPrice(
      reservationMenus.reduce((sum, item) => sum + item.priceTaxExc, 0)
    );
  }, [stepData]);

  const totalMenuTime = useMemo(() => {
    const reservationMenus = stepData[stepOptions.step2];
    if (!Array.isArray(reservationMenus)) return formatPrice(0);
    const totalMinute = reservationMenus.reduce(
      (sum, item) => sum + item.durationNumber,
      0
    );
    const numberOfHours = totalMinute / 60;
    return `${Math.floor(numberOfHours)}時間${Math.round(
      (numberOfHours % 1) * 60
    )}分`;
  }, [stepData]);

  return (
    <>
      <div className="w-full h-full pb-8 relative">
        <div className="w-full h-full pb-3">
          <div className="w-full h-full overflow-auto">
            <div className="w-full h-auto">
              <div className="w-full flex justify-between mb-0.5 flex-wrap">
                <div className="bg-[#A5A5A5] text-white h-9 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center ">
                  お客様No.
                </div>
                <div className="bg-[#F0F0F0] text-gray-900 h-9 w-full md:w-[calc(100%-130px)] px-4 py-1.5">
                  {stepData[stepOptions.step1].id}
                </div>
              </div>

              <div className="w-full flex justify-between mb-0.5 flex-wrap">
                <div className="bg-[#A5A5A5] text-white h-9 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
                  <p>お客様名</p>
                </div>
                <div className="bg-[#F0F0F0] text-gray-900 h-9 w-full md:w-[calc(100%-130px)] px-4 py-0.5">
                  {stepData[stepOptions.step1].id ? (
                    <div className="w-full h-full flex items-center">
                      {getNameFromFirstLast(stepData[stepOptions.step1])}
                    </div>
                  ) : (
                    <input
                      type="text"
                      value={nonCustomerInfo.name}
                      onChange={(e) =>
                        setNonCustomerInfo((oldState) => ({
                          ...oldState,
                          name: e.target.value,
                        }))
                      }
                      className="focus:outline-[#1976d2] w-full border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[3px]"
                    />
                  )}
                </div>
              </div>

              <div className="w-full flex justify-between mb-0.5 flex-wrap">
                <div className="bg-[#A5A5A5] text-white h-9 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
                  <p>電話番号</p>
                </div>
                <div className="bg-[#F0F0F0] text-gray-900 h-9 w-full md:w-[calc(100%-130px)] px-4 py-0.5">
                  {stepData[stepOptions.step1].id ? (
                    <div className="w-full h-full flex items-center">
                      {stepData[stepOptions.step1]?.phone1 || ""}
                    </div>
                  ) : (
                    <input
                      type="text"
                      value={nonCustomerInfo.phone}
                      onChange={(e) =>
                        setNonCustomerInfo((oldState) => ({
                          ...oldState,
                          phone: e.target.value,
                        }))
                      }
                      className="focus:outline-[#1976d2] w-full border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[3px]"
                    />
                  )}
                </div>
              </div>

              <div className="w-full flex justify-between mb-0.5 flex-wrap">
                <div className="bg-[#A5A5A5] text-white h-9 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
                  <p>日付</p>
                </div>
                <div className="bg-[#F0F0F0] text-gray-900 h-9 w-full md:w-[calc(100%-130px)] px-4 flex items-center">
                  {stepData[stepOptions.step3].visitDate}
                </div>
              </div>

              <div className="w-full flex justify-between mb-0.5 flex-wrap">
                <div className="bg-[#A5A5A5] text-white h-9 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
                  <p>時間</p>
                </div>
                <div className="bg-[#F0F0F0] text-gray-900 h-9 w-full md:w-[calc(100%-130px)] px-4 flex items-center">
                  {stepData[stepOptions.step3].startTime}
                </div>
              </div>

              <div className="w-full flex justify-between flex-wrap mb-0.5">
                <div className="bg-[#A5A5A5] text-white h-9 md:h-14 w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
                  <p>担当</p>
                </div>
                <div className="bg-[#F0F0F0] text-gray-900 h-14 w-full md:w-[calc(100%-130px)] px-4 py-1.5 flex items-center justify-start">
                  {stepData[stepOptions.step3]?.staff ? (
                    <>
                      <Avatar
                        alt=""
                        src={
                          stepData[stepOptions.step3].staff.imageUrl ||
                          defaultAvatar
                        }
                        sx={{
                          width: 40,
                          height: 40,
                          border: "1px solid black",
                          marginRight: "16px"
                        }}
                      />
                      <p>
                        {getNameFromFirstLast(
                          stepData[stepOptions.step3].staff
                        )}
                      </p>
                    </>
                  ) : null}
                </div>
              </div>

              <div className="w-full h-auto flex justify-between mb-0.5 flex-wrap">
                <div className="bg-[#A5A5A5] text-white h-auto w-full md:w-32 px-4 py-1.5 text-sm font-semibold flex items-center">
                  <p>メニュー</p>
                </div>
                <div className="bg-[#F0F0F0] text-gray-900 h-auto w-full md:w-[calc(100%-130px)] px-4 py-3 flex justify-between">
                  <div className="w-full p-4 pb-12 relative">
                    {stepData[stepOptions.step2].map((menuItem) => (
                      <ReservationMenuItem menuItem={menuItem} />
                    ))}

                    <div className="w-full h-12 rounded-b-lg absolute inset-x-0 bottom-0 px-4 pb-4">
                      <div className="w-full h-full border-t-2 border-gray-400">
                        <div className="w-full h-10 rounded-md px-2 relative font-semibold">
                          <div className="w-full h-full flex">
                            <div className="w-1/2 flex items-center justify-start"></div>
                            <div className="w-1/4 flex items-center justify-end">
                              <p>{totalMenuTime}</p>
                            </div>
                            <div className="w-1/4 flex items-center justify-end">
                              <p>{totalMenuAmount} (税抜)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
          <button
            type="button"
            onClick={handleBackToStep3}
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </button>

          <Tooltip title={isDisableCreateBtn ? "顧客名を入力してください" : ""}>
            <button
              type="button"
              onClick={handleCreate}
              className={`button-size ${
                isDisableCreateBtn
                  ? "bg-gray-btn-secondary"
                  : "bg-blue-btn-primary"
              }`}
            >
              予約
            </button>
          </Tooltip>
        </div>
      </div>

      <LoadingBackdrop isOpen={createStatus === statusOptions.loading} />
      <CreateCompleteDialog
        open={createStatus === statusOptions.success}
        createCompleteTitle="予約を登録しました"
        createCompleteBtnLabel1="予約へ戻る"
        createCompleteAction1={() => {
          navigate(Path.reservationManagement);
        }}
        createCompleteBtnLabel2="終る"
        createCompleteAction2={() => {
          navigate(Path.home);
        }}
      />
    </>
  );
}

export default Step4ConfirmAndCreate;

import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import Path from "../../../route/Path";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CommonDialog from "../../../components/CommonDialog";
import { getInventoryAll, updateInventory } from "../../../services/api/18";
import NoData from "../../../components/commonUI/NoData";
function InventoryManagementCheckStockPage() {
  const navigate = useNavigate();

  const [updateData, setUpdateData] = useState([]);

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [fetchInventory, setFetchInventory] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInventory = () => {
    setFetchInventory({
      isLoading: true,
      data: null,
      error: null,
    });

    getInventoryAll(name, "", "", "", "", "")
      .then((res) => {
        setFetchInventory({
          isLoading: false,
          data: res.data,
          error: null,
        });

        setUpdateData(res.data);
      })
      .catch((error) => {
        setFetchInventory({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadInventory();
  }, [nameSearch]);

  const handleChangeInventory = (index, value) => {
    const newUpdateData = [...updateData];
    newUpdateData[index] = {
      ...newUpdateData[index],
      inventory: Number(value),
    };
    setUpdateData(newUpdateData);
  };

  const handleUpdateInventory = () => {
    setOpen(true);
    updateInventory(updateData[0]?.product._id, updateData[0].inventory);
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>在庫</p>
          </>
        }
      >
        <div className="w-full h-full pt-20 pb-8  lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className=" text-base lg:text-xl font-semibold">
              在庫チェック
            </h2>
          </div>
          <div className="flex items-center gap-2 mt-2">
            <div className=" h-8  border border-[#dddddd] rounded-full shadow-[0_4px_12px_4px_rgba(0,0,0,0.088)] relative py-1 pl-12 pr-3">
              <div className="absolute top-[58%] left-1 -translate-y-1/2 w-10 h-10 p-1 ">
                <SearchIcon classes={{ root: "!text-[24px]" }} />
              </div>
              <input
                value={name}
                placeholder="製品名で探す"
                className="w-full h-full !outline-none text "
                onChange={(e) => setName(e.target.value)}
                onBlur={() => setNameSearch(name)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setNameSearch(name);
                  }
                }}
              />
            </div>
          </div>

          <div className="w-full h-[90%] py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchInventory.isLoading ? <LoadingProgress /> : null}

                {!fetchInventory.isLoading && fetchInventory.error ? (
                  <LoadInitError error={fetchInventory.error} />
                ) : null}
                {!fetchInventory.isLoading && !fetchInventory.error ? (
                  Array.isArray(fetchInventory.data) &&
                  fetchInventory.data.length > 0 ? (
                    <div className="w-full h-full relative overflow-auto">
                      {/* header */}
                      <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b">
                        <div className="w-1/5 flex items-center justify-center shrink-0 truncate">
                          品番
                        </div>
                        <div className="w-1/5 flex items-center justify-center shrink-0 truncate">
                          メーカー
                        </div>
                        <div className="w-1/5 flex items-center justify-center shrink-0 truncate">
                          シリーズ
                        </div>
                        <div className="w-1/5 flex items-center justify-center shrink-0 truncate">
                          品名
                        </div>
                        <div className="w-1/5 flex items-center justify-center shrink-0 truncate">
                          在庫数
                        </div>
                      </div>

                      {/* body */}
                      {fetchInventory.data.map((row, index) => (
                        <div className="w-full h-14 flex bg-[#F0F1EF] bg-opacity-50 border-b">
                          <div className="w-1/5 flex items-center justify-center">
                            {row.product.productNumber}
                          </div>
                          <div className="w-1/5 flex items-center justify-center">
                            {row.product.maker}
                          </div>
                          <div className="w-1/5 flex items-center justify-center">
                            {row.product.series}
                          </div>
                          <div className="w-1/5 flex items-center justify-center">
                            {row.product.name}
                          </div>
                          <div className="w-1/5 flex items-center justify-center">
                            <input
                              key={row.id}
                              placeholder={`${row.inventory}`}
                              value={updateData[index].inventory}
                              onChange={(e) =>
                                handleChangeInventory(
                                  index,
                                  e.currentTarget.value.replace(/[^0-9.]/g, "")
                                )
                              }
                              type="text"
                              className="w-20 p-2 border rounded-md"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <NoData />
                  )
                ) : null}
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() => navigate(Path.inventoryManagement)}
              className="button-size bg-gray-btn-secondary mr-4"
            >
              戻る
            </button>
            <button
              onClick={handleUpdateInventory}
              className="button-size bg-blue-btn-primary"
            >
              登録
            </button>
          </div>
        </div>
        <CommonDialog
          open={open}
          handleClose={() => setOpen(false)}
          actions={
            <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
              <button
                onClick={() => setOpen(false)}
                type="button"
                className="button-size bg-gray-btn-secondary"
              >
                在庫管理へ戻る
              </button>
              <button type="button" className="button-size bg-blue-btn-primary">
                終る
              </button>
            </div>
          }
        >
          <div className="w-full h-full flex justify-center items-center text-xl font-bold">
            在庫数を登録しました
          </div>
        </CommonDialog>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default InventoryManagementCheckStockPage;

import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";

import { staff_member_list } from "app/services/api/fake_services/fake_data";
import MainContentLayout from "../../../layouts/MainContentLayout";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import { getAllSalonStaffs } from "../../../services/api";
import NoData from "../../../components/commonUI/NoData";
import { getNameFromFirstLast } from "../../../utils";

function StaffItem(props) {
  const { staffInfo } = props;

  return (
    <div className="w-[100px] h-[90px]">
      <Link
        to={Path.inputSaleStaff(staffInfo.id)}
        className="w-[100px] h-[90px] flex flex-col items-center py-2 hover:bg-slate-200 hover:shadow-md hover:rounded-md"
      >
        <img
          alt={getNameFromFirstLast(staffInfo)}
          src={staffInfo?.imageUrl || defaultAvatar}
          className="!w-[48px] !h-[48px] mb-1 rounded-full border border-gray-900"
        />
        <div className="w-full truncate text-sm text-center font-semibold">
          {getNameFromFirstLast(staffInfo)}
        </div>
      </Link>
    </div>
  );
}

function AllSalonStaff(props) {
  const { staffList } = props;

  return (
    <div className="w-full h-full border border-gray-400 rounded py-6 pl-6 pr-2 relative">
      <h2 className="absolute -top-3 left-4 text-base lg:text-lg font-semibold bg-[#F0F1EF] bg-opacity-50 px-2">
        売上を入力するスタッフを選択してください
      </h2>
      <div className="w-full h-full pr-4 overflow-auto">
        <div className="w-full h-auto inline-flex flex-wrap justify-center gap-4">
          {staffList.map((staff) => (
            <StaffItem staffInfo={staff} />
          ))}
        </div>
      </div>
    </div>
  );
}

function ListStaffMemberPage() {
  const [loadInitStatus, setLoadInitStatus] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const handleLoadInit = useCallback(() => {
    setLoadInitStatus({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllSalonStaffs()
      .then((res) => {
        setLoadInitStatus({
          isLoading: false,
          data: {
            staffList: res.data,
          },
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitStatus({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    handleLoadInit();
  }, [handleLoadInit]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "",
      }}
    >
      <MainContentLayout pageTitle="売上">
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">売上入力</h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full">
              {loadInitStatus.isLoading ? <LoadingProgress /> : null}

              {!loadInitStatus.isLoading && loadInitStatus.error ? (
                <LoadInitError error={loadInitStatus.error} />
              ) : null}

              {!loadInitStatus.isLoading && !loadInitStatus.error ? (
                Array.isArray(loadInitStatus?.data?.staffList) &&
                loadInitStatus.data.staffList.length > 0 ? (
                  <AllSalonStaff staffList={loadInitStatus.data.staffList} />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <NoData />
                  </div>
                )
              ) : null}
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.saleManagement}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ListStaffMemberPage;

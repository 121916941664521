import React from "react";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";

function Alert(props) {
  const { open, handleOk, message, okLabel } = props;
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={() => {}}
      classes={{ root: "bg-transparent" }}
      TransitionComponent={Fade}
    >
      <div className="w-full flex flex-col p-6">
        <div className="w-full grow min-h-[120px] flex items-center justify-center text-xl font-semibold">{message}</div>

        <div className="w-full flex items-center justify-end text-white text-base font-semibold">
          <button
            onClick={() => {
              handleOk && handleOk();
            }}
            type="button"
            className="button-size rounded bg-blue-btn-primary"
          >
            {okLabel || "進む"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default Alert;

import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import Header from "./Header";
import SideSectionPC from "./SideSectionPC";
import NoticeSectionPC from "./NoticeSectionPC";

import "react-toastify/dist/ReactToastify.css";
import { Drawer } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function DefaultLayout(props) {
  const { headerProps, children } = props;

  const [showMobileNoti, setShowMobileNoti] = useState(false);

  const location = useLocation();
  const modePC = useMediaQuery("(min-width:2048px)");

  useLayoutEffect(() => {
    // eslint-disable-next-line no-undef
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <>
      {
        /* Responsive breakpoint */
        /*
        min-[400px]:
        min-[475px]:
        min-[500px]:
        min-[630px]:
        min-[680px]: 
        */
      }
      <div className="h-screen w-full flex flex-col bg-[url('app/assets/images/back.png')] bg-cover bg-center">
        <Header
          {...headerProps}
          modePC={modePC}
          setShowMobileNoti={setShowMobileNoti}
        />

        <div className="custom-container w-full flex-grow h-[calc(100vh-80px)] flex py-3 mg:py-">
          <div className="main-section-width-full h-full flex flex-col gap-4">
            <div className="w-full h-full flex-grow lg:border border-gray-400 overflow-auto">
              {children}
            </div>
            {modePC ? (
              <div className="hidden lg:block w-full bottom-noti-height shrink-0">
                <div className="w-full h-full border border-gray-400">
                <NoticeSectionPC />
                  </div>
              </div>
            ) : null}
          </div>

          {modePC ? (
            <div className="side-section-width h-full hidden lg:block lg:pt-6">
              <div className="w-full h-full">
                <SideSectionPC />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <Drawer
        anchor="right"
        open={showMobileNoti}
        onClose={() => setShowMobileNoti(false)}
      >
        <div className="w-[390px] h-full pt-16 pb-4 relative">
          <div className="absolute inset-x-0 top-0 w-full h-16 flex items-center justify-end px-6 border-b border-gray-300">
            <button
              type="button"
              onClick={() => setShowMobileNoti(false)}
              className="w-8 h-8 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faXmark} size="xl" />
            </button>
          </div>

          <div className="w-full h-full flex flex-col">
            <div className="w-full h-2/3 pb-6 pt-4 px-4">
              <div className="w-full h-full border border-gray-400 rounded-lg p-4">
                <SideSectionPC />
              </div>
            </div>
            <div className="w-full h-2/3 px-4">
              <div className="w-full h-full border border-gray-400 rounded-lg">
                <NoticeSectionPC />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default DefaultLayout;

import React from "react";
import { Link } from "react-router-dom";
import Path from "app/route/Path";
import InputPaymentViewMenu from "../../components/InputPaymentViewMenu";
import InputPaymentDiscount from "../../components/InputPaymentDiscount";
import InputPaymentProdcuts from "../../components/InputPaymentProdcuts";
import InputPaymentMethod from "../../components/InputPaymentMethod";
import { Tooltip } from "@mui/material";

function PaymentInput(props) {
  const {
    updateVisitData,
    setDiscount,
    setSaleProduct,
    setVisitMenus,
    nextToConfirm,

    selectedPaymentMethod,
    setSelectedPaymentMethod,
  } = props;
  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-full h-auto">
          <InputPaymentMethod
            isPaymentByCash
            paymentMethod={selectedPaymentMethod?.paymentMethod}
            paymentType={selectedPaymentMethod?.paymentType}
            handleSelectPaymentMethod={setSelectedPaymentMethod}
          />
        </div>

        <div className="w-full h-auto flex flex-col">
          <InputPaymentViewMenu
            visitMenus={updateVisitData.visitMenus}
            setVisitMenus={setVisitMenus}
          />
          <InputPaymentProdcuts
            selectedProduct={updateVisitData?.sales}
            setSelectedProduct={setSaleProduct}
          />
          <InputPaymentDiscount
            discount={updateVisitData.discount}
            setDiscount={setDiscount}
          />
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <Link
          to={Path.toDayVisitList}
          className="button-size bg-gray-btn-secondary mr-4"
        >
          戻る
        </Link>

        <Tooltip
          title={
            !selectedPaymentMethod.paymentType ||
            !selectedPaymentMethod.paymentMethod
              ? "お支払い方法を選択してください"
              : ""
          }
        >
          <button
            className={`button-size ${
              !selectedPaymentMethod.paymentType ||
              !selectedPaymentMethod.paymentMethod
                ? "bg-gray-btn-secondary"
                : "bg-blue-btn-primary"
            } `}
            onClick={nextToConfirm}
          >
            金額確認
          </button>
        </Tooltip>
      </div>
    </>
  );
}

export default PaymentInput;

import React from "react";

function FieldInput(props) {
  const { value, setValue, ...otherProps } = props;

  return (
    <input
      value={value}
      onChange={(evt) => setValue(evt.target.value)}
      {...otherProps}
    />
  );
}

export default FieldInput;

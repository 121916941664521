import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Avatar, CircularProgress } from "@mui/material";
import SouthIcon from "@mui/icons-material/South";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import { getDetailReservation, startUseServices } from "app/services/api";
import { formatPrice, getNameFromFirstLast } from "app/utils";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import { jpDateFormat } from "app/constants";
import Path from "app/route/Path";

function ReservationInfo(props) {
  const { nextToSchedule, reservationMenus, visitDate, startTime, staff } =
    props;

  const reservationTime = useMemo(() => {
    const visitDateJpStr = moment(visitDate).format(jpDateFormat);
    return `${visitDateJpStr} ${startTime}`;
  }, [visitDate, startTime]);

  const durationStr = useMemo(() => {
    const durationNumber =
      reservationMenus.reduce((a, b) => a + b.durationNumber, 0) / 60;
    if (durationNumber < 1) return `${Math.round(durationNumber * 60)}分`;

    return `${Math.floor(durationNumber)}時間${Math.round(
      (durationNumber % 1) * 60
    )}分`;
  }, [reservationMenus]);

  const totalMenuList = useMemo(() => {
    return reservationMenus.reduce((a, b) => a + b.priceTaxInc, 0);
  }, [reservationMenus]);

  const totalMenuWithTaxList = useMemo(() => {
    return reservationMenus.reduce((a, b) => a + b.priceTaxInc, 0);
  }, [reservationMenus]);

  const totalMenuWithoutTaxList = useMemo(() => {
    return reservationMenus.reduce((a, b) => a + b.priceTaxExc, 0);
  }, [reservationMenus]);

  return (
    <div className="w-full h-full pb-8 lg:pb-12 relative pt-16 lg:pt-0">
      <div className="w-full h-full py-3">
        <div className="w-full h-full flex items-center justify-center overflow-auto">
          <div className="w-full h-auto rounded-lg border border-gray-400 max-w-[680px] mx-auto">
            <div className="w-ffull h-auto border-b border-gray-400">
              <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200 rounded-t-lg">
                日時
              </div>
              <div className="w-full h-auto px-3 lg:px-6 py-4">
                {reservationTime}
              </div>
            </div>

            <div className="w-ffull h-auto border-b border-gray-400">
              <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                施術時間合計（日時）
              </div>
              <div className="w-full h-auto px-3 lg:px-6 py-4">
                {durationStr}
              </div>
            </div>

            <div className="w-ffull h-auto border-b border-gray-400">
              <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                担当
              </div>

              <div className="w-full h-auto px-3 lg:px-6 py-4">
                {staff ? (
                  <div className="w-full h-auto flex items-center justify-center">
                    <div className="w-12 h-12 rounded-full overflow-hidden">
                      <Avatar
                        alt=""
                        src={staff.imageUrl || defaultAvatar}
                        sx={{
                          width: 48,
                          height: 48,
                          border: "1px solid black",
                        }}
                      />
                    </div>
                    <p className="text-base font-semibold ml-4">
                      {getNameFromFirstLast(staff)}
                    </p>
                  </div>
                ) : (
                  <div className="w-full h-auto min-h-[40px] flex items-center justify-center">
                    指名なし
                  </div>
                )}
              </div>
            </div>

            <div className="w-full h-auto flex border-b border-gray-400 min-h-[180px]">
              <div className="w-1/2 h-auto border-r border-gray-400">
                <div className="w-ffull h-auto">
                  <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                    メニュー
                  </div>
                  <div className="w-full h-auto px-3 lg:px-6 py-4">
                    <div className="w-full h-auto min-h-[80px] flex items-stretch flex-col">
                      <h3 className="font-semibold mb-4">ご利用クーポン</h3>

                      <div className="w-full h-auto flex items-start justify-between gap-3">
                        <span>
                          {reservationMenus
                            .map((menuItem) => menuItem.name)
                            .join(" + ")}
                        </span>
                        <span className="shrink-0 whitespace-nowrap font-semibold">
                          {formatPrice(totalMenuWithoutTaxList)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 h-auto">
                <div className="w-ffull h-auto">
                  <div className="w-full h-8 border-b border-gray-400 px-6 flex items-stretch text-lg font-semibold bg-gray-200">
                    予約時の金額
                  </div>
                  <div className="w-full h-auto px-3 lg:px-6 py-4">
                    <div className="w-full h-auto min-h-[80px] flex items-center flex-col">
                      <div className="w-full h-auto flex items-center justify-between mb-2">
                        <span>合計金額（税抜き）</span>
                        <span>{formatPrice(totalMenuWithoutTaxList)}</span>
                      </div>

                      <div className="w-full h-auto flex items-center justify-between">
                        <span>ご利用ポイント</span>
                        <span>0</span>
                      </div>

                      <div className="w-full h-auto flex items-center justify-between mb-2">
                        <span>10%税額</span>
                        <span>{formatPrice(totalMenuWithTaxList - totalMenuWithoutTaxList)}</span>
                      </div>

                      <div className="w-full h-[1px] bg-slate-500 my-3" />

                      <div className="w-full h-auto flex items-center justify-between">
                        <span>お支払い予約金額</span>
                        <span>{formatPrice(totalMenuWithTaxList)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-ffull h-auto">
              <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                要望・その他
              </div>
              <div className="w-full h-auto px-3 lg:px-6 py-4"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-8 lg:h-12 flex items-end justify-end bg-[#F0F1EF] bg-opacity-50 absolute inset-x-0 bottom-0">
        <button
          onClick={nextToSchedule}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          次へ
        </button>
      </div>
    </div>
  );
}

function ServiceUsageSchedule(props) {
  const { handleBack, handleStartUseService, reservationMenus } = props;

  const durationStr = useMemo(() => {
    const durationNumber =
      reservationMenus.reduce((a, b) => a + b.durationNumber, 0) / 60;
    if (durationNumber < 1) return `${Math.round(durationNumber * 60)}分`;

    return `${Math.floor(durationNumber)}時間${Math.round(
      (durationNumber % 1) * 60
    )}分`;
  }, [reservationMenus]);

  const totalMenuList = useMemo(() => {
    return reservationMenus.reduce((a, b) => a + b.priceTaxInc, 0);
  }, [reservationMenus]);

  const totalMenuWithoutTaxList = useMemo(() => {
    return reservationMenus.reduce((a, b) => a + b.priceTaxExc, 0);
  }, [reservationMenus]);

  const totalMenuWithTaxList = useMemo(() => {
    return reservationMenus.reduce((a, b) => a + b.priceTaxInc, 0);
  }, [reservationMenus]);

  return (
    <div className="w-full h-full pt-16 lg:pt-0">
      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto flex flex-col justify-center">
          <div className="w-full h-auto max-w-[960px] mx-auto relative max-h-full flex flex-wrap lg:flex-nowrap">
            <div className="w-full lg:w-3/5 flex flex-col max-h-full lg:pr-6 relative">
              <div className="w-full h-full lg:min-h-[360px] flex flex-col">
                {reservationMenus.map((menuItem, index) => (
                  <React.Fragment key={index}>
                    <div className="flex items-center gap-4">
                      <div className="w-44 h-12 flex items-center justify-center rounded-full bg-[#736966] hover:bg-[#5e5553] overflow-hidden text-base text-white shrink-0">
                        {menuItem.name}
                      </div>

                      <div className="text-[#736966] font-normal text-sm">
                        <p>時間：{menuItem.durationString}</p>
                        <p>料金：{formatPrice(menuItem.priceTaxExc) + "(税抜)"}</p>
                      </div>
                    </div>

                    {index === reservationMenus.length - 1 ? null : (
                      <div className="w-full h-8 flex">
                        <div className="w-44 h-full flex items-center justify-center">
                          <SouthIcon className="text-lg text-[#736966]" />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="hidden lg:flex  w-1 h-full flex-col items-center justify-center inset-y-0 right-0">
              <div className="w-2 h-2 overflow-hidden rounded-full bg-[#736966] shrink-0" />
              <div className="w-[2px] h-full grow bg-[#736966]" />
              <div className="w-2 h-2 overflow-hidden rounded-full bg-[#736966] shrink-0" />
            </div>

            <div className="flex lg:hidden w-full h-1 items-center justify-center my-8">
              <div className="w-2 h-2 overflow-hidden rounded-full bg-[#736966] shrink-0" />
              <div className="h-[2px] w-full grow bg-[#736966]" />
              <div className="w-2 h-2 overflow-hidden rounded-full bg-[#736966] shrink-0" />
            </div>

            <div className="w-full lg:w-2/5 flex flex-col max-h-full pl-6">
              <div className="w-full max-h-full overflow-auto pb-12 relative h-full">
                <h3 className="text-lg font-semibold text-[#736966] mb-2">
                  ＜本日の施術内容＞
                </h3>

                <ul className="list-disc pl-4 mb-8 text-[#736966] font-normal text-sm">
                  {reservationMenus.map((menuItem, index) => (
                    <li key={index}>{menuItem.name}</li>
                  ))}
                </ul>

                <p className="text-[#736966] font-normal text-sm">
                  想定施術時間：{durationStr}
                </p>
                <p className="text-[#736966] font-normal text-sm">
                  施術費用：{formatPrice(totalMenuWithoutTaxList)}（税抜）
                </p>
                <p className="text-[#736966] font-normal text-sm">
                  施術費用：{formatPrice(totalMenuWithTaxList)}（税込）
                </p>

                <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
                  <button
                    onClick={handleBack}
                    type="button"
                    className="button-size rounded bg-gray-btn-secondary mr-4 lg:mr-6"
                  >
                    内容修正
                  </button>

                  <button
                    onClick={handleStartUseService}
                    type="button"
                    className="button-size bg-blue-btn-primary"
                  >
                    施術開始
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ConfirmReservationPageContent(props) {
  const { reservationInfo, reservationId } = props;
  const [showMainReservationInfo, setShowMainReservationInfo] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleStartUseService = () => {
    setIsLoading(true);
    startUseServices(reservationId)
      .then((res) => {
        setIsLoading(false);
        navigate(Path.duringService(res.data.id));
      })
      .catch((error) => {
        console.log("error : ", error);
        toast.error("error");
        setIsLoading(false);
      });
  };
  return (
    <>
      {showMainReservationInfo ? (
        <ReservationInfo
          reservationMenus={reservationInfo.reservationMenus}
          visitDate={reservationInfo.visitDate}
          startTime={reservationInfo.startTime}
          staff={reservationInfo.staff}
          nextToSchedule={() => setShowMainReservationInfo(false)}
        />
      ) : (
        <ServiceUsageSchedule
          reservationMenus={reservationInfo.reservationMenus}
          handleBack={() => setShowMainReservationInfo(true)}
          handleStartUseService={handleStartUseService}
        />
      )}
      <LoadingBackdrop isOpen={isLoading} />
    </>
  );
}

function ConfirmReservationPage() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const { reservationId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getDetailReservation(reservationId)
      .then((res) => {
        const reservationData = res.data;
        if (reservationData?.visit?.id) {
          toast.success("この予約はチェックインされました");
          navigate(Path.duringService(reservationData?.visit?.id));
        } else {
          setLoadInitState({
            isLoading: false,
            data: res.data,
            error: null,
          });
        }
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [reservationId]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>ご来店</p>
          </>
        }
      >
        {loadInitState.error ? (
          <div className="w-full h-full flex items-center justify-center">
            Error
          </div>
        ) : null}

        {loadInitState.isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <CircularProgress size={40} />
          </div>
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <div className="w-full h-full flex items-center justify-center">
            <ConfirmReservationPageContent
              reservationId={reservationId}
              reservationInfo={loadInitState.data}
            />
          </div>
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ConfirmReservationPage;

import React, { useState } from "react";
import { setAuthUser, setAuthToken } from "app/redux/authReducer";
import { useDispatch } from "react-redux";
import logo from "app/assets/images/logo.png";
import { login } from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { accountTypes } from "../../constants";

function Login() {
  const dispatch = useDispatch();
  const [loginName, setLoginName] = useState("");
  const [password, setPassword] = useState("");

  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  
  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  }

  const handleLogin = () => {
    login(loginName, password)
      .then((res) => {
        const tokenData = jwtDecode(res.data.result.accessToken);

        dispatch(
          setAuthUser({
            id: res.data.result.id,
            email: res.data.result.email,
            permissions: tokenData?.permissions || [],
            accountType: tokenData?.accountType || accountTypes.STAFF,
          })
        );
        dispatch(
          setAuthToken({
            accessToken: res.data.result.accessToken,
          })
        );
      })
      .catch((err) => {
        console.log("login error");
        toast.error("ログイン情報が間違っています");
      });
  };

  return (
    <>
    <div class="bg-[url('app/assets/images/back.png')] bg-cover bg-center">
      {/* <section class="flex items-center justify-center h-screen backdrop-blur-lg"> */}
      {/* Add 'backdrop-blur-lg' for bluring  */}
      <section class="flex items-center justify-center h-screen">
        <div class="w-full p-6 m-4 bg-transparent rounded-lg sm:max-w-md">
          <h1 class="pb-14 text-5xl font-bold text-center text-[#4EE2F6]">Login</h1>
          <form class="flex flex-col justify-center space-y-4 mt-8" action="#">
            <div>
              <label for="loginName" class="block mb-2 text-sm font-medium text-gray-900">MAIL</label>
              <input
                type="text"
                name="loginName"
                id="loginName"
                class="block w-full px-4 py-3 mb-2 text-gray-1000 bg-gray-50 bg-opacity-10 bg-transparent border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500 placeholder-gray-500"
                placeholder="account"
                required=""
                value={loginName}
                onChange={(e) => setLoginName(e.currentTarget.value)}
              />
            </div>
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900">PASSWORD</label>
              <div className="relative">
                <input
                  type={isRevealPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  class="block w-full px-4 py-3 mb-16 text-gray-1000 bg-gray-50 bg-opacity-10 bg-transparent border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500 placeholder-gray-500"
                  placeholder="••••••••"
                  required=""
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
                {/*  spanタグにrole="presentation"をつけないと、onClickつけたらeslintの内容によっては怒られます。 */ }
                <span
                  onClick={togglePassword}
                  role="presentation"
                  className={"absolute inset-y-0 right-0 pr-3 flex items-center text-lg leading-5 cursor-pointer"}
                >
                  {isRevealPassword ? (
                    <i className="fa fa-eye" />
                  ) : (
                    <i className="fa fa-eye-slash" />
                  )}
                </span>
              </div>
            </div>
            <button
              type="button"
              onClick={handleLogin}
              class="block w-3/4 px-4 py-3 text-sm font-medium text-white bg-black rounded-lg self-center"
            >
              Login
            </button>
          </form>
        </div>
      </section>
    </div>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default Login;

import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import { getVisitDetailForPayment } from "app/services/api";
import { toast } from "react-toastify";
import MainContentLayout from "app/layouts/MainContentLayout";
import { ResponsiveMenuItem } from "../../Home";
import { useMediaQuery } from "@mui/material";

function DuringServicePage() {
  const isMobile = useMediaQuery("(max-width:680px)");
  const [fetchVisitInfoState, setFetchVisitInfoState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const { id } = useParams();

  const loadToDayVisit = useCallback(() => {
    if (!id) return;
    setFetchVisitInfoState({
      isLoading: true,
      data: null,
      error: null,
    });

    getVisitDetailForPayment(id)
      .then((res) => {
        setFetchVisitInfoState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchVisitInfoState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [id]);

  useEffect(() => {
    loadToDayVisit();
  }, [loadToDayVisit]);

  const navigate = useNavigate();
  return (
    <DefaultLayout>
      <div className="w-full h-full overflow-auto">
        <div className="w-full h-full relative lg:pt-10 lg:pb-6 lg:pl-4 lg:pr-4 lg:flex flex-col justify-center self-center">
          <div className="absolute z-10 top-2 sm:top-0 sm:left-0 lg:top-6 lg:left-6 w-16 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20">
            <button className="rounded-md sm:rounded-full overflow-hidden relative shrink-0 !border-[#666FC8] w-full h-full">
              <div className="w-full h-full rounded-md sm:rounded-full !border-[#666FC8] cursor-default border-[2px] lg:border-[5px]"></div>
              <div className="absolute inset-0 rounded-md sm:rounded-full overflow-hidden text-white  ">
                <div className="w-full h-[20%] sm:h-[36%] bg-transparent"></div>
                <div className="w-full h-[80%] sm:h-[64%] flex flex-col items-center justify-center !bg-[#666FC8] text-xs lg:text-base font-semibold sm:pb-2">
                  施術中
                </div>
              </div>
            </button>
          </div>

          <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
            <div className="w-full h-full relative  flex flex-col mt-6 justify-start md:justify-center px-4">
              <div className="w-full ">
                <div className="w-full h-full flex items-center sm:border sm:border-gray-400 sm:rounded-2xl ">
                  <div className="w-full grid grid-cols-1 sm:grid-cols-3">
                    <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={
                          <>
                            <p>施術メモを</p>
                            <p>入力する</p>
                          </>
                        }
                        size="100%"
                        onClick={() =>
                          navigate(Path.duringServiceAddCounselingPage(id))
                        }
                      />
                    </div>
                    <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={
                          <>
                            <p>お客様情報を</p>
                            <p>入力する</p>
                          </>
                        }
                        onClick={() => {
                          if (
                            fetchVisitInfoState.data.customer.payment === null
                          ) {
                            navigate(Path.home);
                          } else {
                            navigate(
                              Path.customerManagementDetail(
                                fetchVisitInfoState.data.customer._id
                              )
                            );
                          }
                        }}
                        size="100%"
                      />
                    </div>

                    <div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%]">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={
                          <>
                            <p>頭皮ラボを</p>
                            <p>提案する</p>
                          </>
                        }
                        onClick={() =>
                          navigate(
                            Path.customersUndergoingTreatmentIntroduction(id)
                          )
                        }
                        size="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
              <button
                onClick={() =>
                  navigate(
                    Path.customersUndergoingTreatmentIntroductionScalpLab(id)
                  )
                }
                type="button"
                className="button-size bg-gray-btn-secondary"
              >
                施術終る
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </MainContentLayout> */}
    </DefaultLayout>
  );
}

export default DuringServicePage;

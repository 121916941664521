import React from "react";

function MainContentLayout(props) {
  const { children, pageTitle } = props;
  return (
    <div className="w-full h-full overflow-auto">
      <div className="w-full h-full relative lg:pt-10 lg:pb-6 lg:pl-32 lg:pr-4 lg:flex flex-col justify-center self-center">
        {/* Chen: Comment out unused styling */}
        {/* <div className="absolute z-10 top-2 sm:top-0 sm:left-0 lg:top-6 lg:left-6 w-16 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20">
          <button className="rounded-md sm:rounded-full overflow-hidden relative shrink-0 !border-[#666FC8] w-full h-full">
            <div className="w-full h-full rounded-md sm:rounded-full !border-[#666FC8] cursor-default border-[2px] lg:border-[5px]"></div>
            <div className="absolute inset-0 rounded-md sm:rounded-full overflow-hidden text-white  ">
              <div className="w-full h-[20%] sm:h-[36%] bg-transparent"></div>
              <div className="w-full h-[80%] sm:h-[64%] flex flex-col items-center justify-center !bg-[#666FC8] text-xs lg:text-base font-semibold sm:pb-2">
                {pageTitle}
              </div>
            </div>
          </button>
        </div> */}

        {children}
      </div>
    </div>
  );
}

export default MainContentLayout;

import React, { useState } from "react";
import CommonDialog from "app/components/CommonDialog";
import InputNumberVirtualKeyBoard from "app/components/InputNumberVirtualKeyBoard";
import { formatPrice } from "app/utils";

function EnterCashDialog(props) {
  const { open, handleClose, total, handlePayment } = props;

  const [amountReceivedFromCustomer, setAmountReceivedFromCustomer] =
    useState(0);

  return (
    <CommonDialog
      open={open}
      handleClose={() => {
        handleClose();
      }}
      title=" "
      actions={
        <div className="w-full flex items-center justify-end text-white text-base font-semibold">
          <button
            onClick={() => {
              if (amountReceivedFromCustomer >= total) handlePayment(total);
            }}
            type="button"
            className={`button-size ${
              amountReceivedFromCustomer >= total
                ? "bg-blue-btn-primary"
                : "bg-gray-btn-secondary"
            } `}
          >
            完了
          </button>
        </div>
      }
    >
      <div className="w-full h-auto pt-8 pb-4 flex items-start gap-8">
        <div className="shrink-0 h-full">
          <InputNumberVirtualKeyBoard
            value={amountReceivedFromCustomer}
            handleChange={setAmountReceivedFromCustomer}
          />
        </div>
        <div className="flex-1 h-full text-xl font-semibold">
          <div className="w-full h-auto flex items-center justify-between mb-5">
            <p className="">会計</p>
            <p className="">{formatPrice(total)}</p>
          </div>

          <div className="w-full h-auto flex items-center justify-between mb-5">
            <p className="">お預かり</p>
            <p className="">{formatPrice(amountReceivedFromCustomer)}</p>
          </div>

          <div className="w-full h-auto flex items-center justify-between mb-5">
            <p className="">お残り</p>
            <p
              className={
                amountReceivedFromCustomer - total < 0 ? "text-red-500" : ""
              }
            >
              {formatPrice(amountReceivedFromCustomer - total)}
            </p>
          </div>
        </div>
      </div>
    </CommonDialog>
  );
}

export default EnterCashDialog;

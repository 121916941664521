import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "1",
    amount: 4000,
  },
  {
    name: "2",
    amount: 3000,
  },
  {
    name: "3",
    amount: 2000,
  },
  {
    name: "4",
    amount: 2780,
  },
  {
    name: "5",
    amount: 1890,
  },
  {
    name: "6",
    amount: 2390,
  },
  {
    name: "8",
    amount: 3490,
  },
  {
    name: "9",
    amount: 3000,
  },
  {
    name: "10",
    amount: 2000,
  },
  {
    name: "11",
    amount: 2780,
  },
  {
    name: "12",
    amount: 1890,
  },
  {
    name: "13",
    amount: 2390,
  },
  {
    name: "14",
    amount: 3490,
  },
  {
    name: "15",
    amount: 3490,
  },
  {
    name: "16",
    amount: 3000,
  },
  {
    name: "17",
    amount: 2000,
  },
  {
    name: "18",
    amount: 2780,
  },
  {
    name: "19",
    amount: 1890,
  },
  {
    name: "20",
    amount: 2390,
  },
  {
    name: "21",
    amount: 3490,
  },
  {
    name: "22",
    amount: 3490,
  },
  {
    name: "23",
    amount: 2390,
  },
  {
    name: "24",
    amount: 0,
  },
  {
    name: "25",
    amount: 0,
  },
  {
    name: "26",
    amount: 0,
  },
  {
    name: "27",
    amount: 0,
  },
  {
    name: "28",
    amount: 0,
  },
  {
    name: "29",
    amount: 0,
  },
];

export default class CustomBarChart extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={240}
          height={240}
          data={data}
          margin={{
            top: 2,
            right: 4,
            left: 4,
            bottom: 2,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="amount" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

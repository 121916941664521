import React, { useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainContentLayout from "app/layouts/MainContentLayout";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import { toast } from "react-toastify";
import LoadingBackdrop from "../../../components/commonUI/LoadingBackdrop";
import CreateCompleteDialog from "../../../components/CommonDialog/CreateCompleteDialog";
import { sendConsultation } from "../../../services/api";

function InputConsultationAnalyzePageContent() {
  const [consultationInput, setConsultationInput] = useState({
    title: "",
    content: "",
  });

  const [consultationConfirmed, setConsultationConfirmed] = useState(null);
  const [sendConsultationStatus, setSendConsultationStatus] = useState("idle");

  const navigate = useNavigate();

  const isValidConsultation = useMemo(
    () =>
      consultationInput.title &&
      consultationInput.content &&
      consultationInput.title.trim() &&
      consultationInput.content.trim(),
    [consultationInput]
  );

  const handleSendConsultation = () => {
    if (!consultationConfirmed || sendConsultationStatus === "loading") return;
    setSendConsultationStatus("loading");

    sendConsultation(consultationConfirmed)
      .then((res) => {
        setSendConsultationStatus("success");
      })
      .catch((error) => {
        toast.error("Send consultation error!");
        console.log("error: ", error);
        setSendConsultationStatus("idle");
      });
  };

  return (
    <>
      <div className="w-full h-full border border-gray-400 p-6 rounded-xl">
        <div className="w-full h-auto flex items-center mb-6">
          <label
            className="w-20 h-10 flex items-center font-semibold"
            htmlFor="title"
          >
            タイトル
          </label>
          <div className="w-[calc(100%-80px)] h-10">
            <input
              id="title"
              type="text"
              value={consultationInput.title}
              onChange={(e) =>
                setConsultationInput((oldState) => ({
                  ...oldState,
                  title: e.target.value,
                }))
              }
              readOnly={consultationConfirmed}
              className={`w-full h-full px-3 py-2 text-base border border-gray-400 rounded-md ${
                consultationConfirmed ? "bg-gray-100" : ""
              }`}
            />
          </div>
        </div>

        <div className="w-full h-[calc(100%-64px)] flex items-start">
          <label
            className="w-20 h-10 flex items-center font-semibold"
            htmlFor="content"
          >
            本文
          </label>
          <div className="w-[calc(100%-80px)] h-full">
            <textarea
              id="content"
              type="text"
              value={consultationInput.content}
              onChange={(e) =>
                setConsultationInput((oldState) => ({
                  ...oldState,
                  content: e.target.value,
                }))
              }
              readOnly={consultationConfirmed}
              className={`w-full h-full px-3 py-2 text-base border border-gray-400 rounded-md ${
                consultationConfirmed ? "bg-gray-100" : ""
              }`}
            />
          </div>
        </div>
      </div>

      <div className="w-full h-12 flex items-end justify-end text-base font-semibold absolute inset-x-0 bottom-0 bg-[#F0F1EF] bg-opacity-50">
        {!consultationConfirmed ? (
          <>
            <Link
              to={Path.analyzeManagement}
              className="button-size bg-gray-btn-secondary mr-4"
            >
              戻る
            </Link>

            <button
              onClick={() => {
                if (isValidConsultation)
                  setConsultationConfirmed(consultationInput);
              }}
              type="button"
              className={`button-size ${
                isValidConsultation
                  ? "bg-blue-btn-primary"
                  : "bg-gray-btn-secondary"
              }`}
            >
              内容確認
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => setConsultationConfirmed(null)}
              type="button"
              className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
            >
              戻る
            </button>

            <button
              onClick={handleSendConsultation}
              type="button"
              className="button-size bg-blue-btn-primary"
            >
              相談する
            </button>
          </>
        )}
      </div>

      <LoadingBackdrop isOpen={sendConsultationStatus === "loading"} />

      <CreateCompleteDialog
        open={sendConsultationStatus === "success"}
        createCompleteTitle="経営相談を送信しました"
        createCompleteBtnLabel2="終る"
        createCompleteAction2={() => {
          navigate(Path.analyzeManagement);
        }}
      />
    </>
  );
}

function InputConsultationAnalyzePage() {
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "",
      }}
    >
      <MainContentLayout pageTitle="経営分析">
        <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-12 pb-12">
          <div className="w-full h-8 flex items-center justify-between absolute inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              相談内容を入力
            </h2>
            <div className=""></div>
          </div>

          <InputConsultationAnalyzePageContent />
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default InputConsultationAnalyzePage;

import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import CommonDialog from "app/components/CommonDialog";
import { getAllSaleProducts } from "app/services/api";
import { formatPrice } from "app/utils";
import noImage from "app/assets/images/no-image.png";

function ProductItem(props) {
  const { productInfo, selectedProduct, setSelectedProduct } = props;

  const checked = useMemo(() => {
    const selectedObj = selectedProduct.find(
      (item) => item.id === productInfo.id
    );
    return selectedObj && selectedObj.amount > 0;
  }, [productInfo, selectedProduct]);

  const amount = useMemo(() => {
    const selectedObj = selectedProduct.find(
      (item) => item.id === productInfo.id
    );

    return selectedObj ? selectedObj.amount : 0;
  }, [productInfo, selectedProduct]);

  const handleChangeCheckbox = () => {
    const selectedObj = selectedProduct.find(
      (item) => item.id === productInfo.id
    );

    if (selectedObj && selectedObj.amount > 0)
      setSelectedProduct((oldList) =>
        oldList.filter((item) => item.id !== productInfo.id)
      );
    else {
      setSelectedProduct((oldList) => [
        ...oldList.filter((item) => item.id !== productInfo.id),
        { ...productInfo, amount: 1 },
      ]);
    }
  };

  const decreaseAmount = () => {
    const selectedObj = selectedProduct.find(
      (item) => item.id === productInfo.id
    );
    if (selectedObj && selectedObj.amount > 1) {
      setSelectedProduct((oldList) => [
        ...oldList.filter((item) => item.id !== productInfo.id),
        { ...productInfo, amount: selectedObj.amount - 1 },
      ]);
    } else if (selectedObj && selectedObj.amount === 1) {
      setSelectedProduct((oldList) =>
        oldList.filter((item) => item.id !== productInfo.id)
      );
    }
  };

  const increaseAmount = () => {
    const selectedObj = selectedProduct.find(
      (item) => item.id === productInfo.id
    );

    if (selectedObj) {
      setSelectedProduct((oldList) => [
        ...oldList.filter((item) => item.id !== productInfo.id),
        { ...productInfo, amount: selectedObj.amount + 1 },
      ]);
    } else {
      setSelectedProduct((oldList) => [
        ...oldList.filter((item) => item.id !== productInfo.id),
        { ...productInfo, amount: 1 },
      ]);
    }
  };

  console.log("Ricky --productInfo-- : ", productInfo)

  return (
    <div
      className={`w-full h-auto py-3 flex flex-wrap sm:fexn items-center mb-0.5 bg-slate-100 rounded-md pr-2 ${
        checked ? "" : "opacity-70"
      }`}
    >
      <div className="w-full sm:w-[calc(100%-112px)] h-full flex items-center">
        <Checkbox
          key={checked}
          checked={checked}
          onChange={(e) => handleChangeCheckbox(e, productInfo)}
        />
        <div className="w-[calc(100%-44px)] h-full flex items-center">
          {/* Product image and name */}
          <div className="w-2/3 h-full flex items-center">
            <img
              className="w-10 h-10 rounded mr-3 border border-gr"
              src={productInfo.imageUrl || noImage}
              alt={productInfo.name}
            />
            {productInfo.name}
          </div>

          {/* Product price */}
          <div className="w-1/3 h-full flex items-center justify-end sm:justify-start">
            {formatPrice(productInfo.priceTaxExc)}
          </div>
        </div>
      </div>
      <div className="w-full sm:w-28 flex justify-end">
        <div className="w-28 h-6 border border-gray-300 rounded flex">
          <button
            type="button"
            onClick={decreaseAmount}
            className="h-full w-8 flex items-center justify-center rounded-r"
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <div className="h-full w-12 border-l border-r border-gray-300 flex items-center justify-center">
            {amount}
          </div>
          <button
            type="button"
            onClick={increaseAmount}
            className="h-full w-8 flex items-center justify-center rounded-l"
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    </div>
  );
}

function PaymentProductDialogContent(props) {
  const { allProducts, selectedProduct, setSelectedProduct } = props;

  return (
    <div className="w-full h-auto flex flex-col max-h-[362px] overflow-auto py-4">
      {allProducts.map((product) => (
        <ProductItem
          key={product.id}
          productInfo={product}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      ))}
    </div>
  );
}

function PaymentProductDialog(props) {
  const { open, handleClose, selectedProductProps, setSelectedProductProps } =
    props;
  const [loadAllProductState, setLoadAllProductState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const [selectedProduct, setSelectedProduct] = useState([]);

  const loadInit = () => {
    setLoadAllProductState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllSaleProducts()
      .then((res) => {
        setLoadAllProductState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadAllProductState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    if (open) {
      loadInit();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setSelectedProduct(selectedProductProps || []);
    }
  }, [open, selectedProductProps]);

  const handleConfirmProduct = () => {
    setSelectedProductProps(selectedProduct);
    handleClose();
  };

  return (
    <CommonDialog
      open={open}
      handleClose={handleClose}
      title="専売品を選択してください。（税抜）"
      actions={
        <div className="w-full flex items-center justify-end text-white text-base font-semibold">
          <button
            onClick={handleConfirmProduct}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            確定
          </button>
        </div>
      }
    >
      {loadAllProductState.isLoading ? (
        <div className="w-full h-40">
          <LoadingProgress />
        </div>
      ) : null}

      {!loadAllProductState.isLoading &&
      !loadAllProductState.error &&
      loadAllProductState.data ? (
        <PaymentProductDialogContent
          allProducts={loadAllProductState.data}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      ) : null}
    </CommonDialog>
  );
}

export default PaymentProductDialog;

import React from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import AnalyzeBox from "./AnalyzeBox";
import CustomPieChart from "../../components/CustomPieChart";

function OperatingProfitRatioAnalyze() {
  const navigate = useNavigate();
  return (
    <AnalyzeBox
      title="営業利益率"
      onClickTitle={() => navigate(Path.operatingProfitRatioAnalyzeManagement)}
    >
      <CustomPieChart rate={0.15} />
    </AnalyzeBox>
  );
}

export default OperatingProfitRatioAnalyze;

import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { v4 } from "uuid";
import { faFilePen, faTrash } from "@fortawesome/free-solid-svg-icons";
import NoData from "app/components/commonUI/NoData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultDateFormat, jpDateFormat } from "app/constants";

function CounselingSheetMemo(props) {
  const { handleCancel, handleAddNewCounseling } = props;

  const [counselingInfo, setCounselingInfo] = useState("");

  const handleSaveCounselingSheet = () => {
    if (!counselingInfo || !counselingInfo.trim()) return;
    handleAddNewCounseling(counselingInfo);
  };

  return (
    <div className="w-full h-full pt-16 lg:pt-12 pb-8 relative">
      <div className="absolute inset-x-0 top-0 w-full h-16 lg:h-12 pl-20 lg:pt-0 flex items-center bg-[#F0F1EF] bg-opacity-50">
        <div className="w-full h-auto flex items-center justify-start">
          <h2 className="text-base lg:text-xl font-semibold">カウンセリングメモ入力</h2>
        </div>
      </div>

      <div className="w-full h-full max-w-[960px] mx-auto relative max-h-full py-3">
        <div className="w-full h-full border border-gray-600 min-h-[360px] rounded-2xl">
          <textarea
            value={counselingInfo}
            onChange={(e) => setCounselingInfo(e.target.value)}
            className="w-full h-full rounded-2xl px-4 py-3"
          />
        </div>
      </div>

      <div className="absolute inset-x-0 bottom-0 w-full h-8 flex items-end justify-end bg-[#F0F1EF] bg-opacity-50">
        <button
          onClick={handleCancel}
          type="button"
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          戻る
        </button>
        <button
          onClick={handleSaveCounselingSheet}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          完了
        </button>
      </div>
    </div>
  );
}

function CounselingSheetHistoryItem(props) {
  const { counselingInfo, handleClick, handleClickDelete } = props;
  return (
    <button
      type="button"
      onClick={() => handleClick(counselingInfo)}
      className="w-full h-20 border mb-4 border-gray-600 rounded-2xl px-4 py-1.5 pt-6 relative flex flex-col justify-end hover:shadow-md transition-all"
    >
      <div className="absolute inset-x-0 top-0 h-7 flex items-end pt-1 px-4 font-semibold">
        {moment(counselingInfo.counsellingDate, defaultDateFormat).format(
          jpDateFormat
        )}
      </div>

      {counselingInfo?.tempId ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleClickDelete(counselingInfo?.tempId);
          }}
          className="w-6 h-6 absolute top-1 right-2 flex items-center justify-center rounded-full opacity-70 hover:opacity-100 hover:bg-gray-200 transition-all"
        >
          <FontAwesomeIcon icon={faTrash} color="red" />
        </button>
      ) : null}

      <p className="w-full h-auto overflow-hidden text-sm font line-clamp-2 text-start">
        {counselingInfo.memo}
      </p>
    </button>
  );
}

function CounselingSheetHistory(props) {
  const {
    handleClickAdd,
    handleClickCounselingItem,
    detailCounselingSheet,
    handleNextStep,
    handleClickDelete,
    counselingHistory,
    newCounselings,
  } = props;

  const listCounselingToShow = useMemo(
    () => [...newCounselings, ...counselingHistory],
    [newCounselings, counselingHistory]
  );

  return (
    <div className="w-full h-full pt-16 lg:pt-12 pb-8 relative">
      <div className="absolute inset-x-0 top-0 w-full h-16 pl-20 lg:pl-0 lg:h-12 flex items-center bg-[#F0F1EF] bg-opacity-50">
        <div className="w-full h-auto flex items-center justify-between">
          <h2 className="text-base lg:text-xl font-semibold">カウンセリングシート</h2>

          <button
            type="button"
            className="button-size flex items-center justify-center gap-2 bg-[#6670c8]"
            onClick={handleClickAdd}
          >
            <FontAwesomeIcon icon={faFilePen} color="white" />
            <span className="text-white">新規メモ入力</span>
          </button>
        </div>
      </div>
      <div className="w-full h-full py-3">
        {listCounselingToShow.length === 0 ? (
          <NoData message="まだ相談シートはありません！" />
        ) : (
          <div className="w-full h-full max-w-[960px] mx-auto relative flex">
            <div className="w-1/2 py-6 pr-1 md:pr-4 pl-0 max-h-full">
              <div className="w-full h-full border border-gray-600 min-h-[360px] rounded-2xl py-3 pt-12 relative">
                {detailCounselingSheet ? (
                  <>
                    <div className="absolute inset-x-0 top-0 h-7 flex items-end pt-1 px-4 font-semibold">
                      {moment(
                        detailCounselingSheet.counsellingDate,
                        defaultDateFormat
                      ).format(jpDateFormat)}
                    </div>

                    <div className="w-full h-full px-6 overflow-auto">
                      {detailCounselingSheet.memo}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="absolute inset-x-0 top-0 h-7 flex items-end pt-1 px-4 font-semibold">
                      {moment(
                        listCounselingToShow[0].counsellingDate,
                        defaultDateFormat
                      ).format(jpDateFormat)}
                    </div>

                    <div className="w-full h-full px-6 overflow-auto">
                      {listCounselingToShow[0].memo}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="w-1/2 p-6 pl-1 lg:pl-4 pr-0 flex flex-col max-h-full overflow-auto">
              <div className="w-full max-h-full overflow-auto">
                <div className="flex flex-col">
                  {listCounselingToShow.map((counseling) => (
                    <CounselingSheetHistoryItem
                      key={counseling?.id || counseling?.tempId}
                      counselingInfo={counseling}
                      handleClick={handleClickCounselingItem}
                      handleClickDelete={handleClickDelete}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>


      <div className="absolute inset-x-0 bottom-0 w-full h-8 flex items-end justify-end bg-[#F0F1EF] bg-opacity-50">
        {/* <button
          onClick={() => {}}
          type="button"
          className="button-size bg-gray-btn-secondary mr-6 text-white"
        >
          戻る
        </button> */}
        <button
          onClick={handleNextStep}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          施術内容入力へ
        </button>
      </div>
    </div>
  );
}

function Step1CounselingSheet(props) {
  const { handleNextToStep2, counselingHistory, step1Data } = props;

  const [showInputMode, setShowInputMode] = useState(false);
  const [newCounselings, setNewCounselings] = useState(
    Array.isArray(step1Data) ? step1Data : []
  );
  const [detailCounselingSheet, setDetailCounselingSheet] = useState(null);

  useEffect(() => {
    setNewCounselings(Array.isArray(step1Data) ? step1Data : []);
    if (Array.isArray(step1Data)) {
      setDetailCounselingSheet([...step1Data, ...counselingHistory][0]);
    } else {
      setDetailCounselingSheet(counselingHistory[0]);
    }
  }, [step1Data, counselingHistory]);

  const handleAddNewCounseling = (memo) => {
    setNewCounselings((oldList) => [
      {
        tempId: v4(),
        counsellingDate: moment().format(defaultDateFormat),
        memo,
      },

      ...oldList,
    ]);
    setShowInputMode(false);
  };

  const handleClickCounselingItem = (counseling) => {
    setDetailCounselingSheet(counseling);
  };

  const handleNextStep = () => {
    handleNextToStep2(newCounselings || []);
  };

  const handleClickDelete = (tempId) => {
    const newCounselingsList = newCounselings.filter(
      (item) => item.tempId !== tempId
    );
    setNewCounselings(newCounselingsList);
    if (detailCounselingSheet.tempId === tempId) {
      setDetailCounselingSheet(
        [...newCounselingsList, ...counselingHistory][0]
      );
    }
  };

  if (!showInputMode)
    return (
      <CounselingSheetHistory
        handleClickAdd={() => setShowInputMode(true)}
        handleClickCounselingItem={handleClickCounselingItem}
        detailCounselingSheet={detailCounselingSheet}
        handleClickDelete={handleClickDelete}
        handleNextStep={handleNextStep}
        counselingHistory={counselingHistory}
        newCounselings={newCounselings}
      />
    );

  return (
    <CounselingSheetMemo
      handleCancel={() => setShowInputMode(false)}
      handleAddNewCounseling={handleAddNewCounseling}
    />
  );
}

export default Step1CounselingSheet;

import React, { useState } from "react";
import { Popover } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment";

function MonthPickerPopover(props) {
  const { anchorEl, handleClose, month, setMonth } = props;

  const [value, setvalue] = useState(month);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateCalendar
          value={value}
          onChange={(newDate) => {
            setvalue(newDate);
          }}
          referenceDate={moment("2022-04-01")}
          views={["year", "month"]}
        />
      </LocalizationProvider>
      <div className="py-4 px-6 flex items-center justify-end">
        <button
          type="button"
          onClick={() => {
            setMonth(value);
            handleClose();
          }}
          className="button-size bg-blue-btn-primary"
        >
          OK
        </button>
      </div>
    </Popover>
  );
}

export default MonthPickerPopover;

import React from "react";
import MainContentLayout from "../../../layouts/MainContentLayout";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle } from "@fortawesome/free-solid-svg-icons";
function ConnectSuccess() {
  const navigate = useNavigate();
  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>システム</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">レジ認証</h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 ">
              <div className="w-full h-full flex flex-col justify-center items-center gap-6 overflow-auto border border-gray-400 rounded-2xl">
                <div className="text-3xl font-bold">
                  ペアリングが完了しました
                </div>
                <div className="flex items-center gap-6">
                  <div className="border border-black border-2 rounded-lg px-4 py-2">
                    iPad
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCircle} color="#3498db" />
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCircle} color="#3498db" />
                  </div>
                  <div className="border border-[#3498db] border-2 !w-[60px] !h-[60px] flex justify-center items-center rounded-full">
                    <FontAwesomeIcon icon={faCheck} size="3x" color="#3498db" />
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCircle} color="#3498db" />
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCircle} color="#3498db" />
                  </div>
                  <div className="border border-black border-2 rounded-lg px-4 py-2">
                    device1
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => navigate(Path.systemManagement)}
                    className="px-8 text-white text-base font-semibold py-1 rounded bg-blue-btn-primary mt-4"
                  >
                    連続完了
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0"></div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ConnectSuccess;

import React, { useState } from "react";
import LoadingBackdrop from "../commonUI/LoadingBackdrop";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const updateSteps = {
  CONFIRM: "CONFIRM",
  UPDATING: "UPDATING",
  UPDATE_COMPLETE: "UPDATE_COMPLETE",
};

function ConfirmUpdateDialog(props) {
  const {
    open,
    handleClose,
    confirmTitle,
    confirmMessage,
    updateCompleteTitle,
    updateCompleteBtnLabel,
    updateCompleteAction,

    updateAction,
    handleDeleteSuccess,
    handleDeleteError,
  } = props;

  const [showStep, setShowStep] = useState(updateSteps.CONFIRM);

  const executeAction = async () => {
    setShowStep(updateSteps.UPDATING);

    try {
      const res = await updateAction();
      if (handleDeleteSuccess) handleDeleteSuccess(res);

      setShowStep(updateSteps.UPDATE_COMPLETE);
    } catch (error) {
      if (handleDeleteError) handleDeleteError(error);

      setShowStep(updateSteps.CONFIRM);
    }
  };

  const handleConfirm = () => {
    executeAction();
  };

  const handleBack = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={() => {
          if (!updateSteps.UPDATE_COMPLETE) {
            handleClose();
          }
        }}
        aria-labelledby="responsive-dialog-title"
        classes={{ root: "bg-transparent" }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            paddingTop: "40px",
          },
        }}
        TransitionComponent={Fade}
      >
        <div className="w-full flex flex-col items-center pt-16 pb-8 px-8 bg-white rounded-3xl relative min-w-[360px]">
          {showStep === updateSteps.UPDATE_COMPLETE ? (
            <div className="absolute w-20 h-20 -top-10 left-1/2 -translate-x-1/2 rounded-full overflow-hidden bg-green-500 flex items-center justify-center">
              <FontAwesomeIcon icon={faCheck} size="3x" color="white" />
            </div>
          ) : null}

          {showStep === updateSteps.UPDATE_COMPLETE ? (
            <>
              <h3 className="text-xl font-semibold mb-14">
                {updateCompleteTitle}
              </h3>

              <div className="w-full flex items-center justify-end text-white text-base font-semibold gap-6">
                {props.updateCompleteBtnLabel2 ? (
                  <button
                    onClick={props.updateCompleteAction2}
                    type="button"
                    className="button-size bg-blue-btn-primary"
                  >
                    {props.updateCompleteBtnLabel2}
                  </button>
                ) : null}

                <button
                  onClick={updateCompleteAction}
                  type="button"
                  className="button-size bg-gray-btn-secondary"
                >
                  {updateCompleteBtnLabel}
                </button>
              </div>
            </>
          ) : (
            <>
              <h3 className="text-xl font-semibold mb-3">{confirmTitle}</h3>

              <p className="text-lg font-base mb-8">{confirmMessage}</p>

              <div className="w-full flex items-center justify-center text-white text-base font-semibold gap-8">
                <button
                  onClick={handleBack}
                  type="button"
                  className="button-size bg-gray-btn-secondary"
                >
                  {props.confirmCancleLabel || "キャンセル"}
                </button>
                <button
                  onClick={handleConfirm}
                  type="button"
                  className="button-size bg-blue-btn-primary"
                >
                  {props.confirmLabel || "変化"}
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>

      <LoadingBackdrop isOpen={showStep === updateSteps.UPDATING} />
    </>
  );
}

export default ConfirmUpdateDialog;

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function BackBtn({ url }) {
  const navigate = useNavigate();

  return (
    <div>
      <Button
        className="w-full"
        onClick={() => {
          navigate(url || -1);
        }}>
        戻る
      </Button>
    </div>
  );
}

import React from "react";

function FieldInput(props) {
  const { value, setValue, ...otherProps } = props;

  return (
    <textarea
      value={value}
      onChange={(evt) => setValue(evt.target.value)}
      cols="40"
      rows="5"
      {...otherProps}
    />
  );
}

export default FieldInput;

import React, { useMemo } from "react";
import dayjs from "dayjs";
import { jpDateFormat, jpWeekDayLabel } from "app/constants";
import { Avatar } from "@mui/material"; 
import {
  convertTimeToNumber,
  formatPrice,
  getNameFromFirstLast,
} from "app/utils";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import { Link } from "react-router-dom";
import Path from "app/route/Path";

function ReservationDetail(props) {
  const { reservationInfo } = props;

  const visitDateObj = useMemo(
    () => dayjs(reservationInfo.visitDate),
    [reservationInfo]
  );

  const durationStr = useMemo(() => {
    const startTimeNumber = convertTimeToNumber(reservationInfo.startTime);
    const finishTimeNumber = convertTimeToNumber(reservationInfo.finishTime);

    const durationNumber = finishTimeNumber - startTimeNumber;
    if (durationNumber < 1) return `${Math.round(durationNumber * 60)}分`;

    return `${Math.floor(durationNumber)}時間${Math.round(
      (durationNumber % 1) * 60
    )}分`;
  }, [reservationInfo]);

  const reservationMenuStr = useMemo(() => {
    if (!Array.isArray(reservationInfo.reservationMenus)) return "";

    return reservationInfo.reservationMenus
      .map((item) => item.name)
      .join(" + ");
  }, [reservationInfo]);

  const reservationMenuTotal = useMemo(() => {
    if (!Array.isArray(reservationInfo.reservationMenus)) return 0;

    return reservationInfo.reservationMenus.reduce(
      (a, b) => a + b.priceTaxInc,
      0
    );
  }, [reservationInfo]);

  const isNonCustomer = useMemo(() => {
    const customer = reservationInfo.customer;
    return (
      !customer.lastNameKata ||
      !customer.firstNameKata ||
      !customer.lastNameKanji ||
      !customer.firstNameKanji
    );
  }, [reservationInfo]);

  return (
    <form className="w-full h-auto max-w-[880px] mx-auto relative max-h-full flex flex-wrap">
      <div className="w-full h-full overflow-auto">
        <div className="w-full h-auto rounded-lg border border-gray-400 max-w-[680px] mx-auto">
          <>
            <div className="w-ffull h-auto border-b border-gray-400">
              <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200 rounded-t-lg">
                お客様名
              </div>
              <div className="w-full h-auto px-3 lg:px-6 py-4">
                {!isNonCustomer ? (
                  <Link
                    to={Path.customerManagementDetail(
                      reservationInfo.customer.id
                    )}
                  >
                    {getNameFromFirstLast(reservationInfo.customer)}
                  </Link>
                ) : (
                  <div>{getNameFromFirstLast(reservationInfo.customer)}</div>
                )}
              </div>
            </div>
            <div className="w-ffull h-auto border-b border-gray-400">
              <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                電話番号
              </div>
              <div className="w-full h-auto px-3 lg:px-6 py-4">
                {reservationInfo.customer.phone1 || ""}
              </div>
            </div>
          </>

          <div className="w-ffull h-auto border-b border-gray-400">
            <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
              日時
            </div>
            <div className="w-full h-auto px-3 lg:px-6 py-4">
              {`${visitDateObj.format(jpDateFormat)} (${
                jpWeekDayLabel[visitDateObj.day()]
              }) ${reservationInfo.startTime}`}
            </div>
          </div>

          <div className="w-ffull h-auto border-b border-gray-400">
            <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
              施術時間合計（日時）
            </div>
            <div className="w-full h-auto px-3 lg:px-6 py-4">{durationStr}</div>
          </div>

          <div className="w-ffull h-auto border-b border-gray-400">
            <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
              担当
            </div>
            <div className="w-full h-auto px-3 lg:px-6 py-4">
              {reservationInfo.staff ? (
                <div className="w-full h-auto flex items-center justify-center">
                  <div className="w-12 h-12 rounded-full overflow-hidden">
                    <Avatar
                      alt=""
                      src={reservationInfo.staff.imageUrl || defaultAvatar}
                      sx={{
                        width: 48,
                        height: 48,
                        border: "1px solid black",
                      }}
                    />
                  </div>
                  <p className="text-base font-semibold ml-4">
                    {getNameFromFirstLast(reservationInfo.staff)}
                  </p>
                </div>
              ) : (
                <div className="w-full h-auto min-h-[40px] flex items-center justify-center">
                  指名なし
                </div>
              )}
            </div>
          </div>

          <div className="w-full h-auto flex border-b border-gray-400 min-h-[180px]">
            <div className="w-1/2 h-auto border-r border-gray-400">
              <div className="w-ffull h-auto">
                <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
                  メニュー
                </div>
                <div className="w-full h-auto px-3 lg:px-6 py-4">
                  <div className="w-full h-auto min-h-[80px] flex items-stretch flex-col">
                    <h3 className="font-semibold mb-4">ご利用クーポン</h3>

                    <div className="w-full h-auto flex items-center justify-between">
                      <span>{reservationMenuStr}</span>
                      <span>{formatPrice(reservationMenuTotal)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 h-auto">
              <div className="w-ffull h-auto">
                <div className="w-full h-8 border-b border-gray-400 px-6 flex items-stretch text-lg font-semibold bg-gray-200">
                  予約時の金額
                </div>
                <div className="w-full h-auto px-3 lg:px-6 py-4">
                  <div className="w-full h-auto min-h-[80px] flex items-center flex-col">
                    <div className="w-full h-auto flex items-center justify-between mb-2">
                      <span>合計金額</span>
                      <span>{formatPrice(reservationMenuTotal)}</span>
                    </div>

                    <div className="w-full h-auto flex items-center justify-between">
                      <span>ご利用ポイント</span>
                      <span>{formatPrice(0)}</span>
                    </div>

                    <div className="w-full h-[1px] bg-slate-500 my-3" />

                    <div className="w-full h-auto flex items-center justify-between">
                      <span>お支払い予約金額</span>
                      <span>{formatPrice(reservationMenuTotal)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-ffull h-auto">
            <div className="w-full h-8 border-b border-gray-400 px-6 flex items-center text-lg font-semibold bg-gray-200">
              要望・その他
            </div>
            <div className="w-full h-auto px-3 lg:px-6 py-4"></div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ReservationDetail;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import InputDateField from "app/components/commonUI/InputDateField";

import { getAllStaffAttendances, getDetailSalonStaff } from "app/services/api";
import { defaultDateFormat, jpDateFormat } from "app/constants";
import Path from "app/route/Path";
import NoData from "../../../components/commonUI/NoData";
import { getNameFromFirstLast } from "../../../utils";
import { CSVLink } from "react-csv";

function StaffManagementIndividualAttendancesPageContent(props) {
  const { listAttendances, staffInfo } = props;

  return (
    <>
      <div className="w-full h-full relative overflow-auto">
        <div className="w-full h-auto">
          <div className="w-full h-12 sticky top-0 left-0 grid grid-cols-4 bg-[#4472C4] text-lg font-semibold text-white border-b border-white">
            <div className="px-2 border-r-[1px] border-white flex items-center">
              申請者
            </div>
            <div className="px-2 border-r-[1px] border-white flex items-center">
              日付
            </div>
            <div className="px-2 border-r-[1px] border-white flex items-center">
              出勤時刻
            </div>
            <div className="px-2 border-r-[1px] border-white flex items-center">
              退勤時刻
            </div>
          </div>
          {listAttendances.map((item) => (
            <div
              key={item.id}
              className="group w-full h-10 grid grid-cols-4 border-b border-white text-base"
            >
              <div className="px-2 border-r-[1px] border-white flex items-center bg-gray-200 group-hover:bg-slate-300">
                {staffInfo ? getNameFromFirstLast(staffInfo) : ""}
              </div>
              <div className="px-2 border-r-[1px] border-white flex items-center bg-gray-200 group-hover:bg-slate-300">
                {moment(item.workDate, defaultDateFormat).format(jpDateFormat)}
              </div>
              <div className="px-2 border-r-[1px] border-white flex items-center bg-gray-200 group-hover:bg-slate-300">
                {item.startTime}
              </div>
              <div className="px-2 border-r-[1px] border-white flex items-center bg-gray-200 group-hover:bg-slate-300">
                {item.finishTime}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function StaffManagementIndividualAttendancesPage() {
  const [fetchInitState, setFetchInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const [startTime, setStartTime] = useState(
    () => moment().format(defaultDateFormat.slice(0, 7)) + "-01"
  );
  const [endTime, setEndTime] = useState(
    () => moment().add(1, "M").format(defaultDateFormat.slice(0, 7)) + "-01"
  );

  const [staffInfo, setStaffInfo] = useState(null);

  const { staffId } = useParams();

  const loadAllShiftPlan = useCallback(() => {
    setFetchInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllStaffAttendances(staffId, startTime, endTime)
      .then((res) => {
        setFetchInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [staffId, startTime, endTime]);

  useEffect(() => {
    getDetailSalonStaff(staffId)
      .then((res) => {
        setStaffInfo(res.data);
      })
      .catch((error) => {
        console.log("getDetailSalonStaff Fail!");
      });
  }, [staffId]);

  useEffect(() => {
    loadAllShiftPlan();
  }, [loadAllShiftPlan]);

  const startTimeObj = useMemo(
    () => moment(startTime, defaultDateFormat),
    [startTime]
  );
  const endTimeObj = useMemo(
    () => moment(endTime, defaultDateFormat),
    [endTime]
  );

  const handleChangeStart = (newStart) => {
    setStartTime(newStart.format(defaultDateFormat));

    if (newStart.diff(moment(endTime, defaultDateFormat), "d") >= 0) {
      setEndTime(newStart.clone().add(1, "M").format(defaultDateFormat));
    }
  };

  const handleChangeEnd = (newEnd) => {
    setEndTime(newEnd.format(defaultDateFormat));

    if (newEnd.diff(moment(startTime, defaultDateFormat), "d") <= 0) {
      setStartTime(newEnd.clone().add(-1, "M").format(defaultDateFormat));
    }
  };

  const csvData = useMemo(() => {
    let data = [["申請者", "日付", "出勤時刻", "退勤時刻"]];
    if (Array.isArray(fetchInitState.data) && staffInfo) {
      fetchInitState.data.forEach((attendance) => {
        const newRow = [
          getNameFromFirstLast(staffInfo),
          moment(attendance.workDate, defaultDateFormat).format(jpDateFormat),
          attendance.startTime,
          attendance.finishTime,
        ];
        data.push(newRow);
      });
    }
    return data;
  }, [fetchInitState.data, staffInfo]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>予約を</p>
            <p>確認する</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">個人勤念表</h2>

            <CSVLink
              filename={`staff-sale-${startTimeObj.format(
                jpDateFormat
              )}-${endTimeObj.format(jpDateFormat)}.csv`}
              data={csvData}
              className="button-size bg-blue-btn-primary"
            >
              <FontAwesomeIcon icon={faDownload} />
              <span className="ml-2">エクスポート</span>
            </CSVLink>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full flex flex-col overflow-hidden">
              <div className="w-full h-14">
                <div className="w-full h-auto flex items-center mb-4">
                  <div className="text-lg font-semibold shrink-0 mr-4">
                    期間
                  </div>

                  <div className="flex-grow flex items-center">
                    <InputDateField
                      value={startTimeObj}
                      setValue={handleChangeStart}
                    />

                    <span className="text-xl font-bold mx-3">~</span>

                    <InputDateField
                      value={endTimeObj}
                      setValue={handleChangeEnd}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full h-[calc(100%-56px)]">
                {fetchInitState.isLoading ? <LoadingProgress /> : null}

                {!fetchInitState.isLoading && fetchInitState.error ? (
                  <LoadInitError error={fetchInitState.error} />
                ) : null}
                {!fetchInitState.isLoading &&
                !fetchInitState.error &&
                fetchInitState.data ? (
                  <StaffManagementIndividualAttendancesPageContent
                    listAttendances={fetchInitState.data}
                    staffId={staffId}
                    staffInfo={staffInfo}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.staffManagementDetail(staffId)}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementIndividualAttendancesPage;

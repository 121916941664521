import DatePickerInput from "./DatePickerInput";
import TimePickerInput from "./TimePickerInput";
import FieldInput from "./FieldInput";
import MultilineFieldInput from "./MultilineFieldInput";

const exportObjs = {
  Field: FieldInput,
  DatePicker: DatePickerInput,
  TimePicker: TimePickerInput,
  MultilineField: MultilineFieldInput,
};

export default exportObjs;

import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import Path from "app/route/Path";
import { Link, useNavigate } from "react-router-dom";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import TableAllMenu from "./TableAllMenu";
import { getSalonDetail } from "../../../services/api/18";
import NoData from "../../../components/commonUI/NoData";

function SystemManagementAllMenuPage() {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  console.log("load", load);
  const [fetchAllMenu, setFetchAllMenu] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadAllMenu = () => {
    setFetchAllMenu({
      isLoading: true,
      data: null,
      error: null,
    });

    getSalonDetail()
      .then((res) => {
        setFetchAllMenu({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchAllMenu({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadAllMenu();
  }, [load]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>システム</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full flex justify-between h-16 lg:h-12 pl-20 lg:pl-0  items-center  absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">メニュー</h2>
            <button
              onClick={() => navigate(Path.systemManagementRegisterMenu)}
              type="button"
              className="button-size gap-1 bg-blue-btn-primary"
            >
              <div className="font-bold text-xl">+</div>
              <div>追加</div>
            </button>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchAllMenu.isLoading ? <LoadingProgress /> : null}

                {!fetchAllMenu.isLoading && fetchAllMenu.error ? (
                  <LoadInitError error={fetchAllMenu.error} />
                ) : null}
                {!fetchAllMenu.isLoading && !fetchAllMenu.error ? (
                  Array.isArray(fetchAllMenu.data?.salonMenus) &&
                  fetchAllMenu.data?.salonMenus.length > 0 ? (
                    <TableAllMenu
                      allMenu={fetchAllMenu.data.salonMenus}
                      load={load}
                      setLoad={setLoad}
                    />
                  ) : (
                    <NoData />
                  )
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.systemManagement}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SystemManagementAllMenuPage;

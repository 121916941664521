import React, { useState } from "react";
import ConfirmCustomerInfoDialog from "./ConfirmCustomerInfoDialog";
import { ResponsiveMenuItem } from "../index";

function CustomerVisitMenuItem(props) {
  const [showConfirmCustomerInfo, setShowConfirmCustomerInfo] = useState(false);

  return (
    <>
      <ResponsiveMenuItem
        isMobile={props.isMobile}
        itemTitle={
          <>
            <p>お客様の</p>
            <p>ご来店</p>
          </>
        }
        onClick={() => {
          setShowConfirmCustomerInfo(true);
        }}
        size="100%"
      />

      <ConfirmCustomerInfoDialog
        openDialog={showConfirmCustomerInfo}
        handleCloseDialog={() => setShowConfirmCustomerInfo(false)}
      />
    </>
  );
}

export default CustomerVisitMenuItem;

import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import InputField from "../../../components/commonUI/InputField";
import { getHistoryOfOrderingDetail } from "../../../services/api/1C";
import moment from "moment";
import NoData from "../../../components/commonUI/NoData";
import {
  getAllFirstImpression,
  createNewFirstImpression,
} from "app/services/api";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function SurveyButton(props) {
  const { staffInfo, handleClick, active, extendClass, text, disabled } = props;

  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={disabled}
      className={`button-size text-black border-4 border-stone-300 font-semibold rounded-full bg-[#F0F1EF] bg-opacity-50-btn-primary h-10 text-sm sm:text-base${extendClass} ${
        active ? "bg-stone-300 shadow-md " : ""
      }`}
    >
      {text}
    </button>
  );
}

function Customer1StImpressionAndFactCheckPage() {
  const { customerId, stepId } = useParams();

  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState("1"); // 默认选中的值为 "

  const [inputData, setInputData] = useState({});

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    const question = findQuestionById(fieldName);
    setInputData((prevInputData) => ({
      ...prevInputData,
      [fieldName]: { question: question?.text, answer: value },
    }));
  };

  const [selectedButtons, setSelectedButtons] = useState({});

  const questions = [
    // Section 1.
    [
      {
        id: "Q_Occupation",
        text: "Q1. お客様の職業は何ですか",
        type: "options",
        options: [
          "会社員",
          "パート",
          "アルバイト",
          "学生",
          "主夫・主婦",
          "自営業",
          "その他",
        ],
      },
      {
        id: "Q_JobType",
        text: "Q2. お客様の職種は何ですか",
        type: "input",
        options: "",
      },
      {
        id: "Q_CommuteMethod",
        text: "Q3. お客様はどんな方法で通勤していますか",
        type: "options",
        options: ["徒歩", "自転車", "電車", "車", "バイク", "その他"],
      },
      {
        id: "Q_CommuteTime",
        text: "Q4. 通勤時間はどの程度ですか",
        type: "options",
        options: ["10分以内", "10-30分", "30-60分", "60分以上"],
      },
      {
        id: "Q_WorkSchedule",
        text: "Q5. 仕事体系はどうですか",
        type: "options",
        options: ["固定", "シフト", "その他"],
      },
      {
        id: "Q_OffDays",
        text: "Q6. お仕事がお休みの曜日は確認しましたか",
        type: "options",
        options: ["平日", "土日", "シフト"],
      },
      {
        id: "Q_WorkplaceLocation",
        text: "Q7. 職場のロケーションはどうですか",
        type: "options",
        options: ["オフィス街", "商業地域", "工業地域", "住宅街", "その他"],
      },
      {
        id: "Q_DressCode",
        text: "Q8. 職場のドレスコードに制約はありますか",
        type: "options",
        options: ["制服", "作業着", "オフィス\nカジュアル", "自由", "その他"],
      },
      {
        id: "Q_HairLengthRestriction",
        text: "Q9. 職場にヘアスタイル制約（長さ）はありますか",
        type: "options",
        options: ["自由", "ショート", "ひとまとめ", "その他"],
      },
      {
        id: "Q_HairColorRestriction",
        text: "Q10. 職場にヘアスタイル制約（色）はありますか",
        type: "options",
        options: ["自由", "黒のみ", "ある程度OK", "その他"],
      },
      {
        id: "Q_HairstyleRestriction",
        text: "Q11. 職場にヘアスタイル制約（髪型）はありますか",
        type: "options",
        options: ["自由", "指定あり"],
      },
      {
        id: "Q_ForeheadExposureRestriction",
        text: "Q12. 職場にヘアスタイル制約（額の露出）はありますか",
        type: "options",
        options: ["自由", "指定あり"],
      },
      {
        id: "Q_BeardRestriction",
        text: "Q13. 職場にヘアスタイル制約（ひげ）はありますか",
        type: "options",
        options: ["自由", "指定あり"],
      },
      {
        id: "Q_HeadgearRestriction",
        text: "Q14. 職場にヘアスタイル制約（作業帽の有無）はありますか",
        type: "options",
        options: ["なし", "あり"],
      },
      {
        id: "Q_MakeupNorms",
        text: "Q15. 職場での化粧慣習はありますか",
        type: "options",
        options: ["なし", "あり"],
      },
    ],
    // Section 2.
    [
      {
        id: "Q_Hobbies",
        text: "Q1. どんな趣味をもっていますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_HobbyBudget",
        text: "Q2. その趣味にかける予算はどの程度ですか",
        type: "options",
        options: [
          "5000円/月\n以下",
          "5000円～\n1万円/月",
          "1万円～\n10万円/月",
          "10万円/月\n以上",
        ],
      },
      {
        id: "Q_HobbyFrequency",
        text: "Q3. 趣味の頻度はどの程度ですか",
        type: "options",
        options: ["毎日", "週2，3回", "週1回", "月2，3回", "月1回", "年に数回"],
      },
      {
        id: "Q_FavoriteMedia",
        text: "Q4. 好きな映画やドラマ、Youtubeチャンネルはありますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_FavoriteMusic",
        text: "Q5. 好きな音楽ジャンルやアーティストはありますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_WeekendActivities",
        text: "Q6. 休日はなにをして過ごしていますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_WeekdayEveningActivities",
        text: "Q7. 平日の夜は何をして過ごしていますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_OutdoorIndoorPreference",
        text: "Q8. アウトドア派ですか？インドア派ですか？",
        type: "options",
        options: ["アウトドア派", "インドア派"],
      },
    ],
    // Section 3.
    [
      {
        id: "Q_MorningSkincareMakeup",
        text: "Q1. 朝のスキンケア・メイクの手順",
        type: "input",
        options: "",
      },
      {
        id: "Q_EveningSkincareMakeup",
        text: "Q2. 夜のスキンケア・メイクの手順",
        type: "input",
        options: "",
      },
      {
        id: "Q_WeekdayDiet",
        text: "Q3. 平日はどんな食生活をしていますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_WeekendDiet",
        text: "Q4. 休日はどんな食生活をしていますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_CosmeticBrand",
        text: "Q5. 使用している化粧品ブランドは何ですか",
        type: "input",
        options: "",
      },
      {
        id: "Q_AverageEatingOut",
        text: "Q6. 1週間の平均外食回数はどの程度ですか",
        type: "options",
        options: [
          "毎日",
          "週2，3回",
          "週1回",
          "月2，3回",
          "月１回",
          "いかない",
        ],
      },
      {
        id: "Q_FrequentPlaces",
        text: "Q7. よく訪れる店や場所はありますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_HavePets",
        text: "Q8. ペットは飼っていますか",
        type: "options",
        options: ["飼っている", "飼っていない"],
      },
      {
        id: "Q_PetType",
        text: "Q9. 飼っているペットは何ですか",
        type: "input",
        options: "",
      },
      // Add more if needed
    ],
    // Section 4.
    [
      {
        id: "Q_PreferredSNS",
        text: "Q1. よく利用するSNSはなんですか（複数選択可能）",
        type: "options",
        isMultiSelect: true,
        options: [
          "Instagram",
          "TikTok",
          "X(Twitter)",
          "facebook",
          "Treads",
          "LinkedIn",
          "その他",
        ],
      },
      {
        id: "Q_WebsitesVisited",
        text: "Q2. 普段どんなサイトをみますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_CommunicationMethods",
        text: "Q3. よく使うコミュニケーション手段は何ですか",
        type: "options",
        options: ["LINE", "Messenger", "E-mail", "電話", "その他"],
      },
      // Add more if needed
    ],
    // Section 5.
    [
      {
        id: "Q_FrequentShoppingAreas",
        text: "Q1. よく訪れるショッピングモールやエリアはどこですか",
        type: "input",
        options: "",
      },
      {
        id: "Q_MainShoppingPlaces",
        text: "Q2. 買い物の際の主な購入先はどこですか",
        type: "options",
        options: ["オンライン", "オフライン", "その他"],
      },
      {
        id: "Q_FavoriteBrands",
        text: "Q3. お気に入りのブランドや店舗はありますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_ClothingShoppingFrequency",
        text: "Q4. 服のショッピング頻度はどれくらいですか",
        type: "options",
        options: ["週2，3回", "週1回", "月2，3回", "月1回"],
      },
      {
        id: "Q_ClothingBudget",
        text: "Q5. 服を買うときの予算はありますか",
        type: "options",
        options: [
          "5000円/月\n以下",
          "5000円～\n1万円/月",
          "1万円～\n10万円/月",
          "10万円/月\n以上",
        ],
      },
      {
        id: "Q_ShoppingCriteria",
        text: "Q6. 買物の際の判断基準は何ですか",
        type: "options",
        options: ["価格", "ブランド", "品質", "使用感", "その他"],
      },
      {
        id: "Q_ShoppingInformationSource",
        text: "Q7. 買物の際の情報収集はどこからですか",
        type: "options",
        options: ["SNS", "ネット広告", "外出先", "特になし", "その他"],
      },
      {
        id: "Q_SaleDiscountUse",
        text: "Q8. セールや割引を利用しますか",
        type: "options",
        options: ["よくする", "たまにする", "しない"],
      },
      {
        id: "Q_PaymentMethodPreference",
        text: "Q9. 買い物の際の支払い方法の好みはなんですか",
        type: "options",
        options: ["現金", "クレジット\nカード", "デジタル支払", "その他"],
      },
      {
        id: "Q_GiftPurchaseFrequency",
        text: "Q10. 贈り物やプレゼントの購入頻度はどの程度ですか",
        type: "options",
        options: ["月に2，3回", "月に１回", "年に数回", "しない", "その他"],
      },
      {
        id: "Q_RewardsForYourself",
        text: "Q11. 自分へのご褒美はしますか",
        type: "options",
        options: ["はい", "いいえ"],
      },
      {
        id: "Q_RewardsFrequency",
        text: "Q12. 自分へのご褒美の頻度はどの程度ですか",
        type: "options",
        options: [
          "週に2，3回",
          "週に１回",
          "月に2，3回",
          "月に1回",
          "年に数回",
          "しない",
          "その他",
        ],
      },
      {
        id: "Q_TechInterest",
        text: "Q13. 家電やテクノロジー製品に興味はありますか",
        type: "options",
        options: ["ある", "ない", "その他"],
      },
      {
        id: "Q_TechPurchaseFrequency",
        text: "Q14. 家電やテクノロジー製品の購入頻度はどの程度ですか",
        type: "options",
        options: ["月に2，3回", "月に1回", "年に数回", "しない", "その他"],
      },
      {
        id: "Q_LifestyleInterest",
        text: "Q15. 生活雑貨やインテリアに興味がありますか",
        type: "options",
        options: ["ある", "ない", "その他"],
      },
      {
        id: "Q_LifestylePurchaseFrequency",
        text: "Q16. 生活雑貨やインテリアの購入頻度はどの程度ですか",
        type: "options",
        options: ["月に2，3回", "月に1回", "年に数回", "しない", "その他"],
      },
    ],
    // Section 6.
    [
      {
        id: "Q_TravelPreference",
        text: "Q1. 旅行は好きですか",
        type: "options",
        options: ["はい", "いいえ"],
      },
      {
        id: "Q_TravelFrequency",
        text: "Q2. 旅行の頻度はどの程度ですか",
        type: "input",
        options: "",
      },
      {
        id: "Q_DomesticInternationalRatio",
        text: "Q3. 国内と海外の比率はどの程度ですか",
        type: "options",
        options: ["国内優勢", "海外優勢", "その他"],
      },
      {
        id: "Q_LastTravelLocation",
        text: "Q4. 最後に訪れた場所はどこですか",
        type: "input",
        options: "",
      },
      {
        id: "Q_NextTravelDestination",
        text: "Q5. 次に行ってみたい場所はどこですか",
        type: "input",
        options: "",
      },
      {
        id: "Q_TravelStyle",
        text: "Q6. 旅行時のスタイルはありますか",
        type: "options",
        options: ["ツアー", "個人", "その他"],
      },
      {
        id: "Q_TravelEssentials",
        text: "Q7. 旅行時の必須アイテムはありますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_OutdoorActivitiesPreference",
        text: "Q8. アウトドア活動は好きですか",
        type: "options",
        options: ["はい", "いいえ"],
      },
      {
        id: "Q_CampingExperience",
        text: "Q9. キャンプ経験はありますか",
        type: "options",
        options: ["はい", "いいえ"],
      },
      {
        id: "Q_HaircareDuringTravelOutdoor",
        text: "Q10. 旅行やアウトドアでヘアケアはどうしていますか",
        type: "input",
        options: "",
      },
    ],
    // Section 7.
    [
      {
        id: "Q_DietConcern",
        text: "Q1. ダイエットは気になったりしますか",
        type: "options",
        options: ["はい", "いいえ"],
      },
      {
        id: "Q_ExerciseHabits",
        text: "Q2. 運動はしてますか",
        type: "options",
        options: ["はい", "いいえ"],
      },
      {
        id: "Q_ExerciseFrequency",
        text: "Q3. 運動の頻度はどの程度ですか",
        type: "options",
        options: ["毎日", "週2，3回", "週1回", "月2，3回", "月１回", "その他"],
      },
      {
        id: "Q_TypeOfExercise",
        text: "Q4. どんな運動をしていますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_ReasonForStartingExercise",
        text: "Q5. その運動をはじめたきっかけは何ですか",
        type: "input",
        options: "",
      },
      {
        id: "Q_HealthCheckConcern",
        text: "Q6. 健康診断の結果で気になったりしますか",
        type: "options",
        options: ["はい", "いいえ"],
      },
      {
        id: "Q_HealthCheckConcernAreas",
        text: "Q7. 健康診断の結果で気になった項目は何ですか",
        type: "input",
        options: "",
      },
      {
        id: "Q_StressReliefMethods",
        text: "Q8. ストレスの解消法はなんですか",
        type: "input",
        options: "",
      },
      {
        id: "Q_RelaxationFacilities",
        text: "Q9. どんなリラクゼーション施設を活用しますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_SupplementUse",
        text: "Q10. サプリメントは接種していますか",
        type: "options",
        options: ["はい", "いいえ"],
      },
      {
        id: "Q_SupplementTypes",
        text: "Q11. どんなサプリメントを接種していますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_SleepDuration",
        text: "Q12. 睡眠時間はどの程度ですか",
        type: "options",
        options: ["3時間以下", "3-5時間程度", "5-8時間程度", "8時間以上"],
      },
      {
        id: "Q_SleepQuality",
        text: "Q13. 睡眠の質はどうですか",
        type: "options",
        options: ["良い", "悪い"],
      },
      {
        id: "Q_ImprovingSleepQuality",
        text: "Q14. 睡眠の質を改善するために何かしていますか",
        type: "input",
        options: "",
      },
      {
        id: "Q_AllergiesExist",
        text: "Q15. アレルギーはありますか",
        type: "options",
        options: ["はい", "いいえ"],
      },
      {
        id: "Q_AllergyPreventionMethods",
        text: "Q16. アレルギー対策はなにかしていますか",
        type: "input",
        options: "",
      },
    ],
  ];

  const sections = [
    { id: 1, title: "１．職業・勤務環境" },
    { id: 2, title: "２．趣味・レジャー" },
    { id: 3, title: "３．日常生活の習慣" },
    { id: 4, title: "４．ソーシャルライフ" },
    { id: 5, title: "５．買い物＆消費行動" },
    { id: 6, title: "６．旅行・アウトドア" },
    { id: 7, title: "７．健康＆ウェルネス" },
  ];

  const handleCreateNewFirstImpression = (combinedData) => {
    // setCreatingCustomerState((oldState) => ({
    //   ...oldState,
    //   isCreating: true,
    //   error: null,
    //   res: null,
    // }));

    createNewFirstImpression({
      id: customerId,
      survey: combinedData,
      // customerId: customerId,
    })
      .then((res) => {
        console.log(res);
        // setCreatingCustomerState((oldState) => ({
        //   ...oldState,
        //   isCreating: false,
        //   error: null,
        //   res: res,
        // }));
      })
      .catch((error) => {
        console.log("error: ", error);

        // setCreatingCustomerState((oldState) => ({
        //   ...oldState,
        //   isCreating: false,
        //   error: null,
        //   res: error,
        // }));
      });
  };

  const [loadFirstImpressionState, setLoadFirstImpressionState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadAllFirstImpression = () => {
    setLoadFirstImpressionState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllFirstImpression(customerId)
      .then((res) => {
        console.log(res);
        setSelectedButtons(
          res.data[0].survey.selectedButtons
            ? res.data[0].survey.selectedButtons
            : {}
        );
        setInputData(
          res.data[0].survey.inputData ? res.data[0].survey.inputData : {}
        );
        setLoadFirstImpressionState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadFirstImpressionState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadAllFirstImpression();
  }, []);

  /*
  const handleButtonClick = (buttonName, buttonIndex) => {
    const question = findQuestionById(buttonName);

    console.log(question.isMultiSelect);

    const currentAnswer = selectedButtons[buttonName]?.answer;

    // 如果当前按钮已经是活动状态（已被选择）
    if (currentAnswer === buttonIndex) {
      // 取消选择（重置为null或空字符串，取决于你的数据结构）
      setSelectedButtons((prevSelectedButtons) => ({
        ...prevSelectedButtons,
        [buttonName]: { question: question?.text, answer: "" },
      }));
    } else {
      setSelectedButtons((prevSelectedButtons) => ({
        ...prevSelectedButtons,
        [buttonName]: { question: question?.text, answer: buttonIndex },
      }));
    }
  };
  */

  const handleButtonClick = (buttonName, buttonIndex) => {
    const question = findQuestionById(buttonName);

    const currentAnswers = selectedButtons[buttonName]?.answers || [];
    const isMultiSelect = question?.isMultiSelect;

    if (isMultiSelect) {
      // 多选逻辑
      const indexInCurrentAnswers = currentAnswers.indexOf(buttonIndex);

      if (indexInCurrentAnswers !== -1) {
        const updatedAnswers = currentAnswers.filter(
          (answer) => answer !== buttonIndex
        );

        setSelectedButtons((prevSelectedButtons) => ({
          ...prevSelectedButtons,
          [buttonName]: { question: question?.text, answers: updatedAnswers },
        }));
      } else {
        setSelectedButtons((prevSelectedButtons) => ({
          ...prevSelectedButtons,
          [buttonName]: {
            question: question?.text,
            answers: [...currentAnswers, buttonIndex],
          },
        }));
      }
    } else {
      // 单选逻辑
      const currentAnswer = selectedButtons[buttonName]?.answer;

      // 如果当前按钮已经是活动状态（已被选择）
      if (currentAnswer === buttonIndex) {
        // 取消选择（重置为null或空字符串，取决于你的数据结构）
        setSelectedButtons((prevSelectedButtons) => ({
          ...prevSelectedButtons,
          [buttonName]: { question: question?.text, answer: "" },
        }));
      } else {
        setSelectedButtons((prevSelectedButtons) => ({
          ...prevSelectedButtons,
          [buttonName]: { question: question?.text, answer: buttonIndex },
        }));
      }
    }
  };

  const findQuestionById = (id) => {
    return questions[stepId - 1].find((question) => question.id === id);
  };
  const handleNextSection = () => {
    const combinedData = {
      selectedButtons,
      inputData,
    };
    //console.log(combinedData); // 将两者的数据合并成一个对象

    setSelectedValue(parseInt(stepId) + 1);

    navigate(
      Path.customerManagementFactCheck(customerId, parseInt(stepId) + 1)
    );
  };

  const handleSubmit = () => {
    const combinedData = {
      selectedButtons,
      inputData,
    };
    //console.log(combinedData); // 将两者的数据合并成一个对象
    const isConfirmed = window.confirm("このアンケートを提出しますか？");

    if (isConfirmed) {
      handleCreateNewFirstImpression(combinedData);
      // 用户点击了确认按钮，执行相应的操作
      console.log("提出しました");
      navigate(Path.customerManagementDetail(customerId));
      // 这里可以添加执行的其他操作
    } else {
      // 用户点击了取消按钮，可以在这里处理取消的逻辑
      console.log("提出を中止しました");
    }
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    navigate(Path.customerManagementFactCheck(customerId, e.target.value));
  };

  var isDisableFlag = false;
  console.log("test ", selectedButtons);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>1stインプレッション</p>
          </>
        }
        key={stepId}
      >
        {stepId == 1 ? (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
            <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
              <FormControl sx={{ minWidth: 150, maxWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label"></InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  //value={member}
                  label="メンバー"
                  onChange={handleChange}
                  //disabled={isChangeButton}
                  style={{ borderRadius: "3rem", backgroundColor: "#868686" }}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  className="!w-72"
                  value={selectedValue}
                >
                  {sections.map((section) => (
                    <MenuItem value={section.id}>{section.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full h-full py-3">
              {questions[stepId - 1].map((question) => (
                <div className="w-full py-5">
                  <div key={question.id} className="w-full">
                    <div className="w-full h-10">{question.text}</div>
                    {question.type === "options" ? (
                      <div className="w-full pl-16 pr-16 py-3 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-2">
                        {question.options.map((option) => (
                          <SurveyButton
                            key={option}
                            text={option}
                            name={question.id}
                            active={
                              question.isMultiSelect
                                ? selectedButtons[
                                    question.id
                                  ]?.answers.includes(option) // 多选情况
                                : selectedButtons[question.id]?.answer ===
                                  option // 单选情况
                            }
                            extendClass=""
                            disabled={isDisableFlag}
                            handleClick={() =>
                              handleButtonClick(question.id, option)
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="w-full py-8 flex justify-center">
                        <input
                          type="text"
                          id=""
                          name={question.id}
                          class="focus:outline-[#1976d2] w-1/2 border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[5px] rounded-2xl w-1/2   "
                          placeholder="入力してください"
                          value={inputData[question.id]?.answer}
                          disabled={isDisableFlag}
                          onChange={(e) => handleInputChange(e, question.id)}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold">
                <button
                  onClick={handleNextSection}
                  className="button-size text-white text-base font-semibold bg-[#F0F1EF] bg-opacity-50-btn-primary w-full h-full bg-[#666FC8] shadow-md  "
                >
                  次へ
                </button>
              </div>
              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold"></div>
            </div>
          </div>
        ) : stepId == 2 ? (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
            <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
              <FormControl sx={{ minWidth: 150, maxWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label"></InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  //value={member}
                  label="メンバー"
                  onChange={handleChange}
                  //disabled={isChangeButton}
                  style={{ borderRadius: "3rem", backgroundColor: "#868686" }}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  className="!w-72"
                  value={selectedValue}
                >
                  {/* {listCustomer?.map((customer) => (
                <MenuItem value={customer.code}>{customer.describe}</MenuItem>
              ))} */}
                  {sections.map((section) => (
                    <MenuItem value={section.id}>{section.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full h-full py-3">
              {questions[stepId - 1].map((question) => (
                <div className="w-full py-5">
                  <div key={question.id} className="w-full">
                    <div className="w-full h-10">{question.text}</div>
                    {question.type === "options" ? (
                      <div className="w-full pl-16 pr-16 py-3 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-2">
                        {question.options.map((option) => (
                          <SurveyButton
                            key={option}
                            text={option}
                            name={question.id}
                            active={
                              selectedButtons[question.id]?.answer === option
                            }
                            extendClass=""
                            disabled={isDisableFlag}
                            handleClick={() =>
                              handleButtonClick(question.id, option)
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="w-full py-8 flex justify-center">
                        <input
                          type="text"
                          id=""
                          name={question.id}
                          class="focus:outline-[#1976d2] w-1/2 border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[5px] rounded-2xl w-1/2   "
                          placeholder="入力してください"
                          value={inputData[question.id]?.answer}
                          disabled={isDisableFlag}
                          onChange={(e) => handleInputChange(e, question.id)}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold">
                <button
                  onClick={handleNextSection}
                  className="button-size text-white text-base font-semibold bg-[#F0F1EF] bg-opacity-50-btn-primary w-full h-full bg-[#666FC8] shadow-md  "
                >
                  次へ
                </button>
              </div>
              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold"></div>
            </div>
          </div>
        ) : stepId == 3 ? (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
            <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
              <FormControl sx={{ minWidth: 150, maxWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label"></InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  //value={member}
                  label="メンバー"
                  onChange={handleChange}
                  //disabled={isChangeButton}
                  style={{ borderRadius: "3rem", backgroundColor: "#868686" }}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  className="!w-72"
                  value={selectedValue}
                >
                  {/* {listCustomer?.map((customer) => (
                <MenuItem value={customer.code}>{customer.describe}</MenuItem>
              ))} */}
                  {sections.map((section) => (
                    <MenuItem value={section.id}>{section.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full h-full py-3">
              {questions[stepId - 1].map((question) => (
                <div className="w-full py-5">
                  <div key={question.id} className="w-full">
                    <div className="w-full h-10">{question.text}</div>
                    {question.type === "options" ? (
                      <div className="w-full pl-16 pr-16 py-3 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-2">
                        {question.options.map((option) => (
                          <SurveyButton
                            key={option}
                            text={option}
                            name={question.id}
                            active={
                              selectedButtons[question.id]?.answer === option
                            }
                            extendClass=""
                            handleClick={() =>
                              handleButtonClick(question.id, option)
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="w-full py-8 flex justify-center">
                        <input
                          type="text"
                          id=""
                          name={question.id}
                          class="focus:outline-[#1976d2] w-1/2 border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[5px] rounded-2xl w-1/2   "
                          placeholder="入力してください"
                          value={inputData[question.id]?.answer}
                          onChange={(e) => handleInputChange(e, question.id)}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold">
                <button
                  onClick={handleNextSection}
                  className="button-size text-white text-base font-semibold bg-[#F0F1EF] bg-opacity-50-btn-primary w-full h-full bg-[#666FC8] shadow-md  "
                >
                  次へ
                </button>
              </div>
              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold"></div>
            </div>
          </div>
        ) : stepId == 4 ? (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
            <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
              <FormControl sx={{ minWidth: 150, maxWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label"></InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  //value={member}
                  label="メンバー"
                  onChange={handleChange}
                  //disabled={isChangeButton}
                  style={{ borderRadius: "3rem", backgroundColor: "#868686" }}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  className="!w-72"
                  value={selectedValue}
                >
                  {/* {listCustomer?.map((customer) => (
                <MenuItem value={customer.code}>{customer.describe}</MenuItem>
              ))} */}
                  {sections.map((section) => (
                    <MenuItem value={section.id}>{section.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full h-full py-3">
              {questions[stepId - 1].map((question) => (
                <div className="w-full py-5">
                  <div key={question.id} className="w-full">
                    <div className="w-full h-10">{question.text}</div>
                    {question.type === "options" ? (
                      <div className="w-full pl-16 pr-16 py-3 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-2">
                        {question.options.map((option) => (
                          <SurveyButton
                            key={option}
                            text={option}
                            name={question.id}
                            active={
                              question.isMultiSelect
                                ? selectedButtons[
                                    question.id
                                  ]?.answers.includes(option) // 多选情况
                                : selectedButtons[question.id]?.answer ===
                                  option // 单选情况
                            }
                            extendClass=""
                            handleClick={() =>
                              handleButtonClick(question.id, option)
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="w-full py-8 flex justify-center">
                        <input
                          type="text"
                          id=""
                          name={question.id}
                          class="focus:outline-[#1976d2] w-1/2 border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[5px] rounded-2xl w-1/2   "
                          placeholder="入力してください"
                          value={inputData[question.id]?.answer}
                          onChange={(e) => handleInputChange(e, question.id)}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold">
                <button
                  onClick={handleNextSection}
                  className="button-size text-white text-base font-semibold bg-[#F0F1EF] bg-opacity-50-btn-primary w-full h-full bg-[#666FC8] shadow-md  "
                >
                  次へ
                </button>
              </div>
              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold"></div>
            </div>
          </div>
        ) : stepId == 5 ? (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
            <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
              <FormControl sx={{ minWidth: 150, maxWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label"></InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  //value={member}
                  label="メンバー"
                  onChange={handleChange}
                  //disabled={isChangeButton}
                  style={{ borderRadius: "3rem", backgroundColor: "#868686" }}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  className="!w-72"
                  value={selectedValue}
                >
                  {/* {listCustomer?.map((customer) => (
                <MenuItem value={customer.code}>{customer.describe}</MenuItem>
              ))} */}
                  {sections.map((section) => (
                    <MenuItem value={section.id}>{section.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full h-full py-3">
              {questions[stepId - 1].map((question) => (
                <div className="w-full py-5">
                  <div key={question.id} className="w-full">
                    <div className="w-full h-10">{question.text}</div>
                    {question.type === "options" ? (
                      <div className="w-full pl-16 pr-16 py-3 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-2">
                        {question.options.map((option) => (
                          <SurveyButton
                            key={option}
                            text={option}
                            name={question.id}
                            active={
                              selectedButtons[question.id]?.answer === option
                            }
                            extendClass=""
                            handleClick={() =>
                              handleButtonClick(question.id, option)
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="w-full py-8 flex justify-center">
                        <input
                          type="text"
                          id=""
                          name={question.id}
                          class="focus:outline-[#1976d2] w-1/2 border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[5px] rounded-2xl w-1/2   "
                          placeholder="入力してください"
                          value={inputData[question.id]?.answer}
                          onChange={(e) => handleInputChange(e, question.id)}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold">
                <button
                  onClick={handleNextSection}
                  className="button-size text-white text-base font-semibold bg-[#F0F1EF] bg-opacity-50-btn-primary w-full h-full bg-[#666FC8] shadow-md  "
                >
                  次へ
                </button>
              </div>
              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold"></div>
            </div>
          </div>
        ) : stepId == 6 ? (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
            <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
              <FormControl sx={{ minWidth: 150, maxWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label"></InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  //value={member}
                  label="メンバー"
                  onChange={handleChange}
                  //disabled={isChangeButton}
                  style={{ borderRadius: "3rem", backgroundColor: "#868686" }}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  className="!w-72"
                  value={selectedValue}
                >
                  {/* {listCustomer?.map((customer) => (
                <MenuItem value={customer.code}>{customer.describe}</MenuItem>
              ))} */}
                  {sections.map((section) => (
                    <MenuItem value={section.id}>{section.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full h-full py-3">
              {questions[stepId - 1].map((question) => (
                <div className="w-full py-5">
                  <div key={question.id} className="w-full">
                    <div className="w-full h-10">{question.text}</div>
                    {question.type === "options" ? (
                      <div className="w-full pl-16 pr-16 py-3 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-2">
                        {question.options.map((option) => (
                          <SurveyButton
                            key={option}
                            text={option}
                            name={question.id}
                            active={
                              selectedButtons[question.id]?.answer === option
                            }
                            extendClass=""
                            handleClick={() =>
                              handleButtonClick(question.id, option)
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="w-full py-8 flex justify-center">
                        <input
                          type="text"
                          id=""
                          name={question.id}
                          class="focus:outline-[#1976d2] w-1/2 border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[5px] rounded-2xl w-1/2   "
                          placeholder="入力してください"
                          value={inputData[question.id]?.answer}
                          onChange={(e) => handleInputChange(e, question.id)}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold">
                <button
                  onClick={handleNextSection}
                  className="button-size text-white text-base font-semibold bg-[#F0F1EF] bg-opacity-50-btn-primary w-full h-full bg-[#666FC8] shadow-md  "
                >
                  次へ
                </button>
              </div>
              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold"></div>
            </div>
          </div>
        ) : stepId == 7 ? (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
            <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
              <FormControl sx={{ minWidth: 150, maxWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label"></InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  //value={member}
                  label="メンバー"
                  onChange={handleChange}
                  //disabled={isChangeButton}
                  style={{ borderRadius: "3rem", backgroundColor: "#868686" }}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }}
                  className="!w-72"
                  value={selectedValue}
                >
                  {/* {listCustomer?.map((customer) => (
                <MenuItem value={customer.code}>{customer.describe}</MenuItem>
              ))} */}
                  {sections.map((section) => (
                    <MenuItem value={section.id}>{section.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full h-full py-3">
              {questions[stepId - 1].map((question) => (
                <div className="w-full py-5">
                  <div key={question.id} className="w-full">
                    <div className="w-full h-10">{question.text}</div>
                    {question.type === "options" ? (
                      <div className="w-full pl-16 pr-16 py-3 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-2">
                        {question.options.map((option) => (
                          <SurveyButton
                            key={option}
                            text={option}
                            name={question.id}
                            active={
                              selectedButtons[question.id]?.answer === option
                            }
                            extendClass=""
                            handleClick={() =>
                              handleButtonClick(question.id, option)
                            }
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="w-full py-8 flex justify-center">
                        <input
                          type="text"
                          id=""
                          name={question.id}
                          class="focus:outline-[#1976d2] w-1/2 border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[5px] rounded-2xl w-1/2   "
                          placeholder="入力してください"
                          value={inputData[question.id]?.answer}
                          onChange={(e) => handleInputChange(e, question.id)}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold">
                <button
                  onClick={handleSubmit}
                  className="button-size text-white text-base font-semibold bg-[#F0F1EF] bg-opacity-50-btn-primary w-full h-full bg-[#666FC8] shadow-md  "
                >
                  送信する
                </button>
              </div>
              <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold"></div>
            </div>
          </div>
        ) : (
          <div>Other Steps {stepId}</div>
        )}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default Customer1StImpressionAndFactCheckPage;

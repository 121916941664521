import React from "react";

function LayoutRow(props) {
  const { children, className, single, ...otherProps } = props;

  return (
    <div
      className={`space-x-4 px-2 grid grid-cols-1 ${
        single ? "" : "xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4"
      } ${className ?? ""}`}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default LayoutRow;

import React from "react";
import InputField from "app/components/commonUI/InputField";
import InputDateField from "app/components/commonUI/InputDateField";

function SearchCustomerForm(props) {
  const { searchValues, setSearchFieldValue } = props;
  return (
    <div className="w-full h-auto rounded-lg border border-gray-400 max-w-[680px] mx-auto p-6 bg-[#F0F1EF] bg-opacity-50">
      <div className="w-full h-auto mb-4">
        <div className="w-full h-8 flex items-center text-lg font-semibold text-white">
          お客様番号
        </div>
        <div className="w-full h-auto">
          <InputField
            name="customerNo"
            value={searchValues.customerNo}
            type="text"
            onChange={(newValue) =>
              setSearchFieldValue("customerNo", newValue)
            }
          />
        </div>
      </div>

      <div className="w-full h-auto flex items-start mb-4 flex-wrap">
        <div className="w-full h-full flex items-start font-semibold leading-9 text-white">
          氏名（カナ）
        </div>
        <div className="w-full h-auto flex">
          <div className="w-1/2 h-auto pr-3">
            <InputField
              label=""
              placeholder="セイ"
              name="lastNameKata"
              value={searchValues.lastNameKata}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("lastNameKata", newValue)
              }
            />
          </div>
          <div className="w-1/2 h-auto pl-3">
            <InputField
              label=""
              placeholder="メイ"
              name="firstNameKata"
              value={searchValues.firstNameKata}
              type="text"
              onChange={(newValue) => setSearchFieldValue("firstNameKata", newValue)}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-auto flex items-start mb-4 flex-wrap">
        {/* <div className="w-full md:w-32 h-full flex items-start font-semibold leading-9 text-white">
          氏名（漢字）
        </div> */}
        <div className="w-full h-auto flex">
          <div className="w-1/2 h-auto pr-3">
            <InputField
              label=""
              placeholder="姓"
              name="lastNameKanji"
              value={searchValues.lastNameKanji}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("lastNameKanji", newValue)
              }
            />
          </div>
          <div className="w-1/2 h-auto pl-3">
            <InputField
              label=""
              placeholder="名"
              name="firstNameKanji"
              value={searchValues.firstNameKanji}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("firstNameKanji", newValue)
              }
            />
          </div>
        </div>
      </div>

      <div className="w-full h-auto items-start mb-4">
        <div className="w-32 h-full flex items-start font-semibold leading-9 text-white">
          生年月日
        </div>
        <div className="w-full h-auto">
          <InputDateField
            value={searchValues.dob}
            className="bg-[#FFFFFF] w-full"
            wfull={true}
            setValue={(newValue) =>
              setSearchFieldValue("dob", newValue)
            }
          />
        </div>
      </div>

      <div className="w-full h-auto mb-4">
        <div className="w-full h-8 flex items-center text-lg font-semibold text-white">
          電話番号
        </div>
        <div className="w-full h-auto">
          <InputField
            name="phone"
            value={searchValues.phone}
            type="text"
            onChange={(newValue) =>
              setSearchFieldValue("phone", newValue)
            }
          />
        </div>
      </div>

      <div className="w-full h-auto mb-4">
        <div className="w-32 h-full flex items-start font-semibold leading-9 text-white">
          メールアドレス
        </div>
        <div className="w-full h-auto">
          <InputField
            name="email"
            value={searchValues.email}
            type="text"
            onChange={(newValue) =>
              setSearchFieldValue("email", newValue)
            }
          />
        </div>
      </div>

      <div className="w-full h-auto ">
        <div className="w-32 h-full flex items-start font-semibold leading-9 text-white">
          住所
        </div>
        <div className="w-full h-auto flex">
          <div className="w-3/12 h-auto mb-4 pr-3">
            <InputField
              name="postalCode"
              value={searchValues.postalCode}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("postalCode", newValue)
              }
            />
          </div>
          <div className="w-9/12 h-auto">
            <InputField
              name="address"
              value={searchValues.address}
              type="text"
              onChange={(newValue) =>
                setSearchFieldValue("address", newValue)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchCustomerForm;

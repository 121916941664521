import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadInitError from "app/components/commonUI/LoadInitError";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import NoData from "app/components/commonUI/NoData";

import { getAllVisitsToday } from "app/services/api";
import Path from "app/route/Path";
import TableVisit from "./TableVisit";
import IssueMemberIdDialog from "./IssueMemberIdDialog";
import ConfirmPaymentMethodDialog from "./ConfirmPaymentMethodDialog";
import { Button } from "@mui/material";

function ToDayVisitList() {
  const [fetchToDayVisitState, setFetchCustomerBasicInfoState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const [confirmIssueId, setConfirmIssueId] = useState(null);
  const [confirmPaymentVisit, setConfirmPaymentVisit] = useState(null);

  const navigate = useNavigate();

  const loadToDayVisit = () => {
    setFetchCustomerBasicInfoState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllVisitsToday()
      .then((res) => {
        setFetchCustomerBasicInfoState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchCustomerBasicInfoState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadToDayVisit();
  }, []);

  const handleClickRow = (visit) => {
    // if (visit?.customer?.customerNo) {
    //   setConfirmIssueId(null);
    //   setConfirmPaymentVisit(visit);
    // } else {
    setConfirmIssueId(visit);
    setConfirmPaymentVisit(null);
    // }
  };

  const handleNextToPayment = () => {
    setConfirmPaymentVisit(confirmIssueId);
    setConfirmIssueId(null);
  };

  const handleIssueMemberIdSuccess = () => {
    // handle update data of table
    console.log("handle update data of table");
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>お帰り</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              お客様を選択してください。
            </h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4 pb-14 relative">
              <div className="w-full h-full overflow-auto">
                {fetchToDayVisitState.isLoading ? <LoadingProgress /> : null}

                {!fetchToDayVisitState.isLoading &&
                fetchToDayVisitState.error ? (
                  <LoadInitError error={fetchToDayVisitState.error} />
                ) : null}
                {!fetchToDayVisitState.isLoading &&
                !fetchToDayVisitState.error ? (
                  Array.isArray(fetchToDayVisitState.data) &&
                  fetchToDayVisitState.data.length > 0 ? (
                    <TableVisit
                      allVisit={fetchToDayVisitState.data}
                      handleClickRow={handleClickRow}
                    />
                  ) : (
                    <NoData />
                  )
                ) : null}
              </div>

              <div className="w-full h-14 bg-[#F0F1EF] bg-opacity-50 rounded-b-2xl flex items-center justify-between px-6 border-t border-gray-400">
                <div className="text-lg font-semibold">お客様を選択しない</div>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(Path.paymentNonCustomer)}
                >
                  次へ
                </Button>
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link to={Path.home} className="button-size bg-gray-btn-secondary">
              戻る
            </Link>
          </div>
        </div>

        <IssueMemberIdDialog
          open={!!confirmIssueId}
          handleClose={() => {
            setConfirmIssueId(null);
            setConfirmPaymentVisit(null);
          }}
          customerId={confirmIssueId?.customer?.id}
          handleNextToPayment={handleNextToPayment}
          handleIssueMemberIdSuccess={handleIssueMemberIdSuccess}
        />

        <ConfirmPaymentMethodDialog
          confirmPaymentVisit={confirmPaymentVisit}
          handleCloseDialog={() => {
            setConfirmIssueId(null);
            setConfirmPaymentVisit(null);
          }}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ToDayVisitList;

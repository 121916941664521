import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { TableHead } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import moment from "moment";
import { formatPaymentType, formatPrice } from "../../../utils";

export default function TableHistoryCashier(props) {
  const navigate = useNavigate();
  const { allHistoryCashier, allNonVisit } = props;

  return (
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            <TableCell className="!font-bold">会計日時</TableCell>
            <TableCell className="!font-bold" align="right">
              お客様名
            </TableCell>
            <TableCell className="!font-bold" align="right">
              支払方法
            </TableCell>
            <TableCell className="!font-bold" align="right">
              金額
            </TableCell>
            <TableCell className="!font-bold" align="right">
              レジ担当者
            </TableCell>
            <TableCell className="!font-bold" align="right">
              詳細
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allHistoryCashier.map((row, index) => {
            return (
              <TableRow
                hover
                onClick={() => {}}
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: "pointer" }}
              >
                <TableCell align="left">
                  {moment(row.payment?.paymentDate).format("YYYY-MM-DD HH:mm")}
                </TableCell>
                <TableCell align="right">
                  {row.customer?.firstNameKata} {row.customer?.lastNameKata}{" "}
                </TableCell>
                <TableCell align="right">
                  {formatPaymentType(row.payment?.paymentType)}
                </TableCell>
                <TableCell align="right">
                  {formatPrice(row.payment?.paymentAmount)}
                </TableCell>
                <TableCell align="right">
                  {row.staff?.firstNameKata} {row.staff?.lastNameKata}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate(
                        Path.cashierManagementCashierVisitDetail(row._id)
                      );
                    }}
                  >
                    詳細を見る
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          {allNonVisit.map((row, index) => {
            return (
              <TableRow
                hover
                onClick={() => {}}
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: "pointer" }}
              >
                <TableCell align="left">
                  {moment(row?.paymentDate).format("YYYY-MM-DD HH:mm")}
                </TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">
                  {formatPaymentType(row?.paymentType)}
                </TableCell>
                <TableCell align="right">
                  {formatPrice(row?.paymentAmount)}
                </TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate(
                        Path.cashierManagementCashierNonVisitDetail(row._id)
                      );
                    }}
                  >
                    詳細を見る
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadInitError from "app/components/commonUI/LoadInitError";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import { getVisitDetailForPayment, updateVisitDetail } from "app/services/api";
import Path from "app/route/Path";
import PaymentConfirm from "./PaymentConfirm";
import PaymentInput from "./PaymentInput";

function PaymentVisitByOtherMethodContent(props) {
  const { initVisitData, visitId } = props;

  const [updateVisitData, setUpdateVisitData] = useState({});
  const [confirmedVisitData, setConfirmedVisitData] = useState(null);
  const [isUpdatingVisit, setIsUpdatingVisit] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    paymentType: "",
    paymentMethod: "",
  });

  useEffect(() => {
    const initSales = Array.isArray(initVisitData.sales)
      ? initVisitData.sales.map((item) => ({
          ...item.product,
          amount: item.amount,
        }))
      : [];
    const initDiscount =
      Array.isArray(initVisitData.discounts) && initVisitData.discounts[0]
        ? initVisitData.discounts[0]
        : null;
    const initUpdateVisitData = {
      sales: initSales,
      discount: initDiscount,
      visitMenus: initVisitData.visitMenus,
    };

    setUpdateVisitData(initUpdateVisitData);
  }, [initVisitData]);

  const setDiscount = (newDiscount) => {
    setUpdateVisitData((oldState) => ({
      ...oldState,
      discount: newDiscount,
    }));
  };

  const setSaleProduct = (newSelectedProduct) => {
    setUpdateVisitData((oldState) => ({
      ...oldState,
      sales: newSelectedProduct,
    }));
  };

  const setVisitMenus = (newVisitMenu) => {
    setUpdateVisitData((oldState) => ({
      ...oldState,
      visitMenus: newVisitMenu,
    }));
  };

  const handleNextToConfirm = () => {
    if (
      !selectedPaymentMethod.paymentType ||
      !selectedPaymentMethod.paymentMethod
    )
      return;
    setIsUpdatingVisit(true);

    const data = {
      discounts: updateVisitData.discount
        ? [
            {
              discountType: updateVisitData.discount.discountType,
              discountAmount: updateVisitData.discount.discountAmount,
            },
          ]
        : [],
      sales: Array.isArray(updateVisitData.sales)
        ? updateVisitData.sales.map((item) => ({
            amount: item.amount,
            productId: item.id,
          }))
        : [],
      salonMenuIds: Array.isArray(updateVisitData.visitMenus)
        ? updateVisitData.visitMenus.map((item) => item.salonMenuId)
        : [],
    };

    updateVisitDetail(visitId, data)
      .then((res) => {
        setConfirmedVisitData(res.data);
        setIsUpdatingVisit(false);
      })
      .catch((error) => {
        setIsUpdatingVisit(false);
        console.log("error: ", error);
      });
  };

  const handleBackToInput = (newConfirmedData) => {
    const updatedSales = Array.isArray(newConfirmedData.sales)
      ? newConfirmedData.sales.map((item) => ({
          ...item.product,
          amount: item.amount,
        }))
      : [];
    const updatedDiscount =
      Array.isArray(newConfirmedData.discounts) && newConfirmedData.discounts[0]
        ? newConfirmedData.discounts[0]
        : null;
    const updatedVisitData = {
      sales: updatedSales,
      discount: updatedDiscount,
    };

    setUpdateVisitData(updatedVisitData);
    setConfirmedVisitData(null);
  };

  return (
    <>
      {!confirmedVisitData ||
      !selectedPaymentMethod.paymentType ||
      !selectedPaymentMethod.paymentMethod ? (
        <PaymentInput
          visitMenus={initVisitData?.visitMenus || []}
          updateVisitData={updateVisitData}
          setDiscount={setDiscount}
          setSaleProduct={setSaleProduct}
          setVisitMenus={setVisitMenus}
          nextToConfirm={handleNextToConfirm}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          visitId={visitId}
        />
      ) : (
        <PaymentConfirm
          confirmedVisitDataInput={confirmedVisitData}
          backToInput={handleBackToInput}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          visitId={visitId}
        />
      )}

      <LoadingBackdrop isOpen={isUpdatingVisit} />
    </>
  );
}

function PaymentByOtherMethod() {
  const [fetchVisitInfoState, setFetchVisitInfoState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const { id } = useParams();

  const loadToDayVisit = useCallback(() => {
    if (!id) return;
    setFetchVisitInfoState({
      isLoading: true,
      data: null,
      error: null,
    });

    getVisitDetailForPayment(id)
      .then((res) => {
        setFetchVisitInfoState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchVisitInfoState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [id]);

  useEffect(() => {
    loadToDayVisit();
  }, [loadToDayVisit]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>お帰り</p>
          </>
        }
      >
        <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap lg:py-12 pt-16 pb-8">
          <div className="w-full max-h-full p-4">
            <div className="w-full h-full overflow-auto">
              {fetchVisitInfoState.isLoading ? <LoadingProgress /> : null}

              {!fetchVisitInfoState.isLoading && fetchVisitInfoState.error ? (
                <LoadInitError error={fetchVisitInfoState.error} />
              ) : null}

              {!fetchVisitInfoState.isLoading &&
              !fetchVisitInfoState.error &&
              fetchVisitInfoState.data &&
              fetchVisitInfoState.data.payment ? (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <h3 className="text-lg font-semibold mb-6">
                    Visit has been paid
                  </h3>
                  <Link
                    to={Path.toDayVisitList}
                    className="button-size bg-blue-btn-primary text-white"
                  >
                    Back to Visit List
                  </Link>
                </div>
              ) : null}

              {!fetchVisitInfoState.isLoading &&
              !fetchVisitInfoState.error &&
              fetchVisitInfoState.data &&
              !fetchVisitInfoState.data.payment ? (
                <PaymentVisitByOtherMethodContent
                  initVisitData={fetchVisitInfoState.data}
                  visitId={id}
                />
              ) : null}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default PaymentByOtherMethod;

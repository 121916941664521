import React, { useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import RegisterStaffForm from "../components/RegisterStaffForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import ConfirmCreateDialog from "app/components/CommonDialog/ConfirmCreateDialog";
import { registerSalonStaff } from "../../../services/api";
import { toast } from "react-toastify";

function StaffManagementRegisterPage() {
  const [confirmedData, setConfirmedData] = useState(null);
  const [showConfirmCreate, setShowConfirmCreate] = useState(false);

  const navigate = useNavigate();

  const handleCreate = async () => {
    if (!confirmedData) return;
    const data = {
      lastNameKata: confirmedData.lastNameKata,
      firstNameKata: confirmedData.firstNameKata,
      lastNameKanji: confirmedData.lastNameKanji,
      firstNameKanji: confirmedData.firstNameKanji,
      dob: confirmedData.dob.toISOString(),
      phone: confirmedData.phone,
      email: confirmedData.email,
      postalCode: confirmedData.postalCode,
      address: confirmedData.address,
      imageUrl: confirmedData.imageUrl,
      loginName: confirmedData.loginName,
      password: confirmedData.password,
    };

    return await registerSalonStaff(data);
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>予約を</p>
            <p>確認する</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              スタッフ新規登録
            </h2>

            <div className="w-auto flex items-center justify-center"></div>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full flex items-center justify-center">
              <RegisterStaffForm
                isReadMode={!!confirmedData}
                handleCreateOrUpdate={
                  confirmedData ? () => setShowConfirmCreate(true) : null
                }
                setConfirmedData={setConfirmedData}
                hanldeBackClick={() => {}}
              />
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.staffManagementList}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>

        {/* <div className="w-full h-full py-12 relative">
          <div className="w-full h-8 flex items-center justify-between absolute inset-x-0 top-2">
            <h2 className="text-base lg:text-xl font-semibold">予約を登録する</h2>

            <button
              type="button"
              className="button-size bg-blue-btn-primary"
            >
              <FontAwesomeIcon icon={faDownload} />
              <span className="ml-2">エクスポート</span>
            </button>
          </div>
          <div className="w-full h-full flex items-center justify-center">
            <RegisterStaffForm
              isReadMode={!!confirmedData}
              handleCreateOrUpdate={
                confirmedData ? () => setShowConfirmCreate(true) : null
              }
              setConfirmedData={setConfirmedData}
              hanldeBackClick={() => {}}
            />
          </div>

          <div className="w-full h-12 flex justify-end absolute inset-x-0 bottom-0">
            <Link
              to={Path.staffManagementList}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div> */}

        <ConfirmCreateDialog
          open={confirmedData && showConfirmCreate}
          handleClose={() => setShowConfirmCreate(false)}
          confirmTitle="新規従業員を登録しますか?"
          confirmMessage=""
          createCompleteTitle="スタッフ情報を新規登録しました"
          createCompleteBtnLabel="終る"
          createCompleteAction={(createdData) => {
            console.log("createdData", createdData);
            navigate(Path.staffManagementDetail(createdData.id));
          }}
          createAction={handleCreate}
          confirmCancleLabel="キャンセル"
          confirmLabel="作成する"
          handleDeleteError={() => {
            toast.error(
              "ユーザー名が無効か、すでに存在します。別のユーザー名を選択してください。"
            );
            setShowConfirmCreate(false);
            setConfirmedData(null);
          }}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementRegisterPage;

import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

function LoadingBackdrop(props) {
  const { isOpen, onClick } = props;
  return (
    <Backdrop sx={{ color: "#fff", zIndex: "10000" }} open={isOpen} onClick={onClick}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingBackdrop;

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorIcon from "@mui/icons-material/Error";

import InputField from "app/components/commonUI/InputField";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const validationErrMessages = {
  nameRequired: "メニュー名は必須です。",
  menuTypeRequired: "メニューカテゴリは必須です。",
  durationNumberRequired: "所要時間は必須です。",
  priceTaxExcRequired: "料金（税抜）は必須です。",
  publicityStatusRequired: "配信方法は必須です。",
};

const publicityStatus = {
  PUBLIC: 1,
  PRIVATE: 0,
};

const menuType = {
  CUT: "cut",
  COLOR: "color",
  PERM: "perm",
  STRAIGHT: "straight",
  SPA: "spa",
  TREATMENT: "treatment",
  OTHER: "other",
};

function ConfirmPublicityStatusMenuContent(props) {
  const { value, handleSelect, errors, touched } = props;
  const handleChange = (event) => {
    handleSelect(event.target.value);
  };
  return (
    <div>
      <RadioGroup value={+value} onChange={handleChange}>
        <FormControlLabel
          value={Number(publicityStatus.PRIVATE)}
          control={<Radio />}
          label="非公開"
        />
        <FormControlLabel
          value={Number(publicityStatus.PUBLIC)}
          control={<Radio />}
          label="公開"
        />
      </RadioGroup>
      {touched && errors ? (
        <p className="w-full !text-red-600 !text-[12px]">
          <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
            <ErrorIcon
              fontSize="inherit"
              className="text-xs text-red-600 font-thin"
            />
          </span>
          <span>{errors}</span>
        </p>
      ) : null}
    </div>
  );
}

function MenuType(props) {
  const { value, handleSelect, errors, touched } = props;
  const handleChange = (event) => {
    handleSelect(event.target.value);
  };
  return (
    <div>
      <RadioGroup
        value={`${value}`}
        onChange={handleChange}
        disabled={value !== null ? true : false}
      >
        <FormControlLabel
          value={menuType.CUT}
          control={<Radio />}
          label="カット"
        />
        <FormControlLabel
          value={menuType.COLOR}
          control={<Radio />}
          label="カラー"
        />
        <FormControlLabel
          value={menuType.PERM}
          control={<Radio />}
          label="パーマ"
        />
        <FormControlLabel
          value={menuType.STRAIGHT}
          control={<Radio />}
          label="ストレート"
        />
        <FormControlLabel
          value={menuType.SPA}
          control={<Radio />}
          label="スパ"
        />
        <FormControlLabel
          value={menuType.TREATMENT}
          control={<Radio />}
          label="トリートメント"
        />
        <FormControlLabel
          value={menuType.OTHER}
          control={<Radio />}
          label="その他"
        />
      </RadioGroup>
      {touched && errors ? (
        <p className="w-full !text-red-600 !text-[12px]">
          <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
            <ErrorIcon
              fontSize="inherit"
              className="text-xs text-red-600 font-thin"
            />
          </span>
          <span>{errors}</span>
        </p>
      ) : null}
    </div>
  );
}

function CreateMenuForm(props) {
  const {
    menuInfo = {},
    isReadMode,
    handleCreate,
    confirmMenuData,
    handleBackClick,
    fetchMenuDetail,
    menuId,
  } = props;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(validationErrMessages.nameRequired),
    menuType: Yup.string().required(validationErrMessages.menuTypeRequired),
    durationNumber: Yup.string().required(
      validationErrMessages.durationNumberRequired
    ),
    priceTaxExc: Yup.string().required(
      validationErrMessages.priceTaxExcRequired
    ),
    publicityStatus: Yup.string().required(
      validationErrMessages.publicityStatusRequired
    ),
  });

  const onSubmit = async (values) => {
    confirmMenuData(values);
  };

  const [waitToConfirm, setWaitToConfirm] = useState(true);

  const { handleSubmit, errors, values, setFieldValue, touched, handleBlur } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        name: !!fetchMenuDetail ? fetchMenuDetail.name : menuInfo.name || "",
        menuType: !!fetchMenuDetail
          ? fetchMenuDetail.menuType
          : menuInfo.menuType || "",
        durationNumber: !!fetchMenuDetail
          ? fetchMenuDetail.durationNumber
          : menuInfo.durationNumber || "",
        priceTaxExc: !!fetchMenuDetail
          ? fetchMenuDetail.priceTaxExc
          : menuInfo.priceTaxExc || "",
        publicityStatus: !!fetchMenuDetail
          ? fetchMenuDetail.publicityStatus
          : Number(menuInfo.publicityStatus) || "",
      },
      onSubmit,
      validationSchema,
    });
  return (
    <form className="w-full h-full">
      <div className="w-full h-full flex flex-col gap-6 border border-gray-400 rounded-2xl p-4 overflow-auto">
        <InputField
          label="メニュー名"
          name="name"
          value={values.name}
          type="text"
          onChange={(newValue) => setFieldValue("name", newValue)}
          handleBlur={handleBlur}
          touched={touched.name}
          errors={errors.name}
          disabled={isReadMode}
          labelClasses="!w-[130px]"
        />
        <div className="flex">
          <label className="block shrink-0 font-semibold leading-9 !w-[130px]">
            メニューカテゴリ
          </label>
          <MenuType
            value={values.menuType}
            handleSelect={(newValue) => setFieldValue("menuType", newValue)}
            errors={errors.menuType}
            touched={touched.menuType}
          />
        </div>
        <div className="flex items-center gap-2">
          <InputField
            label="所要時間"
            name="durationNumber"
            value={values.durationNumber}
            type="text"
            onChange={(newValue) =>
              setFieldValue(
                "durationNumber",
                Number(newValue.replace(/[^0-9.]/g, ""))
              )
            }
            handleBlur={handleBlur}
            touched={touched.durationNumber}
            errors={errors.durationNumber}
            disabled={isReadMode}
            labelClasses="!w-[130px]"
          />
          <div className="font-semibold">分</div>
        </div>

        <div className="flex items-center gap-2">
          <InputField
            label="料金（税抜）"
            name="priceTaxExc"
            value={values.priceTaxExc}
            type="text"
            onChange={(newValue) =>
              setFieldValue(
                "priceTaxExc",
                Number(newValue.replace(/[^0-9.]/g, ""))
              )
            }
            handleBlur={handleBlur}
            touched={touched.priceTaxExc}
            errors={errors.priceTaxExc}
            disabled={isReadMode}
            labelClasses="!w-[130px]"
          />
          <div className="font-semibold">円</div>
        </div>
        <div className="flex">
          <label className="block shrink-0 font-semibold leading-9 !w-[130px]">
            配信方法
          </label>
          <ConfirmPublicityStatusMenuContent
            value={values.publicityStatus}
            handleSelect={(newValue) =>
              setFieldValue("publicityStatus", Number(newValue))
            }
            errors={errors.publicityStatus}
            touched={touched.publicityStatus}
          />
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          onClick={handleBackClick}
          type="button"
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          戻る
        </button>
        <button
          onClick={() => {
            console.log("Ricky handleCreate: ", handleCreate)
            if (handleCreate) {
              handleCreate();
            } else {
              handleSubmit();
              setWaitToConfirm(false)
            }
          }}
          type="button"
          className="button-size rounded bg-blue-btn-primary"
        >
          {waitToConfirm? "内容確認": "内容登録"}
        </button>
      </div>
    </form>
  );
}

export default CreateMenuForm;

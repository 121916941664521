import React, { useCallback, useEffect, useRef, useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import NoData from "app/components/commonUI/NoData";
import { getAllSalonStaffs } from "app/services/api";
import Path from "app/route/Path";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { getNameFromFirstLast } from "app/utils";

function SelectStaffToRegisterShiftPlanContent(props) {
  const { allActiveSalonStaff } = props;
  const staffListRef = useRef(null);

  useEffect(() => {
    const staffListEl = staffListRef.current;

    const handleStaffListWheel = (event) => {
      event.preventDefault();

      staffListEl.scrollBy({
        left: event.deltaY < 0 ? -30 : 30,
      });
    };

    if (staffListEl) {
      staffListEl.addEventListener("wheel", handleStaffListWheel);
    }

    return () => {
      if (staffListEl) {
        staffListEl.removeEventListener("wheel", handleStaffListWheel);
      }
    };
  }, []);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-12 flex items-end">
        <h2 className="text-xl lg:text-3xl font-bold">
          スタッフを選択してください。
        </h2>
      </div>
      <div className="flex-1 w-full flex items-center">
        <div className="w-auto max-w-full h-60 rounded-xl relative">
          <div
            ref={staffListRef}
            className="w-auto max-w-full h-full overflow-auto small-scrollbar"
          >
            <div className="w-auto h-full flex items-center justify-start flex-nowrap gap-4">
              {allActiveSalonStaff.map((staff) => (
                <Link
                  to={Path.staffManagementRegisterShiftPlan(staff.id)}
                  key={staff.id}
                  state={{ salonStaff: staff }}
                  className="w-32 h-full rounded-xl shrink-0 hover:bg-slate-200"
                >
                  <div className="w-full h-full flex flex-col items-center justify-center gap-6">
                    <Avatar
                      alt=""
                      src={staff.imageUrl || defaultAvatar}
                      sx={{
                        width: 80,
                        height: 80,
                        marginBottom: "8px",
                        border: "2px solid black",
                      }}
                    />

                    <h3 className="text-lg font-semibold text-center truncate w-full">
                      {getNameFromFirstLast(staff)}
                    </h3>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectStaffToRegisterShiftPlan() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadAllStaff = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllSalonStaffs()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadAllStaff();
  }, [loadAllStaff]);

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p>勤怠</p>}>
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">シフト作成</h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full overflow-auto">
              {loadInitState.isLoading ? <LoadingProgress /> : null}

              {!loadInitState.isLoading && loadInitState.error ? (
                <LoadInitError error={loadInitState.error} />
              ) : null}
              {!loadInitState.isLoading && !loadInitState.error ? (
                Array.isArray(loadInitState.data) &&
                loadInitState.data.length > 0 ? (
                  <SelectStaffToRegisterShiftPlanContent
                    allActiveSalonStaff={loadInitState.data}
                  />
                ) : (
                  <NoData />
                )
              ) : null}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SelectStaffToRegisterShiftPlan;

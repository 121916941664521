import React from "react";

function AnalyzeBox(props) {
  const { title, children, onClickTitle } = props;
  return (
    <div className="w-full h-full rounded-lg border border-gray-400 hover:shadow-md">
      <button
        type="button"
        onClick={() => onClickTitle && onClickTitle()}
        className="h-12 w-full flex items-center justify-center truncate bg-slate-500 text-white rounded-t-lg"
      >
        <span className="text-lg font-semibold">{title}</span>
      </button>

      <div className="w-full h-0 pt-[100%] relative">
        <div className="w-full h-full absolute inset-0 bg-slate-200 rounded-b-lg">
          {children}
        </div>
      </div>
    </div>
  );
}

export default AnalyzeBox;

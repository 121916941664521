import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import MainMenuItem from "../../../components/MainMenuItem";
import { getSalonDetail } from "../../../services/api/18";
import CommonDialog from "../../../components/CommonDialog";
import { ResponsiveMenuItem } from "../../Home";
import { useMediaQuery } from "@mui/material";
function SystemManagementTopPage() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:680px)");
  const [checkSalonRegister, setCheckSalonRegister] = useState(false);
  const handleCheckSalonRegister = () => {
    getSalonDetail().then((res) => {
      if (res.data._id) {
        setCheckSalonRegister(false);
        navigate(Path.systemManagementEdit(res.data._id));
      } else {
        setCheckSalonRegister(true);
      }
    });
  };
  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>システム</p>
          </>
        }
        onClick={() => navigate(Path.home)}
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          {isMobile ? (
            <div className="w-full h-full py-3 px-4">
              <div className="w-full h-full">
                <div className="w-full h-full flex flex-col gap-4 overflow-auto text-lg text-white ">
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <div className="w-full flex justify-end">
                      <button
                        disabled
                        className="w-full h-9 flex items-center justify-center px-4 py-1 rounded-full bg-gray-btn-secondary font-semibold"
                      >
                        <span>店舗情報</span>
                      </button>
                    </div>
                    <div className="w-full">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={<p>確認</p>}
                        onClick={handleCheckSalonRegister}
                        size="100%"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <div className="w-full flex justify-end">
                      <button
                        disabled
                        className="w-full h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary font-semibold"
                      >
                        <span>メニュー</span>
                      </button>
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={<p>確認</p>}
                        onClick={() => navigate(Path.systemManagementAllMenu)}
                        size="100%"
                      />
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={<p>登録</p>}
                        onClick={() =>
                          navigate(Path.systemManagementRegisterMenu)
                        }
                        size="100%"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <div className="w-full flex justify-end">
                      <button
                        disabled
                        className="w-full h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary font-semibold"
                      >
                        <span>アクセス権</span>
                      </button>
                    </div>

                    <div className="w-full">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={<p>設定</p>}
                        onClick={() =>
                          navigate(Path.systemManagementSettingAccessRight)
                        }
                        size="100%"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <div className="w-full flex justify-end">
                      <button
                        disabled
                        className="w-full h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary font-semibold"
                      >
                        <span>レジ承認</span>
                      </button>
                    </div>
                    <div className="w-full">
                      <ResponsiveMenuItem
                        isMobile={isMobile}
                        itemTitle={<p>設定</p>}
                        onClick={() =>
                          navigate(Path.systemManagementConnectDevice)
                        }
                        size="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center gap-9 w-full max-h-full pr-3 text-lg text-white font-semibold rounded-2xl p-4">
              <div className="flex gap-6 justify-center items-center">
                <div className="w-1/2 flex justify-end">
                  <button
                    disabled
                    className="w-[150px] h-9 flex items-center justify-center px-4 py-1 rounded-full bg-gray-btn-secondary"
                  >
                    <span>店舗情報</span>
                  </button>
                </div>
                <div className="w-1/2">
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">確認</p>
                      </div>
                    }
                    onClick={handleCheckSalonRegister}
                    borderSize={4}
                    size={60}
                  />
                </div>
              </div>
              <div className="flex gap-6 justify-center items-center">
                <div className="w-1/2 flex justify-end">
                  <button
                    disabled
                    className="w-[150px] h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary"
                  >
                    <span>メニュー</span>
                  </button>
                </div>
                <div className="w-1/2 flex gap-6">
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">確認</p>
                      </div>
                    }
                    onClick={() => navigate(Path.systemManagementAllMenu)}
                    borderSize={4}
                    size={60}
                  />
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">登録</p>
                      </div>
                    }
                    onClick={() => navigate(Path.systemManagementRegisterMenu)}
                    borderSize={4}
                    size={60}
                  />
                </div>
              </div>
              <div className="flex gap-6 justify-center items-center">
                <div className="w-1/2 flex justify-end">
                  <button
                    disabled
                    className="w-[150px] h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary"
                  >
                    <span>アクセス権</span>
                  </button>
                </div>

                <div className="w-1/2">
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">設定</p>
                      </div>
                    }
                    onClick={() =>
                      navigate(Path.systemManagementSettingAccessRight)
                    }
                    borderSize={4}
                    size={60}
                  />
                </div>
              </div>
              <div className="flex gap-6 justify-center items-center">
                <div className="w-1/2 flex justify-end">
                  <button
                    disabled
                    className="w-[150px] h-9 flex items-center justify-center px-4 rounded-full bg-gray-btn-secondary"
                  >
                    <span>レジ承認</span>
                  </button>
                </div>
                <div className="w-1/2">
                  <MainMenuItem
                    itemTitle={
                      <div>
                        <p className="text-xs font-medium">設定</p>
                      </div>
                    }
                    onClick={() => navigate(Path.systemManagementConnectDevice)}
                    borderSize={4}
                    size={60}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <CommonDialog
          open={checkSalonRegister}
          handleClose={() => setCheckSalonRegister(false)}
          title="店舗情報が登録されていません。"
          actions={
            <div className="w-full flex items-center justify-end text-white text-base font-semibold">
              <button
                onClick={() => navigate(Path.systemManagement)}
                type="button"
                className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
              >
                戻る
              </button>
              <button
                onClick={() => navigate(Path.systemManagementRegisterSalon)}
                type="button"
                className="button-size bg-blue-btn-primary"
              >
                登録
              </button>
            </div>
          }
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SystemManagementTopPage;

import React, { forwardRef, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import logo from "app/assets/images/logo.png";
import LoadingBackdrop from "../commonUI/LoadingBackdrop";
import {
  defaultDateTimeFormat,
  payment_type_and_method,
} from "../../constants";
import { formatPrice } from "../../utils";
import StarWebPrintTrader from "../../assets/js/StarWebPrintTrader";
import StarWebPrintBuilder from "../../assets/js/StarWebPrintBuilder";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { getSalonDetail } from "../../services/api/18";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";

function ReceiptContentToPrint(props) {
  const {
    receiptInfo,
    serviceList,
    saleList,
    totalDiscount,
    paymentMethod,
    totalWithoutDiscount,
    totalWithoutDiscountWithoutTax,
  } = props;

  const [loadSalonInfo, setLoadSalonInfo] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const handleLoadSalonInfo = () => {
    setLoadSalonInfo({
      isLoading: true,
      data: null,
      error: null,
    });

    getSalonDetail()
      .then((res) => {
        setLoadSalonInfo({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadSalonInfo({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const drawReceipt = (
    receiptInfo,
    serviceList,
    saleList,
    totalDiscount,
    paymentMethod,
    totalWithoutDiscount,
    salonInfo,
    totalWithoutDiscountWithoutTax
  ) => {
    var canvas = document.getElementById("canvasPaper");
    const leftPosition = 0;
    const centerPosition = (canvas.width - leftPosition) / 2;
    const rightPosition = canvas.width - leftPosition;

    console.log("canvas.height : ", canvas.height)

    const fontSize = 28;
    const lineSpace = 32;
    const receiptWidth = 384;

    const salonName = salonInfo.name;
    //const salonName = "ROOM";
    const salonPhone = salonInfo.phone;
    const paymentTime = moment().tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm");
    const salonAddress = `${
      salonInfo.city ? `${salonInfo.city}` : ""
    }${salonInfo.street ? `${salonInfo.street}` : ""
    }${salonInfo.building ? `${salonInfo.building}` : ""}`;
    const salonInvoiceRegistrationNumber = salonInfo.invoiceRegistrationNumber;
    //const salonInvoiceRegistrationNumber = "T0000123456789";
    const linkWeb = window.location.hostname;

    const totalTax = ((totalWithoutDiscountWithoutTax - totalDiscount) * 0.1);
    const totalPrice = (totalWithoutDiscountWithoutTax - totalDiscount + totalTax);

    var payForService = false;
    var payForSale = false;

    if (serviceList && serviceList.length > 0) payForService = true;
    if (saleList && saleList.length > 0) payForSale = true;

    console.log("receipt -- receiptInfo", receiptInfo)
    console.log("receipt -- serviceList", serviceList)
    console.log("receipt -- saleList", saleList)
    console.log("receipt -- totalDiscount", totalDiscount)
    console.log("receipt -- totalWithoutDiscount", totalWithoutDiscount)
    console.log("receipt -- salonInfo", salonInfo)

    const DrawRightText = (text, cursor) => {
      if (canvas.getContext) {
        var context = canvas.getContext("2d");

        context.textAlign = "right";

        context.fillText(text, rightPosition, cursor);

        context.textAlign = "start";
      }
    };

    const DrawCenterText = (text, cursor) => {
      if (canvas.getContext) {
        var context = canvas.getContext("2d");

        context.textAlign = "center";

        context.fillText(text, centerPosition, cursor);

        context.textAlign = "start";
      }
    };

    function DrawLeftText(text, cursor) {
      if (canvas.getContext) {
        var context = canvas.getContext("2d");

        context.textAlign = "left";

        context.fillText(text, leftPosition, cursor);

        context.textAlign = "start";
      }
    }

    function DrawQrQcode(cursor) {
      console.log(cursor)

      const qrcode = document.getElementById("qrcodeId");
      if (qrcode == null) return;

      console.log("qrcode.width", qrcode.width)
      console.log("qrcode.height", qrcode.height)



      //qrcode.scale(0.5,0.5)
      // qrcode.width = 120
      // qrcode.height = 120
      if (canvas.getContext) {
        var context = canvas.getContext("2d");
        
        context.textAlign = "left";
        
        context.drawImage(qrcode, 130, cursor);
        
        context.textAlign = "start";
      }
    }

    function truncateString(str, maxLength) {
      if (str.length <= maxLength) {
        return str;
      } else {
        return str.slice(0, maxLength) + "...";
      }
    }
    
    
    

    if (canvas.getContext) {
      var context = canvas.getContext("2d");

      context.clearRect(0, 0, canvas.width, canvas.height);

      context.textBaseline = "top";

      var font = "";

      font += fontSize + "px ";

      font += "Cambria";

      context.font = font;

      var fontSmall = "";

      fontSmall += "20px ";

      fontSmall += "Cambria";

      var fontTitle = "";

      fontTitle += "40px ";

      fontTitle += "Cambria";

      let cursor = 50;

      canvas.setAttribute("height", cursor);
      canvas.setAttribute("width", receiptWidth);

      (() => {
        let height = cursor;
        height += 7 * lineSpace;

        if (Array.isArray(serviceList) && serviceList.length > 0) {
          serviceList.forEach((serviceItem) => {
            height += lineSpace;
          });
        }

        if (Array.isArray(saleList) && saleList.length > 0) {
          saleList.forEach((saleItem) => {
            height += lineSpace;
          });
        }

        height += 12 * lineSpace;
        height += 5 * lineSpace;
        if (payForService) {
          height += 3 * lineSpace;
        }

        if (salonInvoiceRegistrationNumber) {
          height += 1 * lineSpace;
        }

        canvas.setAttribute("height", height);
        canvas.setAttribute("width", 384);
      })();

      // Start drawing 
      context.font = font;

      DrawCenterText("会計日：" + paymentTime, cursor);
      cursor += 2 * lineSpace;

      context.font = fontTitle;
      DrawCenterText("領収書", cursor);
      cursor += 2 * lineSpace;

      context.font = font;
      if (receiptInfo.customer) {
        DrawCenterText(`${receiptInfo.customer.lastNameKanji}　${receiptInfo.customer.firstNameKanji}　様`, cursor);
        cursor += lineSpace;
        if (receiptInfo.customer.customerNo) {
          DrawCenterText("ID:" + receiptInfo.customer.customerNo, cursor);
        }
        //DrawQrQcode(cursor);
  
        cursor += lineSpace;
      }


      context.fillRect(
        leftPosition,
        cursor - 12,
        receiptWidth - 2 * leftPosition,
        2
      );
      cursor += lineSpace;

      DrawLeftText("領収金額", cursor);
      cursor += 2 * lineSpace;

      if (Array.isArray(serviceList) && serviceList.length > 0) {
        serviceList.forEach((serviceItem) => {
          let truncatedMenuName = truncateString(serviceItem.name, 17);
          DrawLeftText(truncatedMenuName, cursor);
          DrawRightText(formatPrice(serviceItem.priceTaxExc), cursor);
          cursor += lineSpace;
        });
      }

      if (Array.isArray(saleList) && saleList.length > 0) {
        saleList.forEach((saleItem) => {
          let truncatedMenuName = truncateString(saleItem.name, 17);
          DrawLeftText(truncatedMenuName + ` (商品)`, cursor);
          DrawRightText(
            `${saleItem.amount}x ${formatPrice(saleItem.price)}`,
            cursor
          );
          cursor += lineSpace;
          console.log(saleItem)
        });
      }

      cursor += lineSpace;
      context.fillRect(
        leftPosition,
        cursor - 2,
        receiptWidth - 2 * leftPosition,
        2
      );
      cursor += lineSpace;

      DrawLeftText("小計額(税抜き)", cursor);
      DrawRightText(formatPrice(totalWithoutDiscountWithoutTax), cursor);
      cursor += lineSpace;

      DrawLeftText("割引", cursor);
      DrawRightText("-" + formatPrice(totalDiscount), cursor);
      cursor += lineSpace;

      console.log("formatPrice(totalDiscount) : ", "-" + formatPrice(totalDiscount))

      DrawLeftText("ご使用クーポン", cursor);
      DrawRightText("", cursor);
      cursor += lineSpace;
      

      DrawLeftText("10%対象額", cursor);
      DrawRightText(
        formatPrice(
          totalWithoutDiscountWithoutTax - totalDiscount > 0
            ? totalWithoutDiscountWithoutTax - totalDiscount
            : 0
          ), 
          cursor
        );
      cursor += lineSpace;

      DrawLeftText("10%税額", cursor);
      DrawRightText(
        formatPrice(totalTax), 
          cursor
        );
      cursor += lineSpace;
      cursor += lineSpace;

      DrawLeftText("合計", cursor);
      DrawRightText(
        formatPrice(totalPrice),
        cursor
      );

      cursor += lineSpace;

      
      context.fillRect(
        leftPosition,
        cursor - 12,
        receiptWidth - 2 * leftPosition,
        2
      );
      cursor += lineSpace;

      DrawLeftText("上記正に領収いただきました", cursor);
      cursor += 2 * lineSpace;

      DrawLeftText("但　美容費　として", cursor);
      cursor += 2 * lineSpace;

      DrawLeftText(salonName, cursor);
      cursor += lineSpace;

      DrawLeftText(salonAddress, cursor);
      cursor += lineSpace;

      
      let start_num = salonPhone.substr(0,2);
      let mid_num = salonPhone.substr(2, 4);
      let end_num = salonPhone.substr(6);
      let phone =  start_num + '-' + mid_num + '-' + end_num;

      DrawLeftText("TEL：" + phone, cursor);
      cursor += lineSpace;

      if(salonInvoiceRegistrationNumber) {
        DrawLeftText("登録番号：" + salonInvoiceRegistrationNumber, cursor);
        cursor += lineSpace;
      }

      // DrawCenterText("当サロンをご利用いただき", cursor);
      // cursor += lineSpace;
      // DrawCenterText("誠にありがとうございます。", cursor);
      // cursor += lineSpace;
      //DrawCenterText(linkWeb, cursor);    
      cursor += 3*lineSpace;

      // end of receipt
      context.font = "10px Cambria";
      DrawCenterText(".", cursor);

      const img = document.getElementById("receipt_image");
      const receiptImage = canvas.toDataURL();
      img.src = receiptImage;
    }
  };

  useEffect(() => {
    handleLoadSalonInfo();
  }, []);

  useEffect(() => {
    if (loadSalonInfo.data)
      drawReceipt(
        receiptInfo,
        serviceList,
        saleList,
        totalDiscount,
        paymentMethod,
        totalWithoutDiscount,
        loadSalonInfo.data,
        totalWithoutDiscountWithoutTax
      );
  }, [
    loadSalonInfo.data,
    paymentMethod,
    saleList,
    serviceList,
    totalDiscount,
    totalWithoutDiscount,
    totalWithoutDiscountWithoutTax,
  ]);

  return (
    <>
      <div className="w-full h-full max-h-[calc(100vh-160px)] relative overflow-x-hidden overflow-y-auto md:px-6">
        <div className="w-full h-full absolute -z-10 overflow-hidden">
          <canvas id="canvasPaper" width="384" height="200">
            Your browser does not support Canvas!
          </canvas>
        </div>

        <div className="w-full h-full bg-[#F0F1EF] bg-opacity-50">
          <img id="receipt_image" className="w-full h-auto" alt="" />
        </div>
      </div>
      <LoadingBackdrop isOpen={loadSalonInfo.isLoading} />
    </>
  );
}

function PrintReceiptDialog(props) {
  const { open, handleClose, receiptInfo } = props;

  const selectedPrinter = useSelector(
    (state) => state.printers.selectedPrinter
  );

  const handlePrint = () => {
    var trader = new StarWebPrintTrader({
      url: selectedPrinter,
      papertype: "normal",
    });

    trader.onReceive = function (response) {
      toast.success("請求書が印刷されました。");
      handleClose();
    };

    trader.onError = function (response) {
      toast.error(
        "請求書の印刷に失敗しました。プリンターの接続をもう一度確認してください。"
      );
    };

    try {
      var canvas = document.getElementById("canvasPaper");

      if (canvas.getContext) {
        var context = canvas.getContext("2d");

        var builder = new StarWebPrintBuilder();

        var request = "";

        request += builder.createInitializationElement();

        request += builder.createBitImageElement({
          context: context,
          x: 0,
          y: 0,
          width: canvas.width,
          height: canvas.height,
        });

        request += builder.createCutPaperElement({ feed: true });

        trader.sendMessage({ request: request });
      }
    } catch (e) {
      toast.error(
        "請求書の印刷に失敗しました。プリンターの接続をもう一度確認してください。"
      );
    }
  };

  const handlePrintDefaultDevice = () => {
    const iframe = document.createElement("iframe");

    // Make it hidden
    iframe.style.height = 0;
    iframe.style.visibility = "hidden";
    iframe.style.width = 0;
    iframe.style.padding = 0;
    iframe.style.margin = 0;

    // Set the iframe's source
    iframe.setAttribute("srcdoc", "<html><body></body></html>");

    document.body.appendChild(iframe);

    iframe.contentWindow.addEventListener("afterprint", function () {
      iframe.parentNode.removeChild(iframe);
    });

    iframe.addEventListener("load", function () {
      // Clone the image
      const image = document.getElementById("receipt_image").cloneNode();
      image.style.width = "50mm";
      image.style.margin = 0;


      // Append the image to the iframe's body
      const body = iframe.contentDocument.body;
      body.style.textAlign = "center";
      body.style.padding = 0;
      body.style.margin = 0;
      body.appendChild(image);

      image.addEventListener("load", function () {
        // Invoke the print when the image is ready
        iframe.contentWindow.print();
      });
    });
  };

  const totalWithoutDiscount = useMemo(() => {
    let total = 0;
    if (Array.isArray(receiptInfo.visitMenus)) {
      receiptInfo.visitMenus.forEach((item) => (total += item.priceTaxInc));
    }

    if (Array.isArray(receiptInfo.sales)) {
      receiptInfo.sales.forEach((item) => (total += item.price * item.amount));
    }
    return total;
  }, [receiptInfo]);

  const totalWithoutDiscountWithoutTax = useMemo(() => {
    let total = 0;
    if (Array.isArray(receiptInfo.visitMenus)) {
      receiptInfo.visitMenus.forEach((item) => (total += item.priceTaxExc));
    }

    if (Array.isArray(receiptInfo.sales)) {
      receiptInfo.sales.forEach((item) => {
        const itemPriceWithoutTax = (item.price);
        (total += itemPriceWithoutTax * item.amount)
      });
    }
    return total;
  }, [receiptInfo]);

  const totalDiscount = useMemo(() => {
    let total = 0;
    if (Array.isArray(receiptInfo.discounts)) {
      receiptInfo.discounts.forEach((item) => (total += item.discountAmount));
    }
    return total;
  }, [receiptInfo]);

  const paymentMethodStr = useMemo(() => {
    if (
      !receiptInfo.payment ||
      !receiptInfo.payment.paymentType ||
      !receiptInfo.payment.paymentMethod
    )
      return { payment_method: "", payment_method_label: "" };

    const paymentTypeObj =
      payment_type_and_method[receiptInfo.payment.paymentType];
    const paymentMethodObj = Array.isArray(paymentTypeObj?.payment_methods)
      ? paymentTypeObj.payment_methods.find(
          (item) => item.payment_method === receiptInfo.payment.paymentMethod
        )
      : undefined;
    return paymentMethodObj?.payment_method_label || "";
  }, [receiptInfo]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      PaperProps={{
        style: {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <div className="w-full h-full py-5 bg-slate-200">
        {
            receiptInfo.customer?._id? (
              <QRCodeCanvas id="qrcodeId" className="hidden" value={receiptInfo.customer?._id} size={120} />
            ) : null
        }
        {open ? (
          <ReceiptContentToPrint
            receiptInfo={receiptInfo}
            serviceList={receiptInfo.visitMenus}
            saleList={receiptInfo.sales}
            totalDiscount={totalDiscount}
            paymentMethod={paymentMethodStr}
            totalWithoutDiscount={totalWithoutDiscount}
            totalWithoutDiscountWithoutTax={totalWithoutDiscountWithoutTax}
          />
        ) : null}

        <div className="w-full h-12 flex items-end justify-end px-6">
          <button
            className="button-size bg-blue-btn-primary"
            onClick={selectedPrinter ? handlePrint : handlePrintDefaultDevice}
          >
            印刷する
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default PrintReceiptDialog;

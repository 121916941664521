import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "moment/locale/ja";
import { Avatar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faChevronLeft,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadInitError from "app/components/commonUI/LoadInitError";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import NoData from "app/components/commonUI/NoData";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import { getAllStaffWithShiftPlanByDate } from "app/services/api";
import { defaultDateFormat } from "app/constants";
import Path from "app/route/Path";
import { getNameFromFirstLast } from "app/utils";
import CommonDialog from "../../../components/CommonDialog";

function ListStaffRegisterForDutyDialog(props) {
  const { open, handleClose, listStaffRegisterForDuty } = props;

  useEffect(() => {
    const offStaffListEl = document.getElementById("register-for-duty");

    const handleOffStaffListWheel = (event) => {
      event.preventDefault();

      offStaffListEl.scrollBy({
        left: event.deltaY < 0 ? -30 : 30,
      });
    };

    if (offStaffListEl) {
      offStaffListEl.addEventListener("wheel", handleOffStaffListWheel);
    }

    return () => {
      if (offStaffListEl) {
        offStaffListEl.removeEventListener("wheel", handleOffStaffListWheel);
      }
    };
  }, []);

  return (
    <CommonDialog
      open={open}
      handleClose={handleClose}
      handleBack={() => {}}
      handleConfim={() => {}}
      title=""
      actions={<div> </div>}
    >
      <div className="w-full h-48 inset-x-0 bottom-0 flex justify-between pt-8">
        <div className="w-full h-full flex-1 border border-gray-400 rounded-lg relative py-8 px-6">
          <div className="absolute w-auto h-6 flex items-center justify-center px-3 left-4 -top-3 bg-[#F0F1EF] bg-opacity-50 z-20">
            <h3 className="text-lg leading-6 font-semibold">休暇スタッフ</h3>
          </div>
          <div
            id="register-for-duty"
            className="w-full h-full small-scrollbar overflow-auto"
          >
            {Array.isArray(listStaffRegisterForDuty) &&
            listStaffRegisterForDuty.length > 0 ? (
              <div className="w-auto h-full flex items-center flex-nowrap gap-2">
                {listStaffRegisterForDuty.map((staff) => (
                  <Link
                    to={Path.staffManagementAttendanceWithoutShiftPlan(
                      staff.id
                    )}
                    key={staff.id}
                    className="w-20 h-full shrink-0 rounded-lg bg-slate-100 hover:bg-slate-200 px-1"
                  >
                    <div className="w-full h-full flex flex-col items-center justify-center">
                      <Avatar
                        alt=""
                        sx={{ width: 44, height: 44, border: "solid 1px" }}
                        src={staff.imageUrl || defaultAvatar}
                      />

                      <h3 className="w-full truncate text-sm font-semibold text-center">
                        {getNameFromFirstLast(staff)}
                      </h3>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
    </CommonDialog>
  );
}

function ListStaffOnDuty(props) {
  const { listStaffOnDuty, setShowListStaffRegisterForDuty } = props;

  useEffect(() => {
    const onStaffListEl = document.getElementById("on-duty-list");

    const handleOnStaffListWheel = (event) => {
      event.preventDefault();

      onStaffListEl.scrollBy({
        left: event.deltaY < 0 ? -30 : 30,
      });
    };

    if (onStaffListEl) {
      onStaffListEl.addEventListener("wheel", handleOnStaffListWheel);
    }

    return () => {
      if (onStaffListEl) {
        onStaffListEl.removeEventListener("wheel", handleOnStaffListWheel);
      }
    };
  }, []);

  return (
    <div className="w-auto max-w-full h-60 rounded-xl pr-32 relative">
      <div
        id="on-duty-list"
        className="w-auto max-w-full h-full overflow-auto small-scrollbar"
      >
        {Array.isArray(listStaffOnDuty) && listStaffOnDuty.length > 0 ? (
          <div className="w-auto h-full flex items-center justify-start flex-nowrap gap-4">
            {listStaffOnDuty.map((staff) => (
              <Link
                to={Path.staffManagementUpdateStaffAttendance(
                  staff.id,
                  staff.attendances?.[0]?.id
                )}
                key={staff.id}
                className="w-32 h-full rounded-xl shrink-0 bg-slate-100 hover:bg-slate-200 px-2"
              >
                <div className="w-full h-full flex flex-col items-center justify-center gap-6">
                  <Avatar
                    alt=""
                    src={staff.imageUrl || defaultAvatar}
                    sx={{ width: 80, height: 80, border: "solid 2px" }}
                  />
                  <h3 className="text-lg font-semibold text-center truncate w-full">
                    {getNameFromFirstLast(staff)}
                  </h3>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center text-lg font-semibold">
            現在スタッフは常駐しておりません！
          </div>
        )}
      </div>

      <div className="absolute inset-y-0 right-0 h-full w-32 px-2 py-8">
        <button
          type="button"
          onClick={() => setShowListStaffRegisterForDuty(true)}
          className="w-full h-full rounded-2xl bg-[#F0F1EF] bg-opacity-50 hover:bg-slate-200 transition-all flex items-center justify-center group"
        >
          <span className="w-16 h-16 flex items-center justify-center rounded-full bg-gray-600 group-hover:bg-slate-800">
            <FontAwesomeIcon icon={faPlus} size="3x" color="white" />
          </span>
        </button>
      </div>
    </div>
  );
}

function StaffManagementTopPageContent(props) {
  const { allStaffInfo } = props;
  const [showListStaffRegisterForDuty, setShowListStaffRegisterForDuty] =
    useState(false);

  const listStaffOnDuty = useMemo(() => {
    if (!Array.isArray(allStaffInfo)) return [];
    return allStaffInfo.filter(
      (staff) => Array.isArray(staff.shiftplans) && staff.shiftplans.length > 0
    );
  }, [allStaffInfo]);

  const listStaffRegisterForDuty = useMemo(() => {
    if (!Array.isArray(allStaffInfo)) return [];
    return allStaffInfo.filter(
      (staff) =>
        !Array.isArray(staff.shiftplans) || staff.shiftplans.length === 0
    );
  }, [allStaffInfo]);

  return (
    <>
      <div className="w-full h-full py-8 pb-12 flex items-center justify-center relative">
        <ListStaffOnDuty
          listStaffOnDuty={listStaffOnDuty}
          setShowListStaffRegisterForDuty={setShowListStaffRegisterForDuty}
        />

        <div className="absolute inset-x-0 bottom-0 h-12 bg-[#F0F1EF] bg-opacity-50 flex items-center justify-end">
          <Link
            to={Path.staffManagementList}
            className="button-size bg-blue-btn-primary"
          >
            スタッフ情報
          </Link>
        </div>
      </div>

      <ListStaffRegisterForDutyDialog
        open={showListStaffRegisterForDuty}
        handleClose={() => setShowListStaffRegisterForDuty(false)}
        listStaffRegisterForDuty={listStaffRegisterForDuty}
      />
    </>
  );
}

function StaffManagementTopPage() {
  const [fetchAllStaffState, setFetchAllStaffState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const [selectedDate, setSelectedDate] = useState(() => {
    const formatCurrentDate = dayjs().format(defaultDateFormat);
    return formatCurrentDate;
  });

  const loadAllStaff = useCallback(() => {
    if (!selectedDate) return;
    setFetchAllStaffState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllStaffWithShiftPlanByDate(selectedDate)
      .then((res) => {
        console.log("getAllStaffWithShiftPlanByDate RES: ", res);
        setFetchAllStaffState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchAllStaffState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [selectedDate]);
  useEffect(() => {
    loadAllStaff();
  }, [loadAllStaff]);

  const handleNextDate = () => {
    setSelectedDate((oldDate) => {
      if (!oldDate) return dayjs().format(defaultDateFormat);

      const oldDateObj = dayjs(oldDate, defaultDateFormat);
      const newDateObj = oldDateObj.add(1, "day");
      return newDateObj.format(defaultDateFormat);
    });
  };

  const handleBackToYesterday = () => {
    setSelectedDate((oldDate) => {
      if (!oldDate) return dayjs().format(defaultDateFormat);

      const oldDateObj = dayjs(oldDate, defaultDateFormat);
      const newDateObj = oldDateObj.subtract(1, "day");
      return newDateObj.format(defaultDateFormat);
    });
  };

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p>勤怠</p>}>
        <div className="w-full h-full pt-16 lg:pt-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">出勤スタッフ</h2>
            <div className="w-auto flex items-center justify-end">
              <Link
                to={Path.staffManagementCalendar}
                className="button-size bg-blue-btn-primary"
              >
                <FontAwesomeIcon icon={faCalendarDays} />
              </Link>
            </div>
          </div>

          <div className="w-full h-full pt-5">
            <div className="w-full h-full">
              <div className="w-full h-full rounded-xl border border-gray-400 relative">
                {/* Show and change date */}
                <div className="absolute w-auto h-8 flex items-center justify-center px-4 left-4 -top-4 bg-[#F0F1EF] bg-opacity-50 z-20">
                  <button
                    type="button"
                    onClick={handleBackToYesterday}
                    className="px-2"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <h3 className="text-xl leading-8 font-semibold mx-3">
                    {selectedDate}
                  </h3>
                  <button
                    type="button"
                    onClick={handleNextDate}
                    className="px-2"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>

                <div className="w-full h-full overflow-auto px-2 pb-2">
                  {fetchAllStaffState.isLoading ? <LoadingProgress /> : null}

                  {!fetchAllStaffState.isLoading && fetchAllStaffState.error ? (
                    <LoadInitError error={fetchAllStaffState.error} />
                  ) : null}
                  {!fetchAllStaffState.isLoading &&
                  !fetchAllStaffState.error ? (
                    Array.isArray(fetchAllStaffState.data) &&
                    fetchAllStaffState.data.length > 0 ? (
                      <StaffManagementTopPageContent
                        allStaffInfo={fetchAllStaffState.data}
                      />
                    ) : (
                      <NoData />
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementTopPage;

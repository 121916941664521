import React from "react";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function CreateCompleteDialog(props) {
  const {
    open,
    createCompleteTitle,
    createCompleteBtnLabel1,
    createCompleteAction1,
    createCompleteBtnLabel2,
    createCompleteAction2,
  } = props;

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={() => {}}
      aria-labelledby="responsive-dialog-title"
      classes={{ root: "bg-transparent" }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          paddingTop: "40px",
        },
      }}
      TransitionComponent={Fade}
    >
      <div className="w-full flex flex-col items-center pt-16 pb-8 px-8 bg-white rounded-3xl relative min-w-[360px]">
        <div className="absolute w-20 h-20 -top-10 left-1/2 -translate-x-1/2 rounded-full overflow-hidden bg-green-500 flex items-center justify-center">
          <FontAwesomeIcon icon={faCheck} size="3x" color="white" />
        </div>

        <h3 className="text-xl font-semibold mb-14">{createCompleteTitle}</h3>

        <div className="w-full flex items-center justify-center text-white text-base font-semibold gap-8">
          <div className="w-44 flex items-center justify-center">
            {createCompleteBtnLabel1 ? (
              <button
                onClick={() => createCompleteAction1 && createCompleteAction1()}
                type="button"
                className="button-size bg-gray-btn-secondary"
              >
                {createCompleteBtnLabel1}
              </button>
            ) : null}
          </div>
          <div className="w-44 flex items-center justify-center">
            {createCompleteBtnLabel2 ? (
              <button
                onClick={() => createCompleteAction2 && createCompleteAction2()}
                type="button"
                className="button-size w-full bg-gray-btn-secondary"
              >
                {createCompleteBtnLabel2}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default CreateCompleteDialog;

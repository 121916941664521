import React, { useEffect, useMemo, useState } from "react";
import { Grid, MenuItem, Tooltip } from "@mui/material";
import MainMenuItem from "app/components/MainMenuItem";
import CommonDialog from "../../../components/CommonDialog";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { formatPrice, showMenuItemPrice } from "../../../utils";
import { v4 } from "uuid";
import CommonPopoverMenu from "../../../components/commonUI/CommonPopoverMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import SelectServiceDialog from "../../../components/SelectServiceDialog";

function VisitMenuItem(props) {
  const { menuItemInfo, handleDeleteMenuItem } = props;
  const [showEditOptionEl, setShowEditOptionEl] = useState(null);

  return (
    <>
      <div className="w-full h-8 flex items-center justify-between pr-10 relative mb-2">
        <div className="w-32 h-7 flex items-center justify-center rounded bg-[#79716d] text-white">
          {menuItemInfo.name}
        </div>

        <div className="font-semibold">
          {formatPrice(menuItemInfo.priceTaxInc)}
        </div>

        <button
          type="button"
          onClick={(e) => setShowEditOptionEl(e.target)}
          className="w-7 h-7 rounded bg-[#79716d] flex items-center justify-center absolute top-1/2 right-0 -translate-y-1/2"
        >
          <FontAwesomeIcon icon={faEllipsis} color="white" />
        </button>
      </div>

      <CommonPopoverMenu
        open={!!showEditOptionEl}
        anchorEl={showEditOptionEl}
        onClose={() => setShowEditOptionEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleDeleteMenuItem(menuItemInfo);
            setShowEditOptionEl(null);
          }}
          disableRipple
        >
          <span className="text-red-500 mr-3">
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
          <span>消去</span>
        </MenuItem>
      </CommonPopoverMenu>
    </>
  );
}

function UpdateVisitMenuDialog(props) {
  const { visitMenusProps, setVisitMenusProps, open, handleClose } = props;

  const [visitMenus, setVisitMenus] = useState(
    Array.isArray(visitMenusProps) ? visitMenusProps : []
  );
  const [showSelectMenu, setShowSelectMenu] = useState("");

  useEffect(() => {
    setVisitMenus(Array.isArray(visitMenusProps) ? visitMenusProps : []);
  }, [visitMenusProps]);

  const totalVisitMenus = useMemo(() => {
    if (!Array.isArray(visitMenus)) return formatPrice(0);
    return formatPrice(
      visitMenus.reduce((sum, item) => sum + (item?.priceTaxInc || 0), 0)
    );
  }, [visitMenus]);

  const handleDeleteMenuItem = (menuItem) => {
    setVisitMenus((oldList) =>
      oldList.filter((item) => item.id !== menuItem.id)
    );
  };

  const handleSelectService = (service) => {
    setVisitMenus((oldList) => [
      ...oldList,
      {
        ...service,
        salonMenuId: service.id,
        id: v4(),
      },
    ]);
  };

  const handleConfirm = () => {
    if (Array.isArray(visitMenus) && visitMenus.length) {
      setVisitMenusProps(visitMenus);
      handleClose();
    }
  };

  return (
    <>
      <CommonDialog
        open={open}
        handleClose={handleClose}
        handleConfim={handleClose}
        title="施術内容と価格は下記であっえいますか？"
        actions={
          <div className="w-full flex items-center justify-end text-white text-base font-semibold">
            <button
              onClick={handleClose}
              type="button"
              className="button-size rounded bg-gray-btn-secondary mr-4 lg:mr-6"
            >
              戻る
            </button>

            <Tooltip
              title={
                Array.isArray(visitMenus) && visitMenus.length > 0
                  ? ""
                  : "サービスを選択してください"
              }
            >
              <button
                onClick={handleConfirm}
                type="button"
                className={`button-size rounded ${
                  Array.isArray(visitMenus) && visitMenus.length > 0
                    ? "bg-blue-btn-primary"
                    : "bg-gray-btn-secondary"
                }`}
              >
                進む
              </button>
            </Tooltip>
          </div>
        }
      >
        <div className="w-full h-auto min-h-[120px] max-h-[360px] pb-6">
          <div className="flex flex-wrap">
            <div className="w-full sm:w-7/12 h-auto">
              <div className="w-full h-auto flex flex-col">
                {visitMenus.map((item) => (
                  <VisitMenuItem
                    key={item.id}
                    menuItemInfo={item}
                    handleDeleteMenuItem={handleDeleteMenuItem}
                  />
                ))}

                <div className="w-full h-10 flex items-end justify-end">
                  <button
                    type="button"
                    onClick={() => setShowSelectMenu("all")}
                    className="button-size bg-blue-btn-primary gap-2"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>メニューを追加</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full sm:w-5/12 h-auto ">
              <div className="w-full h-auto flex flex-col items-center">
                <div className="w-32 h-7 flex items-center justify-center rounded bg-[#79716d] text-white mb-6">
                  総額
                </div>

                <div className="text-xl font-semibold">{totalVisitMenus}</div>
              </div>
            </div>
          </div>
        </div>
      </CommonDialog>

      <SelectServiceDialog
        menuType={showSelectMenu}
        handleClose={() => setShowSelectMenu("")}
        handleSelectService={handleSelectService}
      />
    </>
  );
}

// function PaymentMenuDialog(props) {
//   const { open, handleClose, visitMenus, setVisitMenus } = props;
//   const [showEditOptionEl, setShowEditOptionEl] = useState(null);

//   const totalVisitMenus = useMemo(() => {
//     if (!Array.isArray(visitMenus)) return formatPrice(0);
//     return formatPrice(
//       visitMenus.reduce((sum, item) => sum + (item?.priceTaxInc || 0), 0)
//     );
//   }, [visitMenus]);

//   const handleAddMenuItem = () => {};

//   const handleDeleteMenuItem = () => {};

//   return (
//     <>
//       <CommonDialog
//         open={open}
//         handleClose={handleClose}
//         handleConfim={handleClose}
//         title="施術内容と価格は下記であっえいますか？"
//       >
//         <div className="mt-4 w-full">
//           <Grid
//             container
//             rowSpacing={1}
//             columnSpacing={{ xs: 1, sm: 2, sm: 3 }}
//             className="flex justify-evenly "
//           >
//             <Grid item xs={6} className="text-center py-6">
//               {Array.isArray(visitMenus)
//                 ? visitMenus.map((visitMenuItem) => (
//                     <div
//                       key={visitMenuItem.id}
//                       className="flex justify-between"
//                     >
//                       <div className="w-1/2 bg-stone-500 text-white font-medium p-1 rounded-md mb-3 text-sm">
//                         {visitMenuItem.name}
//                       </div>
//                       <span>{showMenuItemPrice(visitMenuItem)}</span>
//                       <div>
//                         <button
//                           type="button"
//                           onClick={(e) => setShowEditOptionEl(e.target)}
//                           className="bg-stone-500 text-white font-medium rounded-md pb-1"
//                         >
//                           <MoreHorizIcon />
//                         </button>
//                       </div>
//                     </div>
//                   ))
//                 : null}
//             </Grid>
//             <Grid item xs={4} className="text-center">
//               <div className="bg-stone-500 text-white  font-medium p-1 rounded-md  mb-3">
//                 総額
//               </div>
//               <div>{totalVisitMenus}</div>
//             </Grid>
//           </Grid>
//         </div>
//       </CommonDialog>

//       <CommonPopoverMenu
//         open={!!showEditOptionEl}
//         anchorEl={showEditOptionEl}
//         onClose={() => setShowEditOptionEl(null)}
//       >
//         <MenuItem
//           onClick={() => {
//             handleDeleteMenuItem(serviceInfo);
//             setShowEditOptionEl(null);
//           }}
//           disableRipple
//         >
//           <span className="text-red-500 mr-3">
//             <FontAwesomeIcon icon={faTrashCan} />
//           </span>
//           <span>消去</span>
//         </MenuItem>
//       </CommonPopoverMenu>
//     </>
//   );
// }

function InputPaymentViewMenu(props) {
  const { visitMenus, setVisitMenus } = props;
  const [showPaymentMenuDialog, setShowPaymentMenuDialog] = useState(false);

  const handleClosePaymentMenuDialog = () => {
    setShowPaymentMenuDialog(false);
  };

  const handleOpenPaymentMenuDialog = () => {
    setShowPaymentMenuDialog(true);
  };

  return (
    <>
      <div className="w-full h-auto flex items-center flex-wrap">
        <div className="w-auto sm:w-5/12">
          <div className="flex flex-col items-end">
            <div className="bg-stone-500 text-white font-medium p-3 rounded-full flex justify-center mb-3 w-[160px]">
              施術項目の入力
            </div>
          </div>
        </div>
        <div className="w-auto sm:w-7/12 pl-4">
          <div className="flex gap-4 mb-3 ">
            <MainMenuItem
              itemTitle={<p className="text-xs font-medium">詳細へ</p>}
              onClick={handleOpenPaymentMenuDialog}
              borderSize={4}
              size={50}
              isActive={showPaymentMenuDialog}
            />
          </div>
        </div>
      </div>

      <UpdateVisitMenuDialog
        open={showPaymentMenuDialog}
        handleClose={handleClosePaymentMenuDialog}
        visitMenusProps={visitMenus}
        setVisitMenusProps={setVisitMenus}
      />
    </>
  );
}

export default InputPaymentViewMenu;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import ConfirmUpdateDialog from "app/components/CommonDialog/ConfirmUpdateDialog";
import LoadInitError from "app/components/commonUI/LoadInitError";
import Confirm from "app/components/commonUI/Confirm";
import NoData from "app/components/commonUI/NoData";
import { defaultDateFormat, jpDateFormat } from "app/constants";
import {
  getAllCustomerCounseling,
  addCounselingSheet,
  updateCounseling,
} from "app/services/api";
import Path from "app/route/Path";

function CounselingSheetForm(props) {
  const {
    counselingSheetToEdit,
    handleCancel,
    handleReloadCounselingSheet,
    customerId,
  } = props;
  const [memo, setMemo] = useState("");
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [complete, setComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (counselingSheetToEdit) {
      setMemo(counselingSheetToEdit.memo);
    } else {
      setMemo("");
    }
  }, [counselingSheetToEdit]);

  const handleSaveCounselingSheet = async () => {
    if (counselingSheetToEdit && counselingSheetToEdit.id)
      return await updateCounseling(counselingSheetToEdit.id, memo);
    return await addCounselingSheet(memo);
  };

  const completeBackToCustomerDetail = () => {
    navigate(Path.customerManagementDetail(customerId));
  };

  const completeBackToList = () => {
    handleReloadCounselingSheet();
    handleCancel();
  };

  return (
    <>
      <div className="w-full h-auto max-w-[880px] mx-auto relative max-h-full flex flex-wrap py-8">
        <div className="w-full h-8 flex items-center justify-start absolute inset-x-0 top-0">
          <h2 className="text-base lg:text-xl font-semibold">
            カウンセリングシート
          </h2>
        </div>
        <div className="w-full h-auto py-6">
          <div className="w-full h-full border border-gray-600 min-h-[360px] rounded-2xl">
            <textarea
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
              className="w-full h-full rounded-2xl px-4 py-3"
            />
          </div>
        </div>

        <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
          <button
            onClick={handleCancel}
            type="button"
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </button>
          <button
            onClick={() => {
              if (memo && memo.trim()) setShowDialogConfirm(true);
            }}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            完了
          </button>
        </div>
      </div>

      <ConfirmUpdateDialog
        open={memo && showDialogConfirm}
        handleClose={() => setShowDialogConfirm(false)}
        confirmTitle={
          counselingSheetToEdit
            ? "カウンセグシートを変更しますか？"
            : "カウンセグシートを登録しますか？"
        }
        confirmMessage=""
        updateCompleteTitle="カウンセグシートを変更しました"
        updateCompleteBtnLabel="終る"
        updateCompleteAction={() => {
          navigate(Path.home);
        }}
        updateCompleteBtnLabel2="お客様情報に戻る"
        updateCompleteAction2={() => {
          navigate(Path.customerManagementDetail(customerId));
        }}
        updateAction={handleSaveCounselingSheet}
        confirmCancleLabel="キャンセル"
        confirmLabel="変更"
      />

      <Confirm
        open={complete}
        message={
          counselingSheetToEdit
            ? "カウンセグシートを変更しました"
            : "カウンセグシートを登録しますか？"
        } // TODO-T: confirm correct message
        cancelTitle="お客様情報に戻る" // back to customer info detail
        handleCancel={completeBackToCustomerDetail}
        okTitle="終る"
        handleOk={completeBackToList}
      />
    </>
  );
}

function CounselingSheetHistoryItem(props) {
  const { counselingInfo, handleClick, handleClickEdit, isShowDetail } = props;
  return (
    <button
      type="button"
      onClick={() => handleClick(counselingInfo)}
      className={`w-full h-20 border mb-4 border-gray-600 rounded-2xl px-4 py-1.5 pt-6 relative flex flex-col justify-end hover:shadow-md transition-all ${
        isShowDetail ? "bg-slate-100" : ""
      }`}
    >
      <div className="absolute inset-x-0 top-0 h-7 flex items-end pt-1 px-4 font-semibold justify-between">
        <p>
          {moment(counselingInfo.counsellingDate, defaultDateFormat).format(
            jpDateFormat
          )}
        </p>

        <button
          onClick={(e) => {
            handleClickEdit(counselingInfo);
            e.preventDefault();
            e.stopPropagation();
          }}
          size="small"
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
      </div>
      <p className="w-full h-auto overflow-hidden text-sm font line-clamp-2 text-start">
        {counselingInfo.memo}
      </p>
    </button>
  );
}

function CounselingSheetHistory(props) {
  const {
    customerId,
    handleClickAdd,
    counselingList,
    handleClickCounselingItem,
    handleClickEditCounselingItem,
    detailCounselingSheet,
  } = props;

  const navigate = useNavigate();

  const backToDetail = () => {
    navigate(Path.customerManagementDetail(customerId));
  };

  return (
    <div className="w-full h-full max-w-[880px] mx-auto relative max-h-full flex flex-wrap py-12">
      <div className="w-full h-8 flex items-center justify-between absolute inset-x-0 top-0">
        <h2 className="text-base lg:text-xl font-semibold">
          カウンセリングシート
        </h2>

        <button
          type="button"
          className="button-size flex items-center justify-center gap-2 bg-[#6670c8]"
          onClick={handleClickAdd}
        >
          <NoteAddIcon className="text-white text-[16px]" />
          <span className="text-white">新規メモ入力</span>
        </button>
      </div>

      <div className="w-1/2 py-6 pr-4 pl-0 max-h-full">
        {Array.isArray(counselingList) && counselingList.length > 0 ? (
          <div className="w-full h-full border border-gray-600 min-h-[360px] rounded-2xl py-3 pt-12 relative">
            {detailCounselingSheet ? (
              <>
                <div className="absolute inset-x-0 top-0 h-7 flex items-end pt-1 px-4 font-semibold">
                  {moment(
                    detailCounselingSheet.counsellingDate,
                    defaultDateFormat
                  ).format(jpDateFormat)}
                </div>

                <div className="w-full h-full px-6 overflow-auto">
                  {detailCounselingSheet.memo}
                </div>
              </>
            ) : (
              <div>No Data</div>
            )}
          </div>
        ) : (
          <NoData />
        )}
      </div>

      <div className="w-1/2 p-6 pl-4 pr-0 flex flex-col h-full ">
        <div className="w-full h-full overflow-auto">
          <div className="flex flex-col">
            {counselingList.map((counseling) => (
              <CounselingSheetHistoryItem
                key={counseling.id}
                counselingInfo={counseling}
                handleClick={handleClickCounselingItem}
                handleClickEdit={handleClickEditCounselingItem}
                isShowDetail={counseling.id === detailCounselingSheet?.id}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          onClick={backToDetail}
          type="button"
          className="button-size bg-gray-btn-secondary"
        >
          戻る
        </button>
      </div>
    </div>
  );
}

function CustomerCouselingSheetContent(props) {
  const {
    showInputMode,
    customerId,
    detailCounselingSheet,
    couselingList,
    handleShowDetailCounseling,
    handleClickEditCounseling,
    handleClickAdd,
    backToList,
    loadAllCounselingSheet,
  } = props;
  if (!showInputMode)
    return (
      <CounselingSheetHistory
        customerId={customerId}
        counselingList={couselingList || []}
        detailCounselingSheet={detailCounselingSheet}
        handleClickCounselingItem={handleShowDetailCounseling}
        handleClickEditCounselingItem={handleClickEditCounseling}
        handleClickAdd={handleClickAdd}
      />
    );

  return (
    <CounselingSheetForm
      counselingSheetToEdit={detailCounselingSheet}
      handleCancel={backToList}
      handleReloadCounselingSheet={loadAllCounselingSheet}
      customerId={customerId}
    />
  );
}

function CustomerCouselingSheetPage() {
  const [showInputMode, setShowInputMode] = useState(false);
  const [fetchCounselingSheetState, setFetchCounselingSheetState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [detailCounselingSheet, setDetailCounselingSheet] = useState(null);

  const { customerId } = useParams();

  const loadAllCounselingSheet = () => {
    setFetchCounselingSheetState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllCustomerCounseling(customerId)
      .then((res) => {
        setFetchCounselingSheetState({
          isLoading: false,
          data: res.data,
          error: null,
        });
        if (Array.isArray(res.data) && res.data.length > 0) {
          const newDetailCounselingSheet = detailCounselingSheet?.id
            ? res.data.find((item) => item.id == detailCounselingSheet?.id)
            : null;
          if (newDetailCounselingSheet) {
            setDetailCounselingSheet(newDetailCounselingSheet);
          } else {
            setDetailCounselingSheet(res.data[0]);
          }
        } else {
          setDetailCounselingSheet(null);
        }
      })
      .catch((error) => {
        setFetchCounselingSheetState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadAllCounselingSheet();
  }, []);

  const handleShowDetailCounseling = (counseling) => {
    if (counseling.id === detailCounselingSheet.id) return;

    setDetailCounselingSheet(counseling);
  };

  const handleClickEditCounseling = (counseling) => {
    setDetailCounselingSheet(counseling);
    setShowInputMode(true);
  };

  const handleClickAdd = () => {
    setDetailCounselingSheet(null);
    setShowInputMode(true);
  };

  return (
    <DefaultLayout>
      <MainContentLayout>
        {fetchCounselingSheetState.isLoading ? <LoadingProgress /> : null}

        {!fetchCounselingSheetState.isLoading &&
        fetchCounselingSheetState.error ? (
          <LoadInitError error={fetchCounselingSheetState.error} />
        ) : null}

        {!fetchCounselingSheetState.isLoading &&
        !fetchCounselingSheetState.error &&
        fetchCounselingSheetState.data ? (
          <div className="w-full h-full flex items-center justify-center">
            <CustomerCouselingSheetContent
              showInputMode={showInputMode}
              customerId={customerId}
              detailCounselingSheet={detailCounselingSheet}
              couselingList={fetchCounselingSheetState.data}
              handleShowDetailCounseling={handleShowDetailCounseling}
              handleClickEditCounseling={handleClickEditCounseling}
              handleClickAdd={handleClickAdd}
              loadAllCounselingSheet={loadAllCounselingSheet}
              backToList={() => {
                if (
                  !detailCounselingSheet &&
                  Array.isArray(fetchCounselingSheetState.data) &&
                  fetchCounselingSheetState.data.length > 0
                ) {
                  setDetailCounselingSheet(fetchCounselingSheetState.data[0]);
                }
                setShowInputMode(false);
              }}
            />
          </div>
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CustomerCouselingSheetPage;

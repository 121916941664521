import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import Path from "app/route/Path";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import {
  deleteInventory,
  getInventoryAll,
  updateInventory,
} from "../../../services/api/18";
import InputField from "../../../components/commonUI/InputField";
import InputDateField from "../../../components/commonUI/InputDateField";
import moment from "moment";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ConfirmDeleteDialog from "../../../components/CommonDialog/ConfirmDeleteDialog";
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoData from "../../../components/commonUI/NoData";
import { defaultDateFormat } from "../../../constants";
import { toast } from "react-toastify";

function SearchInventoryFormPage(props) {
  const {
    name,
    setName,
    maker,
    setMaker,
    series,
    setSeries,
    productNumber,
    setProductNumber,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleSearch,
  } = props;

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>在庫</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">在庫履歴</h2>
          </div>
          <div className="w-full h-full max-w-[880px] mx-auto relative max-h-full flex flex-wrap">
            <div className="w-full h-full max-w-[880px] mx-auto relative max-h-full flex flex-wrap py-12 ">
              <div className="w-full h-full overflow-auto">
                <div className="w-full h-full rounded-lg border border-gray-400  mx-auto">
                  <div className="max-w-[680px] h-full border-gray-400 p-6 flex flex-col gap-4 mx-auto justify-center">
                    <div className="w-full h-auto flex items-start">
                      <div className="w-[114px] h-full flex items-start font-semibold ">
                        履歴日付
                      </div>
                      <div className="w-full flex gap-6 items-center">
                        <InputDateField
                          value={startDate}
                          setValue={(newValue) => setStartDate(newValue)}
                        />
                        <div className="font-bold text-2xl">~</div>
                        <InputDateField
                          value={endDate}
                          setValue={(newValue) => setEndDate(newValue)}
                        />
                      </div>
                    </div>
                    <InputField
                      label="品番"
                      name="productNumber"
                      values={productNumber}
                      type="text"
                      onChange={(value) => setProductNumber(value)}
                      labelClasses="!w-[100px]"
                    />
                    <InputField
                      label="メーカー"
                      name="maker"
                      values={maker}
                      type="text"
                      onChange={(newValue) => setMaker(newValue)}
                      labelClasses="!w-[100px]"
                    />

                    <InputField
                      label="シリーズ"
                      name="series"
                      values={series}
                      type="text"
                      onChange={(newValue) => setSeries(newValue)}
                      labelClasses="!w-[100px]"
                    />
                    <InputField
                      label="品名"
                      name="name"
                      values={name}
                      type="text"
                      onChange={(newValue) => setName(newValue)}
                      labelClasses="!w-[100px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.inventoryManagement}
              className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
            >
              戻る
            </Link>
            <button
              onClick={handleSearch}
              type="button"
              className="button-size bg-blue-btn-primary"
            >
              検索
            </button>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

function InventoryManagementSearchPage() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [maker, setMaker] = useState("");
  const [series, setSeries] = useState("");
  const [productNumber, setProductNumber] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [productItem, setProductItem] = useState({});
  const [showSearchResult, setShowSearchResult] = useState(false);

  const [fetchInventory, setFetchInventory] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const newStartDate =
    startDate === null
      ? ""
      : moment(startDate).tz("Asia/Tokyo").format(defaultDateFormat);
  const newEndDate =
    endDate === null
      ? ""
      : moment(endDate).tz("Asia/Tokyo").format(defaultDateFormat);

  const handleSearch = () => {
    setFetchInventory({
      isLoading: true,
      data: null,
      error: null,
    });
    getInventoryAll(
      name,
      maker,
      series,
      productNumber,
      newStartDate,
      newEndDate
    )
      .then((res) => {
        setFetchInventory({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInventory({
          isLoading: false,
          data: null,
          error: error,
        });
      });

    setShowSearchResult(true);
  };

  useEffect(() => {
    if (showSearchResult || load) {
      handleSearch();
    }
  }, [load, name, maker, series, productNumber, newStartDate, newEndDate]);

  const handleShowConfirmDelete = (productItem) => {
    setShowConfirmDelete(true);
    setProductItem(productItem);
  };
  const handleDeleteProductItem = (deleteData) => {
    deleteInventory(deleteData.product._id)
      .then((res) => {
        toast.success("削除成功");
        setLoad(!load);
        setShowConfirmDelete(false);
      })
      .catch((error) => {
        toast.error("削除が成功しない");
      });
  };
  const handleOpenUpdateInventory = (index, productItem) => {
    setProductItem(productItem);
    setOpen(!open);
  };
  const handleUpdateInventory = () => {
    updateInventory(updateData[0]?.id, updateData[0].inventory);
    setOpen(!open);
    setLoad(!load);
  };

  const handleChangeInventory = (index, value, id) => {
    const newUpdateData = [...updateData];
    newUpdateData[index] = {
      ...newUpdateData[index],
      inventory: Number(value),
      id: id,
    };
    setUpdateData(newUpdateData);
  };
  return (
    <div className="w-full !h-full">
      {showSearchResult && !fetchInventory.isLoading ? (
        <DefaultLayout>
          <MainContentLayout
            pageTitle={
              <>
                <p>在庫</p>
              </>
            }
          >
            <div className="w-full h-full pt-20 pb-8  lg:py-12 relative">
              <div className="w-full h-20 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
                <h2 className="w-24 text-base lg:text-xl font-semibold">
                  履歴一覧
                </h2>
              </div>
              <div className="w-full h-full py-3">
                <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
                  <div className="w-full h-full overflow-auto">
                    {fetchInventory.isLoading ? <LoadingProgress /> : null}

                    {!fetchInventory.isLoading && fetchInventory.error ? (
                      <LoadInitError error={fetchInventory.error} />
                    ) : null}
                    {!fetchInventory.isLoading && !fetchInventory.error ? (
                      Array.isArray(fetchInventory.data) &&
                      fetchInventory.data.length > 0 ? (
                        <>
                          <div className="w-full h-full relative overflow-auto min-w-[900px]">
                            {/* header */}
                            <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
                              <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
                                品番
                              </div>
                              <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
                                メーカー
                              </div>
                              <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
                                シリーズ
                              </div>
                              <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
                                品名
                              </div>
                              <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
                                在庫数
                              </div>
                              <div className="w-1/6 flex items-center justify-center shrink-0 truncate"></div>
                            </div>

                            {/* body */}
                            {fetchInventory.data.map((row, index) => (
                              <div className="w-full h-14 flex bg-[#F0F1EF] bg-opacity-50 border-b">
                                <div className="w-1/6 flex items-center justify-center">
                                  {row.product.productNumber}
                                </div>
                                <div className="w-1/6 flex items-center justify-center">
                                  {row.product.maker}
                                </div>
                                <div className="w-1/6 flex items-center justify-center">
                                  {row.product.series}
                                </div>
                                <div className="w-1/6 flex items-center justify-center">
                                  {row.product?.name}
                                </div>
                                <div className="w-1/6 flex items-center justify-center">
                                  {productItem._id === row._id && open ? (
                                    <input
                                      key={row._id}
                                      placeholder={`${row.inventory}`}
                                      value={updateData[index]?.inventory}
                                      onChange={(e) =>
                                        handleChangeInventory(
                                          index,
                                          e.currentTarget.value.codePointAtreplace(
                                            /[^0-9.]/g,
                                            ""
                                          ),
                                          row.product._id
                                        )
                                      }
                                      type="text"
                                      inputProps={{
                                        min: 0,
                                      }}
                                      className="w-20 p-2 border rounded-md"
                                    />
                                  ) : (
                                    updateData[index]?.inventory ||
                                    row.inventory
                                  )}
                                </div>
                                <div className="w-1/6 flex items-end justify-center">
                                  <Button
                                    onClick={() => {
                                      handleOpenUpdateInventory(index, row);
                                    }}
                                    className=" !text-black "
                                  >
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      className="!w-5 !h-5"
                                    />
                                  </Button>
                                  <Button
                                    onClick={() => handleShowConfirmDelete(row)}
                                    className="!text-red-500"
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      className="!w-5 !h-5"
                                    />
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <NoData />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
                <button
                  onClick={() => {
                    navigate(Path.inventoryManagement);
                  }}
                  className="button-size bg-gray-btn-secondary mr-4"
                >
                  戻る
                </button>
                {open && (
                  <button
                    onClick={handleUpdateInventory}
                    className="button-size bg-blue-btn-primary"
                    // disabled={open}
                  >
                    変更する
                  </button>
                )}
              </div>
            </div>

            <ConfirmDeleteDialog
              open={showConfirmDelete}
              handleClose={() => setShowConfirmDelete(false)}
              confirmTitle="下記の在庫を変削除してもよろしいですか"
              confirmMessage={`${productItem.product?.name}`}
              confirmTitleAgain="本当に削除してもよろしいでしょうか？"
              deleteCompleteTitle="在庫を削除しました"
              deleteCompleteBtnLabel="システム設定へ戻る"
              deleteCompleteAction={() => setShowConfirmDelete(false)}
              deleteAction={() => handleDeleteProductItem(productItem)}
              confirmCancleLabel="キャンセル"
              confirmCancleAgainLabel="キャンセル"
              confirmLabel="削除"
              confirmAgainLabel="削除"
            />
          </MainContentLayout>
        </DefaultLayout>
      ) : (
        <SearchInventoryFormPage
          name={name}
          setName={setName}
          maker={maker}
          setMaker={setMaker}
          series={series}
          setSeries={setSeries}
          productNumber={productNumber}
          setProductNumber={setProductNumber}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleSearch={handleSearch}
        />
      )}
    </div>
  );
}

export default InventoryManagementSearchPage;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fake_history_order_list } from "../../../../services/api/fake_services/fake_data";
import Path from "../../../../route/Path";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import MainContentLayout from "../../../../layouts/MainContentLayout";
import LoadingProgress from "../../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../../components/commonUI/LoadInitError";
import InputField from "../../../../components/commonUI/InputField";
import { getHistoryOfOrderingDetail } from "../../../../services/api/1C";
import moment from "moment";
import NoData from "../../../../components/commonUI/NoData";
import scalpDataClassABImage from "app/assets/images/Class_A_B.png";
import scalpDataClassCDImage from "app/assets/images/Class_C_D.png";
import scalpDataClassEFImage from "app/assets/images/Class_E_F.png";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

function HistoryOfOrderDetailByKitIdPage() {
  const customerNo = useParams();

  var isDetail = true;
  const [fetchHistoryOrderDetail, setFetchHistoryOrderDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const CLUSTER = {
    // A_CLASS : "CL1",
    // B_CLASS : "CL2",
    // C_CLASS : "CL3",
    // D_CLASS : "CL4",
    // E_CLASS : "CL5",
    // F_CLASS : "CL6",
    A_CLASS : "1",
    B_CLASS : "2",
    C_CLASS : "3",
    D_CLASS : "4",
    E_CLASS : "5",
    F_CLASS : "6",
  }
  
  const defaultRadarData = {
    labels: ['細胞面積', '多重剥離', 'カルボニル'],
    datasets: [
      {
        label: '# of Votes',
        data: [0, 0, 0],
        backgroundColor: 'rgba(157, 193, 226, 0.7)',
        // borderColor: 'rgba(255, 99, 132, 1)',
        // borderWidth: 1,
      },
    ],
  };

  const[radarData, setRadarData] = useState(defaultRadarData);
  const[scalpData, setScalpData] = useState(false);

  const loadHistoryOrderDetail = () => {
    setFetchHistoryOrderDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getHistoryOfOrderingDetail(customerNo.customerNo)
      .then((res) => {
        const found = res.data.find(function (item) {
          return item.scalpData.inspectionKitId == customerNo.inspectionKitId;
        });
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: found,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
  

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
    scales: {
      r:{
        min: 0,
        max: 10,
        beginAtZero: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        ticks: {
          
          showLabelBackdrop: false,
          //color: 'rgba(255, 99, 132, 1)',
          stepSize: 2,
        },
        pointLabels: {
          display: true,
          padding: 0,
          font: {
            size: 15
          },
        }
      },
    },
    
  };

  useEffect(() => {
    loadHistoryOrderDetail();
  }, []);

  // useEffect 会在组件渲染完成后执行
  useEffect(() => {
    // 在这里执行你想要的操作，可以访问 fetchHistoryOrderDetail 的最新值
    console.log('fetchHistoryOrderDetail changed:', fetchHistoryOrderDetail);

    // 例如，检查 isLoading 是否为 true，然后执行相应的操作
    if (fetchHistoryOrderDetail.isLoading) {
      // 执行你的操作...
      console.log('Loading...');
    } else {

      if (fetchHistoryOrderDetail.data?.scalpData?.factorCellArea == null) {
      } else if (fetchHistoryOrderDetail.data?.scalpData?.factorPeeling == null) {
      } else if (fetchHistoryOrderDetail.data?.scalpData?.factorCP == null) {
      } else {
        setRadarData( {
          labels: ['細胞面積', '多重剥離', 'カルボニル'],
          datasets: [
            {
              label: '# of Votes',
              data: [
                      Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorCellArea), 
                      Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorPeeling), 
                      Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorCP)
                    ],
              backgroundColor: 'rgba(157, 193, 226, 0.7)',
              // borderColor: 'rgba(255, 99, 132, 1)',
              // borderWidth: 1,
            },
          ],
        })

        setScalpData({
          factorCellArea: Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorCellArea),
          factorPeeling: Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorPeeling),
          factorCP: Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorCP),
          factorScore: Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorScore),
          factorCluster: Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorCluster),
        })
      }

      if (fetchHistoryOrderDetail.data?.scalpData?.analyseStatus == "analyse_not_started" || fetchHistoryOrderDetail.data?.scalpData?.analyseStatus == "analyse_in_progress") {
        setScalpData({})
      }
    }

    // 如果有其他依赖项，确保将其添加到 useEffect 的依赖项数组中
  }, [fetchHistoryOrderDetail]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>ラボ</p>
          </>
        }
      >
        <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
          <div className="w-full h-16 lg:h-12 flex items-center justify-center absolute inset-x-0 top-0 bg-transparent">
            <div className="flex flex-col items-center justify-center text-white">
              <h2 className="text-base lg:text-xl font-semibold">頭皮ラボ診断結果</h2>
              <div className="text-xs lg:text-sm font-semibold">Touhilab Diagnosis</div>
            </div>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 p-4">
              <div className="w-full h-full">
                {fetchHistoryOrderDetail.isLoading ? <LoadingProgress /> : null}

                {!fetchHistoryOrderDetail.isLoading &&
                fetchHistoryOrderDetail.error &&
                fetchHistoryOrderDetail.data?.inspectionKitId ? (
                  <LoadInitError error={fetchHistoryOrderDetail.error} />
                ) : null}
                {!fetchHistoryOrderDetail.isLoading &&
                !fetchHistoryOrderDetail.error ? (
                  <div className="lg:flex gap-6 h-full">
                    <div className="w-full lg:w-1/2 flex flex-col h-1/2 lg:h-full gap-6">
                      {/* <InputField
                        label="お客様No."
                        name="customerNo"
                        value={fetchHistoryOrderDetail.data?.customerNo}
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="お客様名"
                        name="customerName"
                        value={
                          fetchHistoryOrderDetail.data?.scalpData.customerName
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="キットID"
                        name="inspectionKitId"
                        value={
                          fetchHistoryOrderDetail.data?.scalpData
                            .inspectionKitId
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="発送時間"
                        name="shippingDateTime"
                        value={moment(
                          fetchHistoryOrderDetail.data?.scalpData?.deliverDate
                        ).format("YYYY-MM-DD HH:mm")}
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="状況"
                        name="situation"
                        value={
                          // fetchHistoryOrderDetail.data?.scalpData
                          //   ?.analyseStatus
                          //   ? fetchHistoryOrderDetail.data?.scalpData
                          //       ?.analyseStatus
                          //   : "pending"
                          fetchHistoryOrderDetail.data?.scalpData
                            .analyseStatus == "analyse_not_started"
                            ? "未分析"
                            : fetchHistoryOrderDetail.data?.scalpData
                                .analyseStatus == "analyse_in_progress"
                            ? "分析中"
                            : fetchHistoryOrderDetail.data?.scalpData
                                .analyseStatus == "analyse_completed"
                            ? "分析完了"
                            : ""
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      /> */}

                      {!scalpData? <LoadingProgress />: (
                        <>
                        
                        <div className="w-full h-auto">
                          <div className="w-full h-auto flex flex-col gap-6">
                            {/* <div className="w-full aspect-[3.74/1] bg-[#D8EAFF] flex items-center justify-center gap-3"> */}
                
                            <div className={`w-full aspect-[3.74/1] flex items-center justify-center gap-3
                                            ${
                                              scalpData?.factorCluster == CLUSTER.A_CLASS? "bg-[#D8EAFF]":
                                              scalpData?.factorCluster == CLUSTER.B_CLASS? "bg-[#D8FFEF]":
                                              scalpData?.factorCluster == CLUSTER.C_CLASS? "bg-[#F0FFD8]":
                                              scalpData?.factorCluster == CLUSTER.D_CLASS? "bg-[#FFF2D8]":
                                              scalpData?.factorCluster == CLUSTER.E_CLASS? "bg-[#FFDAD8]":
                                              scalpData?.factorCluster == CLUSTER.F_CLASS? "bg-[#E28E88]":
                                              "bg-black"
                                            }
                                            `}>
                              <div className="h-full flex items-center justify-center">
                                <img alt="no data" className="self-center h-2/3" src={
                                    scalpData?.factorCluster == CLUSTER.A_CLASS? scalpDataClassABImage:
                                    scalpData?.factorCluster == CLUSTER.B_CLASS? scalpDataClassABImage:
                                    scalpData?.factorCluster == CLUSTER.C_CLASS? scalpDataClassCDImage:
                                    scalpData?.factorCluster == CLUSTER.D_CLASS? scalpDataClassCDImage:
                                    scalpData?.factorCluster == CLUSTER.E_CLASS? scalpDataClassEFImage:
                                    scalpData?.factorCluster == CLUSTER.F_CLASS? scalpDataClassEFImage:
                                    scalpDataClassEFImage
                                } />
                              </div>
                              <div>
                                <div className="h-full text-[16vw] text-[#959798] font-mono">
                                  {
                                    scalpData?.factorCluster == CLUSTER.A_CLASS? "A":
                                    scalpData?.factorCluster == CLUSTER.B_CLASS? "B":
                                    scalpData?.factorCluster == CLUSTER.C_CLASS? "C":
                                    scalpData?.factorCluster == CLUSTER.D_CLASS? "D":
                                    scalpData?.factorCluster == CLUSTER.E_CLASS? "E":
                                    scalpData?.factorCluster == CLUSTER.F_CLASS? "F":
                                    "?"
                                  }
                                </div>
                              </div>
                              <div>
                                <div className="h-full text-[5vw] text-[#959798]">タイプ</div>
                              </div>
                            </div>
                            
                            
                          </div>
                        </div>

                        <div className="w-full h-auto">
                          <div className="w-full h-auto flex flex-col gap-6 items-center justify-center">
                            <div className="w-full h-auto flex flex-col items-center justify-center">
                              <div>
                                ◼️診断結果の詳細
                              </div>
                              <div className="w-full h-px bg-black text-transparent">
                              </div>
                            </div>

                            <div className="w-full h-auto grid grid-rows-3 grid-cols-12 grid-flow-col gap-0">
                              <div className="row-start-2 row-span-2 self-center">
                                <div className="text-center">
                                  {scalpData?.factorCellArea? scalpData.factorCellArea: "0"}
                                </div>
                              </div>
                              <div className="col-span-11 text-xs sm:text-sm flex justify-between ">
                                <span className="font-bold">細胞面積(階層細胞の大きさ)</span>
                                <span className="">ターンオーバーの指標</span>
                              </div>

                              <div className="row-span-2 col-span-11">
                                <div className="w-full h-full flex gap-1">

                                <div className="w-full h-full bg-[#AAD5FD]"></div>

                                {[...Array(Math.round(scalpData?.factorCellArea) || 0)].map((_, index) => (
                                  <div key={index} className="w-full h-full bg-[#AAD5FD]"></div>
                                ))}
                                {[...Array(10 - (Math.round(scalpData?.factorCellArea) || 0))].map((_, index) => (
                                  <div key={index} className="w-full h-full bg-[#D9D9D9]"></div>
                                ))}
                                </div>
                              </div>
                            </div>

                            <div className="w-full h-auto grid grid-rows-3 grid-cols-12 grid-flow-col gap-0">
                              <div className="row-start-2 row-span-2 self-center">
                                <div className="text-center">
                                {scalpData?.factorCP? scalpData.factorCP: "0"}
                                </div>
                              </div>
                              <div className="col-span-11 text-xs sm:text-sm flex justify-between ">
                                <span className="font-bold">カルボニルタンパク</span>
                                <span className="">頭皮酸化ストレスの指標</span>
                              </div>
                              <div className="row-span-2 col-span-11">
                                <div className="w-full h-full flex gap-1">

                                  <div className="w-full h-full bg-[#AAD5FD]"></div>

                                  {[...Array(Math.round(scalpData?.factorCP) || 0)].map((_, index) => (
                                    <div key={index} className="w-full h-full bg-[#AAD5FD]"></div>
                                  ))}
                                  {[...Array(10 - (Math.round(scalpData?.factorCP) || 0))].map((_, index) => (
                                    <div key={index} className="w-full h-full bg-[#D9D9D9]"></div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="w-full h-auto grid grid-rows-3 grid-cols-12 grid-flow-col gap-0">
                              <div className="row-start-2 row-span-2 self-center">
                                <div className="text-center">
                                {scalpData?.factorPeeling? scalpData.factorPeeling: "0"}
                                </div>
                              </div>
                              <div className="col-span-11 text-xs sm:text-sm flex justify-between ">
                                <span className="font-bold">多重剥離度(角層の重なり)</span>
                                <span className="">乾燥や肌荒れの指標</span>
                              </div>
                              <div className="row-span-2 col-span-11">
                                <div className="w-full h-full flex gap-1">
                                <div className="w-full h-full bg-[#AAD5FD]"></div>

                                {[...Array(Math.round(scalpData?.factorPeeling) || 0)].map((_, index) => (
                                  <div key={index} className="w-full h-full bg-[#AAD5FD]"></div>
                                ))}
                                {[...Array(10 - (Math.round(scalpData?.factorPeeling) || 0))].map((_, index) => (
                                  <div key={index} className="w-full h-full bg-[#D9D9D9]"></div>
                                ))}
                                </div>
                              </div>
                            </div>

                            <div className="w-full h-auto flex flex-col sm:flex-row items-center justify-start">
                              <div className="w-full sm:w-1/2 h-auto sm:mt-12">
                                <Radar className="" data={radarData? radarData: {}} options={chartOptions} 
                                  height={300}
                                />
                              </div>
                              <div className="w-full sm:w-1/2 h-auto flex flex-col items-center justify-center gap-6 sm:gap-3">
                                <div>
                                  ◼️総合ポイント
                                </div>
                                <div className="w-3/4 h-px bg-black text-transparent mb-1">
                                </div>
                                <div>
                                  <span className="text-[32px] font-extrabold">{scalpData?.factorScore? scalpData?.factorScore: "0"}</span>
                                  <span>/30pt</span>
                                </div>
                                <div className="w-3/4 h-px bg-black text-transparent mt-1">
                                </div>
                              </div>
                            </div>

                            <div className="font-extrabold self-start text-[20px] min-[400px]:text-[22px] min-[500px]:text-[28px] min-[630px]:text-[32px] mt-12 sm:mt-0">
                              TYPE
                            </div>

                            <div className="w-full h-auto flex flex-col gap-1">
                              <div className="w-full h-auto flex gap-1">
                                <div className="flex items-center justify-center w-2/12 text-center bg-[#D8EAFF]">
                                  <span className="text-[24px] min-[400px]:text-[26px] min-[500px]:text-[32px] min-[630px]:text-[40px]">
                                    A
                                  </span>
                                  <span className="text-[12px] min-[400px]:text-[14px] min-[500px]:text-[18px] min-[630px]:text-[22px]">
                                    タイプ
                                  </span>
                                </div>
                                <div className="text-xs min-[400px]:text-sm w-10/12 bg-[#D8EAFF] p-2">
                                  <p className="font-bold">健康な頭皮の状態</p>
                                  <p>美しい毛髪を育むことを優先した、アクティブなケアができる状態です。</p>
                                </div>
                              </div>
                              <div className="w-full h-auto flex gap-1">
                                <div className="flex items-center justify-center w-2/12 text-center bg-[#D8FFEF]">
                                  <span className="text-[24px] min-[400px]:text-[26px] min-[500px]:text-[32px] min-[630px]:text-[40px]">
                                    B
                                  </span>
                                  <span className="text-[12px] min-[400px]:text-[14px] min-[500px]:text-[18px] min-[630px]:text-[22px]">
                                    タイプ
                                  </span>
                                </div>
                                <div className="text-sm w-10/12 bg-[#D8FFEF] p-2">
                                  <p className="font-bold">ほぼ健康な頭皮の状態</p>
                                  <p>やや酸化していますが、美しい毛髪を育むことを優先した、アクティブなケアができる状態です。</p>
                                </div>
                              </div>
                              <div className="w-full h-auto flex gap-1">
                                <div className="flex items-center justify-center w-2/12 text-center bg-[#F0FFD8]">
                                  <span className="text-[24px] min-[400px]:text-[26px] min-[500px]:text-[32px] min-[630px]:text-[40px]">
                                    C
                                  </span>
                                  <span className="text-[12px] min-[400px]:text-[14px] min-[500px]:text-[18px] min-[630px]:text-[22px]">
                                    タイプ
                                  </span>
                                </div>
                                <div className="text-sm w-10/12 bg-[#F0FFD8] p-2">
                                  <p className="font-bold">頭皮がやや酸化した状態</p>
                                  <p>多重剥離(角層細胞の重なり)がとても多く、乾燥して荒れた状態といえます。</p>
                                </div>
                              </div>

                              <div className="w-full h-auto flex gap-1">
                                <div className="flex items-center justify-center w-2/12 text-center bg-[#FFF2D8]">
                                  <span className="text-[24px] min-[400px]:text-[26px] min-[500px]:text-[32px] min-[630px]:text-[40px]">
                                    D
                                  </span>
                                  <span className="text-[12px] min-[400px]:text-[14px] min-[500px]:text-[18px] min-[630px]:text-[22px]">
                                    タイプ
                                  </span>
                                </div>
                                <div className="text-sm w-10/12 bg-[#FFF2D8] p-2">
                                  <p className="font-bold">頭皮がひどく酸化し、皮脂が多い状態</p>
                                  <p>多重剥離(角層細胞の重なり)がとても多く、乾燥して荒れた状態といえます。また酸化染毛剤いよるダメージも懸念されます。</p>
                                </div>
                              </div>
                              <div className="w-full h-auto flex gap-1">
                                <div className="flex items-center justify-center w-2/12 text-center bg-[#FFDAD8]">
                                  <span className="text-[24px] min-[400px]:text-[26px] min-[500px]:text-[32px] min-[630px]:text-[40px]">
                                    E
                                  </span>
                                  <span className="text-[12px] min-[400px]:text-[14px] min-[500px]:text-[18px] min-[630px]:text-[22px]">
                                    タイプ
                                  </span>
                                </div>
                                <div className="text-sm w-10/12 bg-[#FFDAD8] p-2">
                                  <p className="font-bold">頭皮の生育サイクルが乱れた状態</p>
                                  <p>細胞面積がとても小さく、ターンオーバーが乱れた状態。頭皮がやや酸化した状態で多重剥離がやや多く、頭皮が荒れた状態といえます。</p>
                                </div>
                              </div>
                              <div className="w-full h-auto flex gap-1">
                                <div className="flex items-center justify-center w-2/12 text-center bg-[#E28E88]">
                                  <span className="text-[24px] min-[400px]:text-[26px] min-[500px]:text-[32px] min-[630px]:text-[40px]">
                                    F
                                  </span>
                                  <span className="text-[12px] min-[400px]:text-[14px] min-[500px]:text-[18px] min-[630px]:text-[22px]">
                                    タイプ
                                  </span>
                                </div>
                                <div className="text-sm w-10/12 bg-[#E28E88] p-2">
                                  <p className="font-bold">頭皮が危険な状態</p>
                                  <p>頭皮に問題があります。</p>
                                  <p>専門医の受診が必要な状態です。</p>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                        </>
                      )
                        
                      }
                      
                      
                      
                      {/* <div className="w-full h-1/2 lg:h-full p-4">
                        {fetchHistoryOrderDetail.data?.scalpData
                          ?.analyseStatus === "analyse_completed" ? (
                          <>
                            <p className="whitespace-nowrap px-6 py-4">
                              クラスター　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorCluster.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              細胞面積　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorCellArea.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              多重剥離　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorPeeling.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              CP　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorCP.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              総合　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorScore.toFixed(
                                1
                              )}
                            </p>
                          </>
                        ) : (
                          "まだ分析終わってない"
                        )}
                      </div> */}

                      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold">
                        <Link to={-1} className="button-size bg-gray-btn-secondary">
                          戻る
                        </Link>
                      </div>
                      
                    </div>

                  </div>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
          {/* <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link to={-1} className="button-size bg-gray-btn-secondary">
              戻る
            </Link>
          </div> */}
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default HistoryOfOrderDetailByKitIdPage;

import React, { useEffect, useMemo, useRef } from "react";
import { Avatar } from "@mui/material";
import { convertTimeToNumber, getNameFromFirstLast } from "app/utils";
import defaultAvatar from "app/assets/svg/default_avatar.svg";

const heightOfAnHourBox = 64;
const widthOfAStaffBox = 96;
const gapBetween2Staff = 16;

const timeListHeader = Array.from(new Array(24), (x, index) => index);

function ReservationItem(props) {
  const { reservationInfo } = props;

  const startTimeNumber = convertTimeToNumber(reservationInfo.startTime);
  const finishTimeNumber = convertTimeToNumber(reservationInfo.finishTime);

  return (
    <div
      className="flex flex-col items-center justify-center absolute z-40"
      style={{
        height: `${heightOfAnHourBox * (finishTimeNumber - startTimeNumber)}px`,
        width: `${widthOfAStaffBox}px`,
        padding: `0px ${gapBetween2Staff / 2}px`,
        top: `${heightOfAnHourBox * (startTimeNumber - timeListHeader[0])}px`,
        left: `0px`,
        cursor: "default",
      }}
    >
      <div className="w-full h-full bg-gray-100 border border-gray-300 rounded-md shadow-md text-center text-sm p-3">
        {reservationInfo.customerName}
      </div>
    </div>
  );
}

function NewReservationItem(props) {
  const {
    selectedStaffIndex,
    selectedTime,
    durationOfReservation,
    isValidReservation,
  } = props;

  const startTimeNumber = convertTimeToNumber(selectedTime);

  return (
    <div
      className="flex flex-col items-center justify-center absolute z-50 opacity-80"
      style={{
        height: `${heightOfAnHourBox * durationOfReservation}px`,
        width: `${widthOfAStaffBox}px`,
        padding: `0px ${gapBetween2Staff / 2}px`,
        top: `${heightOfAnHourBox * startTimeNumber}px`,
        left: `${widthOfAStaffBox * selectedStaffIndex}px`,
        cursor: "default",
      }}
      id="new-reservation-item"
    >
      <div
        className={`w-full h-full border rounded-md shadow-md text-center text-sm p-3 ${
          isValidReservation
            ? "bg-amber-100 border-amber-300"
            : "bg-red-200 border-red-500"
        }`}
      ></div>
    </div>
  );
}

function TableReservationRegister(props) {
  const {
    allStaffReservation,
    selectedStaff,
    selectedTime,
    handleSelectStaff,
    handleSelectTime,
    durationOfReservation,
    isValidReservation,
  } = props;

  const staffHeaderRef = useRef(null);
  const timeHeaderRef = useRef(null);

  const handleScroll = (event) => {
    if (staffHeaderRef.current) {
      staffHeaderRef.current.scrollTo(event.currentTarget.scrollLeft, 0);
    }

    if (timeHeaderRef) {
      timeHeaderRef.current.scrollTo(0, event.currentTarget.scrollTop);
    }
  };

  useEffect(() => {
    const newReservationEl = document.getElementById("new-reservation-item");
    const reservationBoxContent = document.getElementById(
      "reservation-box-content"
    );
    if (newReservationEl && reservationBoxContent) {
      const scrollData = {
        top:
          newReservationEl.offsetTop > 100
            ? newReservationEl.offsetTop - 100
            : 0,
        left:
          newReservationEl.offsetLeft > 100
            ? newReservationEl.offsetLeft - 100
            : 0,
        behavior: "smooth",
      };
      reservationBoxContent.scrollTo(scrollData);
    }
  }, [selectedTime, selectedStaff]);

  const selectedStaffIndex = useMemo(() => {
    if (!Array.isArray(allStaffReservation) || !selectedStaff) return -1;
    const selectedStaffIndex = allStaffReservation.findIndex(
      (item) => item?.id === selectedStaff.id
    );
    return selectedStaffIndex;
  }, [allStaffReservation, selectedStaff]);

  return (
    <div className="w-full h-full pt-20 pl-20 relative">
      <div
        className="absolute left-0 top-20 bottom-0 w-20 h-[calc(100%-80px)] overflow-auto hidden-scrollbar py-2"
        ref={timeHeaderRef}
      >
        {timeListHeader.map((item) => (
          <div className="w-full h-16 relative">
            <button
              type="button"
              onClick={() => handleSelectTime(`${item}:00`)}
              className="absolute h-4 leading-4 text-sm font-bold -top-2 left-1/2 -translate-x-1/2 w-full text-center"
            >
              <span className="bg-[#F0F1EF] bg-opacity-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 whitespace-nowrap">
                {item + " : 00"}
              </span>
              <div className="w-ffull h-0.5 rounded-full bg-gray-400 left-0 top-1/2 -translate-y-1/2" />
            </button>
            <button
              type="button"
              onClick={() => handleSelectTime(`${item}:15`)}
              className="absolute h-4 leading-4 text-sm font-bold top-2 left-1/2 -translate-x-1/2 w-full text-center opacity-0 hover:opacity-100 transition-all"
            >
              <span className="bg-[#F0F1EF] bg-opacity-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 whitespace-nowrap">
                {item + " : 15"}
              </span>
              <div className="w-ffull h-[1px] rounded-full bg-gray-400 left-0 top-1/2 -translate-y-1/2" />
            </button>
            <button
              type="button"
              onClick={() => handleSelectTime(`${item}:30`)}
              className="absolute h-4 leading-4 text-sm font-bold top-6 left-1/2 -translate-x-1/2 w-full text-center opacity-0 hover:opacity-100 transition-all"
            >
              <span className="bg-[#F0F1EF] bg-opacity-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 whitespace-nowrap">
                {item + " : 30"}
              </span>
              <div className="w-ffull h-[1px] rounded-full bg-gray-400 left-0 top-1/2 -translate-y-1/2" />
            </button>
            <button
              type="button"
              onClick={() => handleSelectTime(`${item}:45`)}
              className="absolute h-4 leading-4 text-sm font-bold top-10 left-1/2 -translate-x-1/2 w-full text-center opacity-0 hover:opacity-100 transition-all"
            >
              <span className="bg-[#F0F1EF] bg-opacity-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 whitespace-nowrap">
                {item + " : 45"}
              </span>
              <div className="w-ffull h-[1px] rounded-full bg-gray-400 left-0 top-1/2 -translate-y-1/2" />
            </button>
          </div>
        ))}
      </div>

      <div
        className="absolute left-20 top-0 bottom-0 h-20 w-[calc(100%-80px)] overflow-auto flex flex-nowrap hidden-scrollbar px-2"
        ref={staffHeaderRef}
      >
        {allStaffReservation.map((staff) => (
          <button
            type="button"
            onClick={() =>
              handleSelectStaff({
                ...staff,
              })
            }
            className="w-24 h-full px-2 flex flex-col items-center justify-center shrink-0 hover:bg-slate-200 rounded-md"
            style={{
              height: "100%",
              width: `${widthOfAStaffBox}px`,
              padding: `0px ${gapBetween2Staff / 2}px`,
              paddingTop: "8px",
            }}
          >
            <Avatar
              sx={{ width: 40, height: 40, marginBottom: "8px" }}
              alt=""
              src={staff.imageUrl || defaultAvatar}
            />
            <span className="text-sm leading-3">
              {getNameFromFirstLast(staff)}
            </span>
          </button>
        ))}
      </div>

      <div
        className="w-full h-full overflow-auto p-2 small-scrollbar"
        onScroll={handleScroll}
        id="reservation-box-content"
      >
        <div
          className="relative"
          style={{
            height: `${heightOfAnHourBox * timeListHeader.length}px`,
            width: `${widthOfAStaffBox * allStaffReservation.length}px`,
          }}
        >
          {allStaffReservation.map((staff, staffIndex) => {
            return (
              <div
                className="absolute z-10 rounded-md  cursor-pointer overflow-hidden"
                style={{
                  height: `${heightOfAnHourBox * timeListHeader.length}px`,
                  width: `${widthOfAStaffBox}px`,
                  top: 0,
                  left: `${widthOfAStaffBox * staffIndex}px`,
                }}
              >
                {Array.isArray(staff.reservationList) &&
                staff.reservationList.length > 0
                  ? staff.reservationList.map((reservation) => (
                      <ReservationItem
                        key={reservation.id}
                        staffIndex={staffIndex}
                        reservationInfo={reservation}
                      />
                    ))
                  : null}

                {timeListHeader.map((item) => (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        handleSelectStaff({ ...staff });
                        handleSelectTime(`${item}:00`);
                      }}
                      className="w-full h-4 block opacity-0 hover:opacity-80 relative"
                    >
                      <div
                        className="top-0 left-0 absolute w-full px-2 -z-10"
                        style={{
                          height: `${
                            heightOfAnHourBox * durationOfReservation
                          }px`,
                        }}
                      >
                        <div className="w-full h-full rounded-md bg-slate-300" />
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleSelectStaff({ ...staff });
                        handleSelectTime(`${item}:15`);
                      }}
                      className="w-full h-4 block opacity-0 hover:opacity-80 relative"
                    >
                      <div
                        className="top-0 left-0 absolute w-full px-2 -z-10"
                        style={{
                          height: `${
                            heightOfAnHourBox * durationOfReservation
                          }px`,
                        }}
                      >
                        <div className="w-full h-full rounded-md bg-slate-300" />
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleSelectStaff({ ...staff });
                        handleSelectTime(`${item}:30`);
                      }}
                      className="w-full h-4 block opacity-0 hover:opacity-80 relative"
                    >
                      <div
                        className="top-0 left-0 absolute w-full px-2 -z-10"
                        style={{
                          height: `${
                            heightOfAnHourBox * durationOfReservation
                          }px`,
                        }}
                      >
                        <div className="w-full h-full rounded-md bg-slate-300" />
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleSelectStaff({ ...staff });
                        handleSelectTime(`${item}:45`);
                      }}
                      className="w-full h-4 block opacity-0 hover:opacity-80 relative"
                    >
                      <div
                        className="top-0 left-0 absolute w-full px-2 -z-10"
                        style={{
                          height: `${
                            heightOfAnHourBox * durationOfReservation
                          }px`,
                        }}
                      >
                        <div className="w-full h-full rounded-md bg-slate-300" />
                      </div>
                    </button>
                  </>
                ))}
              </div>
            );
          })}

          {allStaffReservation.map((staff, staffIndex) => {
            if (staff.availableShiftPlan) {
              const startAvailableNumber = convertTimeToNumber(
                staff.availableShiftPlan.startTime
              );
              const endAvailableNumber = convertTimeToNumber(
                staff.availableShiftPlan.finishTime
              );

              return (
                <div
                  className="absolute -z-[1] rounded-md bg-green-50 cursor-pointer overflow-hidden border border-green-500"
                  style={{
                    height: `${
                      (endAvailableNumber - startAvailableNumber) *
                      heightOfAnHourBox
                    }px`,
                    width: `${widthOfAStaffBox}px`,
                    top: `${heightOfAnHourBox * startAvailableNumber}px`,
                    left: `${widthOfAStaffBox * staffIndex}px`,
                  }}
                ></div>
              );
            }

            return null;
          })}

          {selectedStaffIndex >= 0 && selectedTime ? (
            <NewReservationItem
              selectedStaffIndex={selectedStaffIndex}
              selectedTime={selectedTime}
              durationOfReservation={durationOfReservation}
              isValidReservation={isValidReservation}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TableReservationRegister;

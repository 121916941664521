import React, { useCallback, useEffect } from "react";

const numberButtons = [
  {
    key: 7,
    label: "7",
  },
  {
    key: 8,
    label: "8",
  },
  {
    key: 9,
    label: "9",
  },
  {
    key: 4,
    label: "4",
  },
  {
    key: 5,
    label: "5",
  },
  {
    key: 6,
    label: "6",
  },
  {
    key: 1,
    label: "1",
  },
  {
    key: 2,
    label: "2",
  },
  {
    key: 3,
    label: "3",
  },
  {
    key: 0,
    label: "0",
  },
  {
    key: "00",
    label: "00",
  },
  {
    key: "",
    label: "",
  },
];

function InputNumberVirtualKeyBoard(props) {
  const { value, handleChange } = props;

  const handleClickButton = useCallback(
    (key) => {
      if (typeof key === "number") {
        const newValue = value * 10 + key;
        handleChange(newValue);
      } else if (key === "00") {
        const newValue = value * 100;
        handleChange(newValue);
      } else if (key === "x") {
        const newValue = Math.floor(value / 10);
        handleChange(newValue);
      } else if (key === "c") {
        const newValue = 0;
        handleChange(newValue);
      }
    },
    [value, handleChange]
  );

  const handleKeyPress = useCallback(
    (ev) => {
      ev.preventDefault();

      if (ev.code.startsWith("Digit")) {
        handleClickButton(Number(ev.key));
      } else if (ev.key === "Escape") {
        handleClickButton("c");
      } else if (ev.key === "Backspace") {
        handleClickButton("x");
      }
    },
    [handleClickButton]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  return (
    <div className="flex gap-1">
      <div className="grid grid-cols-3 bg-slate-400 border border-gray-400">
        {numberButtons.map((item) => (
          <button
            type="button"
            className="w-16 h-11 bg-[#F0F1EF] bg-opacity-50 border border-gray-400 hover:bg-slate-100 flex items-center justify-center text-lg font-semibold"
            onClick={() => handleClickButton(item.key)}
          >
            {item.label}
          </button>
        ))}
      </div>
      <div className="">
        <div className="grid grid-cols-1 gap-y-[2px]">
          <div className="w-16 h-11 px-0 ">
            <button
              type="button"
              onClick={() => handleClickButton("x")}
              className="w-full h-full border-2 border-gray-400 rounded-sm text-lg font-semibold hover:bg-slate-100"
            >
              X
            </button>
          </div>
          <div className="w-16 h-11 px-0 ">
            <button
              type="button"
              onClick={() => handleClickButton("c")}
              className="w-full h-full border-2 border-gray-400 rounded-sm text-lg font-semibold hover:bg-slate-100"
            >
              C
            </button>
          </div>
          <div className="w-16 h-[88px] px-0 ">
            <button className="w-full h-full border-2 border-gray-400 rounded-sm text-lg font-semibold hover:bg-slate-100">
              確定
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InputNumberVirtualKeyBoard;

import React, { useMemo, useState } from "react";
import MainContentLayout from "../../../layouts/MainContentLayout";
import DefaultLayout from "../../../layouts/DefaultLayout";
import Path from "../../../route/Path";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Tooltip } from "@mui/material";
import { postPrintData } from "../../../services/api";
import { testData } from "../../../constants/testPrinter";
import { toast } from "react-toastify";
import {
  addPrinter,
  removePrinter,
  setSelectedPrinter,
} from "../../../redux/printersReducer";

function AddPrinterDialogContent(props) {
  const { handleClose } = props;
  const [printerUrl, setPrinterUrl] = useState("");
  const [checkPrinterSuccess, setCheckPrinterSuccess] = useState(false);

  const correctUrl = useMemo(() => {
    return printerUrl;
  }, [printerUrl]);
  const dispatch = useDispatch();

  const handleCheckPrinter = () => {
    if (!correctUrl) return;

    postPrintData(printerUrl, testData)
      .then((res) => {
        toast.success("プリンターが利用可能");
        setCheckPrinterSuccess(true);
      })
      .catch((error) => {
        toast.error("プリンターが使用できません!");
        setCheckPrinterSuccess(false);
      });
  };

  const handleAddPrinter = () => {
    if (!checkPrinterSuccess) return;
    dispatch(addPrinter(printerUrl));
    handleClose();
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto mb-8">
        <input
          value={printerUrl}
          onChange={(e) => {
            setPrinterUrl(e.target.value);
            setCheckPrinterSuccess(false);
          }}
          type="url"
          placeholder="https://..."
          className="w-full h-10 rounded-md border border-gray-400 text-lg py-1.5 px-4"
        />
      </div>

      <div className="w-full h-auto flex items-center justify-center gap-8">
        <Tooltip
          title={correctUrl ? "" : "正しいプリンターの URL を入力してください"}
        >
          <button
            type="button"
            className={`button-size ${
              !correctUrl ? "bg-gray-btn-secondary" : "bg-blue-btn-primary"
            }`}
            onClick={handleCheckPrinter}
          >
            テスト印刷
          </button>
        </Tooltip>

        <Tooltip
          title={
            checkPrinterSuccess
              ? ""
              : "追加する前にプリンターを確認してください"
          }
        >
          <button
            type="button"
            className={`button-size ${
              !checkPrinterSuccess
                ? "bg-gray-btn-secondary"
                : "bg-blue-btn-primary"
            }`}
            onClick={handleAddPrinter}
          >
            追加
          </button>
        </Tooltip>
      </div>
    </div>
  );
}

function AddPrinterDialog(props) {
  const { open, handleClose } = props;
  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "12px",
        },
      }}
    >
      <div className="min-w-[320px] px-6 py-12">
        <AddPrinterDialogContent handleClose={handleClose} />
      </div>
    </Dialog>
  );
}

function ConnectToDevice() {
  const navigate = useNavigate();
  const [showAddPrinterDialog, setShowAddPrinterDialog] = useState(false);

  const dispatch = useDispatch();
  const allPrinters = useSelector((state) => state.printers.allPrinters);
  const selectedPrinter = useSelector(
    (state) => state.printers.selectedPrinter
  );

  const handleSelectPrinter = (printerUrl) => {
    dispatch(setSelectedPrinter(printerUrl));
  };

  const handleTestPrinter = (printerUrl) => {
    postPrintData(printerUrl, testData)
      .then((res) => {
        toast.success("プリンターが利用可能");
      })
      .catch((error) => {
        toast.error("プリンターが使用できません!");
      });
  };

  const handleDeletePrinter = (printerUrl) => {
    dispatch(removePrinter(printerUrl));
    if (printerUrl === selectedPrinter) {
      dispatch(setSelectedPrinter(""));
    }
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>システム</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">レジ認証</h2>

            <div className="flex items-center justify-end">
              {selectedPrinter ? (
                <button
                  type="button"
                  onClick={() => dispatch(setSelectedPrinter(""))}
                  className="button-size bg-blue-btn-primary mr-4 lg:mr-6"
                >
                  既定のプリンターを使用する
                </button>
              ) : null}

              <button
                type="button"
                onClick={() => {
                  setShowAddPrinterDialog(true);
                }}
                className="button-size bg-blue-btn-primary"
              >
                プリンターを追加
              </button>
            </div>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-20 flex flex-col justify-center items-center gap-6 overflow-auto border border-gray-400 rounded-2xl">
              {selectedPrinter ? (
                <div className="w-full h-auto overflow-hidden px-2">
                  <p className="w-full h-auto text-center text-base md:text-xl lg:text-2xl font-semibold">
                    接続状態：
                  </p>
                  <div className="w-full h-auto overflow-auto small-scrollbar text-base md:text-xl lg:text-2xl text-center">
                    <p>{selectedPrinter}</p>
                  </div>
                </div>
              ) : (
                <div className="text-2xl font-bold">
                  接続状態：デバイスのデフォルトのプリンターを使用する
                </div>
              )}
            </div>
            <div className="w-full h-[calc(100%-80px)] pt-5">
              <div className="w-full h-full pt-14 relative">
                <div className="absolute w-full h-12 px-4 rounded mb-2 inset-x-0 top-0 bg-slate-700 text-white flex items-center">
                  <div className="w-12 shrink-0">#</div>

                  <div className="w-full flex-1 text-center">Printer URL</div>

                  <div className="w-auto shrink-0 flex items-center justify-end">
                    Actions
                  </div>
                </div>

                <div className="w-full h-full overflow-auto">
                  {Array.isArray(allPrinters) && allPrinters.length > 0 ? (
                    <>
                      {allPrinters.map((printer, index) => (
                        <div
                          key={printer}
                          className="w-full h-16 md:h-12 px-4 relative rounded mb-2 flex items-center bg-slate-100 flex-wrap md:flex-nowrap"
                        >
                          <div className="w-12 shrink-0">{index + 1}</div>

                          <div className="w-full flex-1 px-6 truncate">
                            {printer}
                          </div>

                          <div className="w-full md:w-auto shrink-0 flex items-center justify-end">
                            <button
                              type="button"
                              onClick={() => handleTestPrinter(printer)}
                              className="button-size bg-blue-btn-primary mr-4"
                            >
                              テスト印刷
                            </button>
                            <button
                              type="button"
                              onClick={() => handleSelectPrinter(printer)}
                              className="button-size bg-blue-btn-primary mr-4"
                            >
                              選択
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDeletePrinter(printer)}
                              className="button-size bg-blue-btn-primary text-red-400"
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <button
                        type="button"
                        onClick={() => {
                          setShowAddPrinterDialog(true);
                        }}
                        className="button-size bg-blue-btn-primary"
                      >
                        プリンターを追加
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() => navigate(Path.systemManagement)}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
          </div>
        </div>

        <AddPrinterDialog
          open={showAddPrinterDialog}
          handleClose={() => setShowAddPrinterDialog(false)}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ConnectToDevice;

import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function InputAnalyze() {
  return (
    <div className="w-full h-full rounded-lg border border-gray-400 bg-slate-200 relative flex flex-col justify-end pb-6">
      <button
        type="button"
        className="absolute top-4 left-4 w-24 h-24 rounded-md flex items-center justify-center bg-slate-500"
      >
        <FontAwesomeIcon icon={faAdd} size="4x" color="white" />
      </button>
      <p className="text-2xl font-semibold text-center">入力する</p>
    </div>
  );
}

export default InputAnalyze;

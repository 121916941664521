import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Path from "../../../route/Path";
import moment from "moment";

export default function TableHistoryOrdering(props) {
  const navigate = useNavigate();
  const { allHistoryOrdering } = props;

  return (
    <div className="w-full h-full relative overflow-auto min-w-[900px]">
      {/* header */}
      <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          検体No.
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          お客様No.
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          お客様名
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          発送日時
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          発送状況
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate"></div>
      </div>

      {/* body */}
      {allHistoryOrdering.map((row, index) => (
        <div className="w-full h-14 flex bg-[#F0F1EF] bg-opacity-50 border-b">
          <div className="w-1/6 flex items-center justify-center">
            {row.scalpData?.inspectionKitId === ""
              ? "-"
              : row.scalpData?.inspectionKitId}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.scalpData?.customerNo}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.scalpData?.customerName}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.scalpData.deliverDate !== undefined
              ? moment(row.scalpData.deliverDate).format("YYYY-MM-DD HH:mm")
              : "-"}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.scalpData?.deliverDate
              ? "発送した"
              : "pending"}
          </div>
          <div className="w-1/6 flex items-end justify-center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(
                  Path.historyOfOrderingDetail(row.scalpData?.customerNo)
                );
              }}
            >
              詳細
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

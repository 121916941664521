import React from "react";
import Layout from "app/components/common/Layout";
import Image from "app/components/Image";

function ProductOrder(props) {
  const { children } = props;
  const productItem = children;

  return (
    <Layout.Container>
      <Layout.Row>
        <Layout.Col>
          <Image
            src={productItem.imageUrl}
            alt={productItem.name}
          />
        </Layout.Col>
        <Layout.Col grow={true}>
          <p className="font-bold text-xl">商品ナンバー : {productItem.productNumber}</p>
          <p className="font-bold text-xl">商品名 : {productItem.name}</p>
          <p className="font-bold text-gray-400">カテゴリー : {productItem.category}</p>
          <p className="font-bold">価格 : {productItem.price || 0}円</p>
          <p className="font-bold">商品詳細 : {productItem.description}</p>
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  );
}

export default ProductOrder;

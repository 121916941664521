import {
  fake_customer_info,
  fake_category_with_service,
  fake_revervation_info,
  fake_counseling_sheet_list,
  fake_visit_history_list,
  fake_reservation_list,
  fake_introduction_of_scalp_lap,
  all_services,
  fake_history_order_list,
  fake_kits_of_scalp_lap,
  fake_menu_list,
  fake_history_cashier_list,
  fake_total_sales_today,
  fake_inventory_list,
  fake_history_dms_list,
} from "./fake_data";

export const checkScalpLabMember = (member_id) => {
  console.log("customerId: ", member_id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const random = Math.random() > 0.5;
      // if (random > 0.6) {
      //   resolve({
      //     data: {
      //       customerId: member_id,
      //       reservationId: "fake_reservationId",
      //     },
      //   });
      // } else if (random > 0.4) {
      //   resolve({
      //     data: {
      //       customerId: member_id,
      //       reservationId: "",
      //     },
      //   });
      // } else {
      //   resolve({
      //     data: {
      //       customerId: "",
      //       reservationId: "",
      //     },
      //   });
      // }
      resolve({
        data: {
          member_id: member_id,
          reservationId: "",
        },
      });
    }, 1000);
  });
};

export const getCustomerInfo = (customerId) => {
  console.log("customerId: ", customerId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: fake_customer_info });
    }, 1000);
  });
};

export const updateCustomerInfo = (customerId, customerInfo) => {
  console.log("updateCustomerInfo customerId: ", customerId);
  console.log("updateCustomerInfo customerInfo: ", customerInfo);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: customerId,
          ...customerInfo,
        },
      });
    }, 1000);
  });
};

export const deleteCustomer = (customerId) => {
  console.log("deleteCustomerInfo: ", customerId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          isDelete: true,
        },
      });
    }, 1000);
  });
};

export const getCustomerBasicInfo = (customerId) => {
  console.log("customerId: ", customerId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: fake_customer_info });
    }, 1000);
  });
};

export const updateCustomerBasicInfo = (customerId, newCustomerBasicInfo) => {
  console.log("customerId: ", customerId);
  console.log("newCustomerBasicInfo: ", newCustomerBasicInfo);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: fake_customer_info });
    }, 1000);
  });
};

export const searchAllCustomer = (searchConditions) => {
  console.log("searchAllCustomer searchConditions: ", searchConditions);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: [
          fake_customer_info,
          { ...fake_customer_info, id: 2 },
          { ...fake_customer_info, id: 3 },
          { ...fake_customer_info, id: 4 },
          { ...fake_customer_info, id: 5 },
          { ...fake_customer_info, id: 6 },
        ],
      });
    }, 1000);
  });
};

export const getReservationInfo = (reservationId) => {
  console.log("reservationId: ", reservationId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: fake_revervation_info });
    }, 1000);
  });
};

export const getAllCategory = () => {
  console.log("getAllCategory");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_category_with_service.map((item) => ({
          id: item.id,
          title: item.title,
        })),
      });
    }, 1000);
  });
};

export const getListServiceCategory = (category_id) => {
  console.log("category_id: ", category_id);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const categoryObj = fake_category_with_service.find(
        (item) => item.id === category_id
      );
      if (categoryObj) {
        resolve({ data: categoryObj.services });
      } else {
        reject("404");
      }
    }, 1000);
  });
};

export const getSalonMenu = (menuType) => {
  console.log("getSalonMenu: menuType: ", menuType);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.log("menuType", menuType);
      console.log("all_services", all_services);
      const serviceList = all_services.filter(
        (item) => item.menuType === menuType
      );
      resolve({ data: serviceList });
    }, 1000);
  });
};

export const getAllCounselingSheet = () => {
  console.log("getAllCounselingSheet");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_counseling_sheet_list,
      });
    }, 1000);
  });
};

export const addCounselingSheet = (counselingSheetContent) => {
  console.log("counselingSheetContent: ", counselingSheetContent);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: Math.round(Math.random() * 1000),
          content: counselingSheetContent,
        },
      });
    }, 1000);
  });
};

export const editCounselingSheet = (id, counselingSheetContent) => {
  console.log("editCounselingSheet id: ", id);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: id,
          content: counselingSheetContent,
        },
      });
    }, 1000);
  });
};

export const addNewCustomer = (customerInfo) => {
  console.log("customerInfo: ", customerInfo);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: Math.round(Math.random() * 1000),
          ...customerInfo,
        },
      });
    }, 1000);
  });
};

export const addNewReservation = (reservationInfo) => {
  console.log("reservationInfo: ", reservationInfo);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          id: Math.round(Math.random() * 1000),
          ...reservationInfo,
        },
      });
    }, 1000);
  });
};

export const getAllCustomerVisitHistory = (customerId) => {
  console.log("getAllCustomerVisitHistory: ", customerId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_visit_history_list,
      });
    }, 1000);
  });
};

export const getDetailCustomerVisitHistory = (visitHistoryId) => {
  console.log("getDetailCustomerVisitHistory: ", visitHistoryId);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_visit_history_list[0],
      });
    }, 1000);
  });
};

export const getAllReservationToDay = () => {
  console.log("getAllReservationToDay: ");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_reservation_list,
      });
    }, 1000);
  });
};

export const getIntroductionOfScalpLab = () => {
  console.log("getIntroductionOfScalpLab: ");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_introduction_of_scalp_lap,
      });
    }, 1000);
  });
};

export const getAllHistoryOfOrdering = () => {
  console.log("getAllHistoryOfOrdering: ");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_history_order_list,
      });
    }, 1000);
  });
};

export const getAllKit = () => {
  console.log("getAllKit: ");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_kits_of_scalp_lap,
      });
    }, 1000);
  });
};

export const getAllMenu = () => {
  console.log("getAllMenu: ");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_menu_list,
      });
    }, 1000);
  });
};

export const getAllHistoryCashier = () => {
  console.log("getAllHistoryCashier: ");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_history_cashier_list,
      });
    }, 1000);
  });
};

export const getTotalSalesToday = () => {
  console.log("getAllKit: ");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_total_sales_today,
      });
    }, 1000);
  });
};

export const getAllInventory = () => {
  console.log("getAllInventory: ");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_inventory_list,
      });
    }, 1000);
  });
};

export const getAllHistoryDms = () => {
  console.log("getAllHistoryDms: ");

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: fake_history_dms_list,
      });
    }, 1000);
  });
};

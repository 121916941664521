import React, { useEffect, useMemo, useState } from "react";
import CommonDialog from "app/components/CommonDialog";
import { CircularProgress, Radio } from "@mui/material";
import { getAllSalonMenus } from "app/services/api";
import { formatPrice } from "../../utils";
import { menu_category } from "../../constants";

function SelectServiceDialogContent(props) {
  const { menuType, selectedService, setselectedService } = props;

  const [allCategoryService, setAllCategoryService] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if(!menuType) return;

    getAllSalonMenus(menuType === "all" ? undefined : menuType)
      .then((res) => setAllCategoryService(res.data))
      .catch((error) => {
        console.log("error: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [menuType]);

  const handleClickSelect = (service) => {
    setselectedService(service);
  };

  if (isLoading)
    return (
      <div className="w-full h-auto min-h-[160px] flex items-center justify-center">
        <CircularProgress size={40} />
      </div>
    );

  if (allCategoryService.length === 0)
    return (
      <div className="w-full h-auto min-h-[160px] flex items-center justify-center">
        No service!
      </div>
    );

  return (
    <div className="pb-2">
      <div className="w-full max-h-[400px] overflow-auto">
        {allCategoryService.map((service) => (
          <div
            className={`w-full h-12 flex items-center justify-between pl-14 pr-4 relative hover:bg-slate-100 rounded-md mb-1 ${
              selectedService?.id === service.id ? "!bg-slate-200" : ""
            }`}
          >
            <div className="w-10 h-10 flex items-center justify-center overflow-hidden absolute top-1 left-2">
              <Radio
                onChange={() => handleClickSelect(service)}
                checked={selectedService?.id === service.id}
              />
            </div>
            <div className="w-5/12 flex items-center truncate">
              {service.name}
            </div>
            <div className="w-3/12 flex items-center justify-end">
              {service.durationString}
            </div>
            <div className="w-4/12 flex items-center justify-end">
              {formatPrice(service.priceTaxExc)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function SelectServiceDialog(props) {
  const { menuType, handleClose, handleSelectService } = props;
  const [selectedService, setselectedService] = useState(null);

  useEffect(() => {
    setselectedService(null);
  }, [menuType]);

  const title = useMemo(() => {
    if (!menuType || menuType === "all")
      return "利用するサービスを選択してください";

    const menuObj = menu_category.find((item) => item.key === menuType);

    return menuObj?.dialogTitle || "利用するサービスを選択してください";
  }, [menuType]);

  return (
    <CommonDialog
      open={!!menuType}
      handleClose={handleClose}
      title={title}
      actions={
        <div className="w-full flex items-center justify-end text-white text-base font-semibold">
          <button
            onClick={() => {
              if (selectedService) {
                handleSelectService(selectedService);
                handleClose();
              }
            }}
            type="button"
            className={`button-size ${
              !selectedService
                ? "bg-gray-btn-secondary cursor-default"
                : "bg-blue-btn-primary cursor-pointer"
            }`}
            disabled={!selectedService}
          >
            決定
          </button>
        </div>
      }
    >
      <SelectServiceDialogContent
        menuType={menuType}
        selectedService={selectedService}
        setselectedService={setselectedService}
      />
    </CommonDialog>
  );
}

export default SelectServiceDialog;

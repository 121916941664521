import React from "react";
import CloseIcon from "@mui/icons-material/Close";

function CloseDialogButton(props) {
  const { handleClose } = props;
  return (
    <button
      type="button"
      className="absolute top-4 right-4 w-8 h-8 rounded-full overflow-hidden flex items-center justify-center bg-gray-900 hover:bg-black cursor-pointer"
      onClick={() => handleClose && handleClose()}
    >
      <CloseIcon className="text-[24px] text-white" />
    </button>
  );
}

export default CloseDialogButton;

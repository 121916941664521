import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import Path from "../../../route/Path";
import TableHistoryCashier from "./TableHistoryCashier";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { defaultDateFormat } from "../../../constants";
import { Popover } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  getAllNonVisitChargedPayment,
  getAllVisitChargedPayment,
} from "../../../services/api/1E";

import { CSVLink } from "react-csv";
import moment from "moment";
import NoData from "../../../components/commonUI/NoData";

function PopoverCalendar(props) {
  const { showCalendarEl, setShowCalendarEl, date, selectDate } = props;

  const [value, setvalue] = useState(() => {
    if (!date) return dayjs();
    return dayjs(date, defaultDateFormat);
  });

  useEffect(() => {
    if (!date) setvalue(dayjs());
    else setvalue(dayjs(date, defaultDateFormat));
  }, [date]);

  return (
    <Popover
      open={!!showCalendarEl}
      anchorEl={showCalendarEl}
      onClose={() => setShowCalendarEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
        <StaticDatePicker
          value={value}
          onChange={(newValue) => {
            setvalue(newValue);
          }}
          onAccept={() => {
            selectDate(value.format(defaultDateFormat));
            setShowCalendarEl(null);
          }}
          onClose={() => setShowCalendarEl(null)}
          slots={{ toolbar: "div" }}
        />
      </LocalizationProvider>
    </Popover>
  );
}

function CashierManagementListPage() {
  const navigate = useNavigate();
  const [fetchHistoryCashier, setFetchHistoryCashier] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [fetchNonVisit, setFetchNonVisit] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [showCalendarEl, setShowCalendarEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(() => {
    const formatCurrentDate = moment()
      .tz("Asia/Tokyo")
      .format(defaultDateFormat);
    return formatCurrentDate;
  });
  const loadHistoryCashier = () => {
    // if (!selectedDate) return;
    setFetchHistoryCashier({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllVisitChargedPayment(selectedDate)
      .then((res) => {
        setFetchHistoryCashier({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryCashier({
          isLoading: false,
          data: null,
          error: error,
        });
      });

    setFetchNonVisit({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllNonVisitChargedPayment(selectedDate)
      .then((res) => {
        setFetchNonVisit({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchNonVisit({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadHistoryCashier();
  }, [selectedDate]);

  const arrayCustomer =
    !fetchHistoryCashier.isLoading &&
    !fetchHistoryCashier.error &&
    Array.isArray(fetchHistoryCashier.data)
      ? fetchHistoryCashier.data?.map((item) => {
          return {
            createdAt: moment(item.payment?.createdAt).format(
              "YYYY-MM-DD HH:mm"
            ),
            customerName:
              item.customer?.firstNameKata + item.customer?.lastNameKata,
            paymentMethod: item.payment?.paymentMethod,
            paymentAmount: item.payment?.paymentAmount,
            staffName: item.staff?.firstNameKata + item.staff?.lastNameKata,
          };
        })
      : [];

  const csvData =
    arrayCustomer.length > 0
      ? [
          ["会計日時", "お客様名", "支払方法", "金額", "レジ担当者"],
          ...arrayCustomer?.map((item) => [
            item.createdAt,
            item.customerName,
            item.paymentMethod,
            item.paymentAmount,
            item.staffName,
          ]),
        ]
      : [["会計日時", "お客様名", "支払方法", "金額", "レジ担当者"]];

  console.log("csvData", csvData);
  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>お帰り</p>
          </>
        }
      >
        <div className="w-full h-full pt-20 pb-8  lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="w-24 text-base lg:text-xl font-semibold">
              会計履歴
            </h2>
            <div className="w-full flex items-center justify-between gap-2 mt-2">
              <div className="flex md:flex md:flex-row flex-col gap-2">
                <div className="flex items-center ">表示期間</div>
                <div className="flex items-center border border-black rounded-md">
                  <div className="pl-2">{selectedDate}</div>
                  <button
                    type="button"
                    className="button-size text-black"
                    onClick={(e) => setShowCalendarEl(e.currentTarget)}
                  >
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </button>
                </div>
              </div>
              <div>
                <CSVLink
                  data={csvData}
                  className="button-size bg-blue-btn-primary"
                  filename={`cashier-management-${selectedDate}.csv`}
                >
                  <FontAwesomeIcon icon={faDownload} />
                  <span className="pl-2">エクスポート</span>
                </CSVLink>
              </div>
            </div>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchHistoryCashier.isLoading && fetchNonVisit.isLoading ? (
                  <LoadingProgress />
                ) : null}

                {!fetchHistoryCashier.isLoading &&
                fetchHistoryCashier.error &&
                !fetchNonVisit.isLoading &&
                fetchNonVisit.error ? (
                  <LoadInitError error={fetchHistoryCashier.error} />
                ) : null}
                {!fetchHistoryCashier.isLoading &&
                !fetchHistoryCashier.error &&
                !fetchNonVisit.isLoading &&
                !fetchNonVisit.error ? (
                  (Array.isArray(fetchHistoryCashier.data) &&
                    fetchHistoryCashier.data.length > 0) ||
                  (Array.isArray(fetchNonVisit.data) &&
                    fetchNonVisit.data.length > 0) ? (
                    <TableHistoryCashier
                      allHistoryCashier={fetchHistoryCashier.data}
                      allNonVisit={fetchNonVisit.data}
                    />
                  ) : (
                    <NoData />
                  )
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() => navigate(Path.cashierManagement)}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
          </div>
        </div>
        <PopoverCalendar
          showCalendarEl={showCalendarEl}
          setShowCalendarEl={setShowCalendarEl}
          date={selectedDate}
          selectDate={setSelectedDate}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CashierManagementListPage;

import React, { useCallback, useEffect, useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";

import Step1CounselingSheet from "./Step1CounselingSheet";
import Step2SelectMenu from "./Step2SelectMenu";
import Step3ConfirmAndCreate from "./Step3ConfirmAndCreate";
import { useNavigate, useParams } from "react-router-dom";
import {
  createNewReservationAndVisit,
  getAvailableTimeAndStaffToReservation,
  getCustomerInfoAndCounselingHistory,
} from "app/services/api";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import Path from "app/route/Path";
import { convertTimeToNumber } from "app/utils";
import MainContentLayout from "app/layouts/MainContentLayout";

const stepOptions = {
  step1: "COUNSELINGS",
  step2: "MENU_LIST",
  step4: "CONFIRM",
};

function CreateNewReservationPageContent(props) {
  const { customerInfo, counselingHistory } = props;

  const [stepData, setStepData] = useState({
    [stepOptions.step1]: [], // data of counselings
    [stepOptions.step2]: [], // data of menu list
    [stepOptions.step3]: null, // data of menu list
  });
  const [step, setStep] = useState(stepOptions.step1);
  const [isConfirmingAvailability, setIsConfirmingAvailability] =
    useState(false);

  const [isCreating, setIsCreating] = useState(false);

  const navigate = useNavigate();
  const handleNextToStep2 = (counselings) => {
    if (!Array.isArray(counselings)) return;
    setStepData((oldData) => ({
      ...oldData,
      [stepOptions.step1]: counselings,
    }));
    setStep(stepOptions.step2);
  };

  const handleNextToStep3 = (menuList) => {
    if (!Array.isArray(menuList) || menuList.length === 0) return;
    setStepData((oldData) => ({
      ...oldData,
      [stepOptions.step2]: menuList,
    }));

    if (isConfirmingAvailability) return;
    setIsConfirmingAvailability(true);
    const durationNumber =
      menuList.reduce((a, b) => a + b.durationNumber, 0) / 60;

    getAvailableTimeAndStaffToReservation(durationNumber)
      .then((res) => {
        const data = res.data;
        if(data) {
          setStepData((oldData) => ({
            ...oldData,
            [stepOptions.step3]: {
              visitDate: data.visitDate,
              startTime: data.startTime,
              staff: data.staff,
            },
          }));
          setStep(stepOptions.step3);          
        } else {
          toast.error("この予約に対応できるスタッフはいません!")
        }
        setIsConfirmingAvailability(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        toast.error("予約はできません!");
        setIsConfirmingAvailability(false);
      });
  };

  const handleBackToStep1 = () => {
    setStep(stepOptions.step1);
  };

  const handleBackToStep2 = () => {
    setStep(stepOptions.step2);
    setStepData((oldData) => ({
      ...oldData,
      [stepOptions.step3]: null,
    }));
  };

  const handleCreateReservationAndVisit = () => {
    if (isCreating || isConfirmingAvailability) return;
    if (
      !Array.isArray(stepData[stepOptions.step1]) ||
      !Array.isArray(stepData[stepOptions.step2]) ||
      stepData[stepOptions.step2].length === 0 ||
      !stepData[stepOptions.step3]
    )
      return;

    setIsCreating(true);
    const durationNumber =
      stepData[stepOptions.step2].reduce((a, b) => a + b.durationNumber, 0) /
      60;
    const startTime = stepData[stepOptions.step3].startTime;
    const startTimeNumber = convertTimeToNumber(startTime);
    const finishTimeNumber = startTimeNumber + durationNumber;
    const finishTime =
      `0${Math.floor(finishTimeNumber)}`.slice(-2) +
      ":" +
      `0${Math.floor((finishTimeNumber % 1) * 60)}`.slice(-2);

    const data = {
      reservation: {
        visitDate: stepData[stepOptions.step3].visitDate,
        startTime: startTime,
        staffId: stepData[stepOptions.step3].staff.id,
        customerId: customerInfo.id,
        finishTime: finishTime,
        salonMenuIds: stepData[stepOptions.step2].map((item) => item.id),
      },
      counsellings: stepData[stepOptions.step1].map((item) => ({
        memo: item.memo,
      })),
    };

    createNewReservationAndVisit(data)
      .then((res) => {
        const visitId = res.data.id;

        setIsCreating(false);
        navigate(Path.duringService(visitId));
      })
      .catch((error) => {
        console.log("error: ", error);
        toast.error("予約に失敗しました");

        setIsCreating(false);
      });
  };

  return (
    <>
      <div className="w-full h-full">
        {Array.isArray(stepData[stepOptions.step1]) &&
        Array.isArray(stepData[stepOptions.step2]) &&
        stepData[stepOptions.step2].length > 0 &&
        stepData[stepOptions.step3] &&
        step === stepOptions.step3 ? (
          <Step3ConfirmAndCreate
            handleBackToStep2={handleBackToStep2}
            handleClickConfirm={handleCreateReservationAndVisit}
            menuList={stepData[stepOptions.step2]}
            visitDate={stepData[stepOptions.step3].visitDate}
            startTime={stepData[stepOptions.step3].startTime}
            staff={stepData[stepOptions.step3s].staff}
          />
        ) : null}

        {Array.isArray(stepData[stepOptions.step1]) &&
        step === stepOptions.step2 ? (
          <Step2SelectMenu
            step2Data={stepData[stepOptions.step2] || []}
            handleNextToStep3={handleNextToStep3}
            handleBackToStep1={handleBackToStep1}
          />
        ) : null}

        {step === stepOptions.step1 ? (
          <Step1CounselingSheet
            handleNextToStep2={handleNextToStep2}
            counselingHistory={counselingHistory}
            step1Data={stepData[stepOptions.step1] || []}
          />
        ) : null}
      </div>

      <LoadingBackdrop isOpen={isConfirmingAvailability || isCreating} />
    </>
  );
}

function CreateNewReservationPage() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const { customerId } = useParams();

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getCustomerInfoAndCounselingHistory(customerId)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: {
            customerInfo: res.customerInfo,
            counselingHistory: res.counselingHistory,
          },
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [customerId]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>ご来店</p>
          </>
        }
      >
        {loadInitState.error ? (
          <div className="w-full h-full flex items-center justify-center">
            Error
          </div>
        ) : null}

        {loadInitState.isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <CircularProgress size={40} />
          </div>
        ) : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data &&
        loadInitState.data.customerInfo ? (
          <div className="w-full h-full flex items-center justify-center">
            <CreateNewReservationPageContent
              customerInfo={loadInitState.data.customerInfo}
              counselingHistory={loadInitState.data.counselingHistory || []}
            />
          </div>
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CreateNewReservationPage;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import Path from "app/route/Path";
import { getSalonSaleDetail } from "../../../services/api";
import { defaultDateFormat } from "../../../constants";
import { gettFirstDayOfThisMonth } from "../../../utils";
import InputDateField from "../../../components/commonUI/InputDateField";
import CommonStatisticalChart from "../../../components/CommonStatisticalChart";

const data = [
  {
    name: "",
    額: 3000,
  },
];

function SaleOfStaffMemberPageContent(props) {
  const { staffId } = props;
  const [rangeToAnalyze, setRangeToAnalyze] = useState(() => ({
    start: gettFirstDayOfThisMonth(),
    end: gettFirstDayOfThisMonth().add(1, "months"),
  }));

  const [loadDataState, setLoadDataState] = useState({
    isLoading: true,
    data: "",
    error: "",
  });

  const [currentChartData, setCurrentChartData] = useState([]);

  const handleChangeStart = (newStart) => {
    if (newStart.diff(rangeToAnalyze.end) >= 0) {
      setRangeToAnalyze({
        start: newStart,
        end: newStart.clone().add(1, "M"),
      });
    } else {
      setRangeToAnalyze((oldState) => ({
        ...oldState,
        start: newStart,
      }));
    }
  };

  const handleChangeEnd = (newEnd) => {
    if (newEnd.diff(rangeToAnalyze.start) <= 0) {
      setRangeToAnalyze({
        start: newEnd.clone().add(-1, "M"),
        end: newEnd,
      });
    } else {
      setRangeToAnalyze((oldState) => ({
        ...oldState,
        end: newEnd,
      }));
    }
  };

  const loadData = useCallback(() => {
    setLoadDataState((oldState) => ({ ...oldState, isLoading: true }));

    getSalonSaleDetail(
      rangeToAnalyze.start.format(defaultDateFormat),
      rangeToAnalyze.end.format(defaultDateFormat)
    )
      .then((res) => {
        setLoadDataState((oldState) => ({
          ...oldState,
          data: res.data,
          isLoading: false,
        }));
      })
      .catch((error) => {
        console.log("error", error);
        setLoadDataState((oldState) => ({
          ...oldState,
          error: error,
          isLoading: false,
        }));
      });
  }, [rangeToAnalyze]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="w-full h-full ">
      <div className="w-full h-full">
        <div className="w-full h-full py-3 lg:pb-0">
          <div className="w-full h-full overflow-hidden">
            <div className="w-full h-10 flex items-start">
              <div className="w-full flex flex-nowrap">
                <InputDateField
                  value={rangeToAnalyze.start}
                  setValue={(newStart) => handleChangeStart(newStart)}
                />
                <span className="font-bold text-xl mx-2">~</span>
                <InputDateField
                  value={rangeToAnalyze.end}
                  setValue={(newEnd) => handleChangeEnd(newEnd)}
                />
              </div>
            </div>

            <div className="w-full h-[calc(100%-40px)]">
              <CommonStatisticalChart
                isLoading={loadDataState.isLoading}
                startTime={rangeToAnalyze.start}
                endTime={rangeToAnalyze.end}
                data={loadDataState.data}
                setCurrentChartData={setCurrentChartData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SaleOfStaffMemberDetailPage() {
  const staffId = useParams();

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle="売上">
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">個人別売上</h2>
          </div>

          <SaleOfStaffMemberPageContent data={data} staffId={staffId} />

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={-1}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SaleOfStaffMemberDetailPage;

import React, { useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import Step1SelectCustomer from "./Step1SelectCustomer";
import Step2SelectMenu from "./Step2SelectMenu";
import Step3SelectTimeAndStaff from "./Step3SelectTimeAndStaff";
import Step4ConfirmAndCreate from "./Step4ConfirmAndCreate";

const stepOptions = {
  step1: "CUSTOMER_INFO",
  step2: "MENU_LIST",
  step3: "TIME_AND_STEP",
  step4: "COMPLETE",
};

function ReservationManagementRegisterPage() {
  const [stepData, setStepData] = useState({
    [stepOptions.step1]: null, // data of customer
    [stepOptions.step2]: null, // data of menu list
    [stepOptions.step3]: null, // data of staff and time
  });

  const [step, setStep] = useState(stepOptions.step1);

  const handleNextToStep2 = (customerInfo) => {
    if (!customerInfo) return;
    setStepData((oldData) => ({
      ...oldData,
      [stepOptions.step1]: customerInfo,
    }));
    setStep(stepOptions.step2);
  };

  const handleNextToStep3 = (menuList) => {
    if (!Array.isArray(menuList)) return;
    setStepData((oldData) => ({
      ...oldData,
      [stepOptions.step2]: menuList,
    }));
    setStep(stepOptions.step3);
  };

  const handleBackToStep1 = () => {
    setStepData({
      [stepOptions.step1]: null,
      [stepOptions.step2]: null,
      [stepOptions.step3]: null,
    });
    setStep(stepOptions.step1);
  };

  const handleBackToStep2 = () => {
    setStepData((oldState) => ({
      ...oldState,
      [stepOptions.step3]: null,
    }));
    setStep(stepOptions.step2);
  };

  const handleBackToStep3 = () => {
    setStep(stepOptions.step3);
  };

  const handleNextToStep4 = (visitDate, startTime, staff) => {
    setStepData((oldData) => ({
      ...oldData,
      [stepOptions.step3]: {
        visitDate,
        startTime,
        staff,
      },
    }));
    setStep(stepOptions.step4);
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>予約を</p>
            <p>確認する</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 lg:pt-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              予約を登録する
            </h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full overflow-auto">
              {stepData[stepOptions.step1] &&
              stepData[stepOptions.step2] &&
              stepData[stepOptions.step3] &&
              step === stepOptions.step4 ? (
                <Step4ConfirmAndCreate
                  stepData={stepData}
                  handleBackToStep3={handleBackToStep3}
                />
              ) : null}

              {stepData[stepOptions.step1] &&
              stepData[stepOptions.step2] &&
              step === stepOptions.step3 ? (
                <Step3SelectTimeAndStaff
                  handleNextToStep4={handleNextToStep4}
                  handleBackToStep2={handleBackToStep2}
                  step2Data={stepData[stepOptions.step2]}
                  step3DataProps={stepData[stepOptions.step3]}
                />
              ) : null}

              {stepData[stepOptions.step1] && step === stepOptions.step2 ? (
                <Step2SelectMenu
                  step2Data={stepData[stepOptions.step2] || []}
                  handleNextToStep3={handleNextToStep3}
                  handleBackToStep1={handleBackToStep1}
                />
              ) : null}

              {step === stepOptions.step1 ? (
                <Step1SelectCustomer handleNextToStep2={handleNextToStep2} />
              ) : null}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ReservationManagementRegisterPage;

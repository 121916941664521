import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Path from "../../../../route/Path";
import moment from "moment";

export default function TableScalpDataList(props) {
  const navigate = useNavigate();
  const { allHistoryOrdering } = props;

  const CLUSTER = {
    // A_CLASS : "CL1",
    // B_CLASS : "CL2",
    // C_CLASS : "CL3",
    // D_CLASS : "CL4",
    // E_CLASS : "CL5",
    // F_CLASS : "CL6",
    A_CLASS : "1",
    B_CLASS : "2",
    C_CLASS : "3",
    D_CLASS : "4",
    E_CLASS : "5",
    F_CLASS : "6",
  }

  return (
    <div className="w-full h-full relative overflow-auto min-w-[900px]">
      {/* header */}
      <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          検体No.
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          登録日時
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          分析状況
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          クラスター
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate"></div>
      </div>

      {/* body */}
      {allHistoryOrdering.map((row, index) => (
        <div className="w-full h-14 flex bg-[#F0F1EF] bg-opacity-50 border-b">
          <div className="w-1/6 flex items-center justify-center">
            {row.scalpData?.inspectionKitId === ""
              ? "-"
              : row.scalpData?.inspectionKitId}
          </div>
          {/* <div className="w-1/6 flex items-center justify-center">
            {row.scalpData?.customerNo}
          </div> */}
          <div className="w-1/6 flex items-center justify-center">
            {row.scalpData.createdAt !== undefined
              ? moment(row.scalpData.createdAt).format("YYYY-MM-DD HH:mm")
              : "-"}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row?.scalpData.analyseStatus == "analyse_not_started"
              ? "未分析"
              : row?.scalpData.analyseStatus == "analyse_in_progress"
              ? "分析中"
              : row?.scalpData.analyseStatus == "analyse_completed"
              ? "分析完了"
              : ""}
          </div>

          <div className="w-1/6 flex items-center justify-center">
            {
              row?.scalpData?.factorCluster == CLUSTER.A_CLASS? "A":
              row?.scalpData?.factorCluster == CLUSTER.B_CLASS? "B":
              row?.scalpData?.factorCluster == CLUSTER.C_CLASS? "C":
              row?.scalpData?.factorCluster == CLUSTER.D_CLASS? "D":
              row?.scalpData?.factorCluster == CLUSTER.E_CLASS? "E":
              row?.scalpData?.factorCluster == CLUSTER.F_CLASS? "F":
              "?"
            }
            {
              "タイプ"
            }
          </div>

          <div className="w-1/6 flex items-end justify-center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(
                  Path.historyOfOrderingDetailByKitId(
                    row.scalpData?.customerNo,
                    row.scalpData?.inspectionKitId
                  )
                );
              }}
            >
              詳細
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { fake_history_order_list } from "../../../../services/api/fake_services/fake_data";
import Path from "../../../../route/Path";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import MainContentLayout from "../../../../layouts/MainContentLayout";
import LoadingProgress from "../../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../../components/commonUI/LoadInitError";
import InputField from "../../../../components/commonUI/InputField";
import { getScalpDataByBatchingGroup, batchDeliverToLab } from "../../../../services/api/1C";
import moment from "moment";
import NoData from "../../../../components/commonUI/NoData";
import TableBatchDelivery from "../TableBatchDelivery";
import TableScalpDataList from "./TableScalpDataList";
import BatchDeliverModal from "./BatchDeliverModal";

function BatchDeliverScalpLabDetailPage() {
  const navigate = useNavigate();
  const customerNo = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [fetchHistoryOrderDetail, setFetchHistoryOrderDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadHistoryOrderDetail = () => {
    setFetchHistoryOrderDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getScalpDataByBatchingGroup(customerNo.group_id)
      .then((res) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadHistoryOrderDetail();
  }, []);

  const handleOnCloseClicked = () => {
    setOpenModal(false)
  }

  const handleOnSendClicked = () => {
    //loadHistoryOrderDetail()
    batchDeliverToLab(customerNo.group_id)
      .then((res) => {
        async function getAllExaminationDatas() {
          // const response = await Api.examinationDatas.all();
          // const items = response.data;
          // setExaminationDatas(items);
          // setExaminationDatasLoaded(true);
        }

        getAllExaminationDatas();
        //gotoNextStep();
        //setIsSubmitting(false);
        setOpenModal(false)
        alert("一括発送しました")
        navigate(Path.batchDeliverScalpLab);
        
      })
      .catch((e) => {
        //setIsSubmitting(false);
        alert(e);
      })
      .finally(() => {
        //setIsSubmitting(false);
      });
  }

  console.log("Ricky --fetchHistoryOrderDetail.data--", fetchHistoryOrderDetail.data)
  console.log("Ricky --openModal--", openModal)
  const isDeliveryDisabled = fetchHistoryOrderDetail.data?.salonDeliverStatus === "pending"? false : true
  console.log("Ricky --isDeliveryDisabled--", isDeliveryDisabled)

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>ラボ</p>
          </>
        }
      >
        <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">頭皮データ一括発送</h2>
            <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-end absolute inset-x-0 top-0 ">
              <button
                onClick={() => {
                  setOpenModal(true)
                }}
                type="button"
                className={`button-size ${isDeliveryDisabled ? 'bg-gray-btn-secondary' : 'bg-blue-btn-primary'} mr-4 lg:mr-6`} 
                disabled={isDeliveryDisabled}
              >
                一括発送
              </button>
            </div>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchHistoryOrderDetail.isLoading ? <LoadingProgress /> : null}

                {!fetchHistoryOrderDetail.isLoading &&
                fetchHistoryOrderDetail.error &&
                fetchHistoryOrderDetail.data?.inspectionKitId ? (
                  <LoadInitError error={fetchHistoryOrderDetail.error} />
                ) : null}
                {!fetchHistoryOrderDetail.isLoading &&
                !fetchHistoryOrderDetail.error ? (
                  Array.isArray(fetchHistoryOrderDetail.data?.inspectionKitIds) &&
                  fetchHistoryOrderDetail.data?.inspectionKitIds.length > 0 ? (
                    <TableScalpDataList
                      allHistoryOrdering={fetchHistoryOrderDetail.data?.inspectionKitIds}
                    />
                  ) : (
                    <NoData />
                  )
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>

          <BatchDeliverModal
            openDialog={openModal}
            handleOnCloseClicked={handleOnCloseClicked}
            content={"検体  " + fetchHistoryOrderDetail.data?.inspectionKitIds.length + "  個"}
            handleOnSendClicked={handleOnSendClicked}
          >
          </BatchDeliverModal>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link to={-1} className="button-size bg-gray-btn-secondary">
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default BatchDeliverScalpLabDetailPage;
